import React from 'react'
import ss from './MainText.module.css'

const Year2015_22 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>22. 첫 번째 왕따.</h1>
      <p>학교장과의 면담 후에 독도 씨는 확실하게 결론을 내렸다.</p>
      <p className={ss.quo}>“17개 시·도교육청 및 수백 수천 개의 학교 홈페이지마다 설명하고 있는 <span className={ss.quo_mini}>‘학교운영위원회는 민주성·투명성·공정성을 기반으로 창의적인 교육을 실현할 수 있는 환경을 제공한다.’</span>는 말이 한비중학교에서는 전혀 작동하지 않는다. 규정을 잘 지키지도 않고, 제대로 심의하지도 않으며, 분위기는 권위적이며, 정보를 제대로 제공하지 않는다. 어딜 봐서 민주성, 투명성, 공정성이 있다는 말인가?”</p>
      <div className={ss.annotation_wrap}>
        <p>훗날 독도 씨는 이와 같은 현상이 비단 한비중학교에서만 벌어지는 일이 아니라, 대부분의 학교에서 벌어지는 일이라는 것을 알게 된다.</p>
        <p>또한 당시 독도 씨가 느낀 불편함의 본질은, 민주주의를 가르쳐야하는 학교가 민주주의를 훼손하고 있다는 것에서 느낀 분노였다는 것도 알게 된다.</p>
      </div>
      <p>&nbsp;</p>
      <p>게다가 더욱 실망스러운 것은 <span className={ss.quo_mini}>‘당신의 자녀가 상을 받을 것입니다.’</span>라며 점수를 미끼로(학생을 볼모로) 학부모를 컨트롤하려 했다는 점이다.</p>
      <p>공무원이 이익을 대가로 주면서 잘못(규정 위반)을 은폐하려고 하거나 상대에게 배임(형식적인 심의)을 요구했다는 것은 매우 중대한 잘못이다.</p>
      <p>더구나 그것이 교육자의 행동이라면 더욱 잘못된 일이다.</p>
      <p>학교의 관리자를 선발하는 교육계의 시스템(선발 기준, 방식 등)에 문제가 있는 것은 아닌지 반드시 점검해 보아야 한다.</p>
      <p>또한, 국민들이 민감하게 생각하는 학교의 학생 평가 시스템도 다시 한 번 점검해 볼 필요가 있다.</p>
      <p>주관적 평가로 매기는 점수라면 얼마든지 불공정하게 매겨질 수 있다는 것을 분명하게 보여주었기 때문이다.</p>
      <div className={ss.annotation_wrap}>
        <p>2015년부터 진부도교육청은 민간에 예산을 배정하여 진행하는 드림스쿨 사업을 시행했다.</p>
        <p>진부도교육청은 이 사업의 공정한 예산 배정 심사를 위해 교육청과 교사와 민간이 모두 포함된 심사위원단을 구성하였는데, 이 심사위원단에 포함된 심사위원이 직간접으로 사업을 신청하고 예산을 받아간 사례(소위 셀프 심사)가 상당수 있어서 물의를 일으킨 바가 있었다.(당시 교육감은 2020년 국정감사에서 ‘본질을 호도한 지적’이라며 발뺌을 했는데, 이 기막힌 발언은 2020년 이야기에서 자세하게 다룰 예정이다.)</p>
        <p>이와 같은 사례는, 매우 오랜 시간 공교육에서의 혁신과 진보를 표방했으나 결국에는 가장 기본적인 공정성조차 확보하지 못한 진부도 교육청의 부끄러운 모습을 보여주는 사례이다.</p>
        <p>스스로 공정하지 못했으니 단위 학교에서의 공정성을 감시하려는 노력을 얼마나 했겠나 싶다.</p>
        <p>교육청은, 학교에서 학생을 대상으로 하는 평가가 존재하는 한, 평가가 공정하게 관리될 수 있도록 교육(연수)하고 감시하고, 계속해서 평가 시스템을 보완하기 위한 노력을 해야 한다.</p>
        <p>물론, 단위 학교에서도 학교자치가 정상적으로 작동됨으로써 학교 구성원들이 스스로 감시할 수 있어야 하고, 또한 효과적인 평가 시스템의 제안에 참여할 수 있어야 한다.</p>
        <p>예를 들자면, 수행평가계획을 수립할 때의 ‘평가기준, 반영비율, 내용과 방법, 시기 등에 대한 학생과 학부모의 의견 참여’ 등이 있겠다.</p>
      </div>
      <p>&nbsp;</p>
      <p>독도 씨는 운영위원 활동을 제대로 하고 싶었다.</p>
      <p>시·도교육청이 말한 대로 운영위원회가 학교 구성원의 참여를 통해 민주적이고 창의적인 교육을 하는데 필요한 환경을 제공한다면, 제대로 활동함으로써, 그렇게 되는 것을 보고 싶었다.</p>
      <p>그러한 교육이 실행된다면, 학생들의 창의성이 자극을 받을 것이고, 또한 어른 세대가 제대로 배우지 못한 민주주의를 학생들이 제대로 배울 수 있을 것이고, 그 두 가지만 이루어져도 앞으로 우리 사회가 좀 더 행복해질 수 있다고 믿기 때문이었다.</p>
      <p>&nbsp;</p>
      <p>하지만 도대체 어떻게 해야 할지 알 수가 없었다.</p>
      <p>졸업하고 성인이 된 후로는 추억의 대상일 뿐이었던 학교였고, 학생이 아닌 학부모로서 학교를 바라보는 것은 처음 겪는 경험이었으므로, 적응할 시간이 필요하기도 했지만 무엇보다도 가진 정보가 너무 빈약했기 때문이었다.</p>
      <p>왜 이렇게 정보가 없을까?</p>
      <p>&nbsp;</p>
      <p>잘못된 일을 바로 잡으려면, 그 일에 대해 자세히 알아야 한다.</p>
      <p>정보가 없다면, 직접 경험해 보는 수밖에 없다.</p>
      <p>지금으로서는 답답하지만, 계속 경험해 보는 수밖에 없으므로, 하는 수없이 독도 씨는 다음 회의를 기다리기로 했다.</p>
      <div className={ss.annotation_wrap}>
        <p>독도 씨가 좌충우돌 부딪히며 배워나갔던 과정을 돌아보면, <span className={ss.bold}>정보를 감추는 것이야말로 학교민주주의를 고사 시키려는 학교장과 교육계의 핵심 전술</span>이라는 것을 알 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그렇게 다음 회의를 기다리고 있던 독도 씨에게, 어느 날 윤미옥 운영위원장의 전화가 걸려왔다.</p>
      <p>뜻밖이었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“부위원장님 안녕하세요? 저, 윤미옥입니다.”</p>
      <p className={ss.quo}>“아, 안녕하세요?”</p>
      <p className={ss.quo}>“네. 그동안 잘 지내셨죠?”</p>
      <p className={ss.quo}>“그럼요. 위원장님도 잘 지내셨죠?”</p>
      <p>의례적인 인사말이 한 차례 오고간 뒤에, 윤미옥 위원장이 바로 용건을 꺼냈다.</p>
      <p className={ss.quo}>“다름이 아니고요. 운영위원회 운영 관련해서 좀 드릴말씀이 있어서요.”</p>
      <p className={ss.quo}>“네? 어떤…….”</p>
      <p className={ss.quo}>“음……. 전화로 말씀 드리기는 좀 그렇고요. 한 번 만나 뵈었으면 하는데, 언제 시간이 괜찮으실까요?”</p>
      <p>&nbsp;</p>
      <p>독도 씨는, 갑자기 전화를 해서는 다짜고짜 만나자고 하는 윤미옥 위원장의 의도를 알 수는 없었지만, 운영위원회 관련하여 만나자고 하는 것이니 나름 중요한 일일 것이라 생각했다.</p>
      <p>그래서 며칠 뒤에 만나는 것으로 약속을 잡았다.</p>
      <p>&nbsp;</p>
      <p>그리고 6월의 어느 날 오후, 학교에서 멀지 않은 “조선 카페”라는 다소 촌스러운 이름의 커피숍에서, 독도 씨는 윤미옥 위원장을 만났다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“날씨가 많이 더워진 것 같아요.”</p>
      <p>시원한 아이스커피를 한 모금 마시면서 윤미옥 위원장은 잠깐 창밖을 내다보았다.</p>
      <p>도로는 한산했고, 햇빛을 받은 가로수는 짙은 녹색으로 반짝이고 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“네, 이제 완전히 여름입니다.”</p>
      <p>독도 씨는 윤미옥 위원장의 말에 대답하면서 커피숍의 내부를 둘러보았다.</p>
      <p>테이블이 4개뿐인 작은 공간이었고, 두 사람 외에 다른 손님은 없었다.</p>
      <p>&nbsp;</p>
      <p>윤미옥 위원장은 독도 씨를 바라보면서 바로 용건을 꺼냈다.</p>
      <p className={ss.quo}>“부위원장님, 얼마 전에 교장선생님 만나셨다고 들었어요.”</p>
      <p className={ss.quo}>“네. 그랬습니다.”</p>
      <p className={ss.quo}>“저는 첫애가 초등학교 2학년 때부터 시작해서 지금껏 운영위원을 10년 동안 했어요.”</p>
      <p className={ss.quo}>“아, 오래 하셨네요.”</p>
      <p className={ss.quo}>“별로 할 일도 없는데 힘든 게 운영위원이에요.”</p>
      <p className={ss.quo}>“그런가요?”</p>
      <p className={ss.quo}>“사실 할 일이란 것이 일 년에 몇 번 회의 참석하는 것이 전부인데, 가끔 학교에서 골치 아픈 일이 생기거든요. 그런 일이 생기면 운영위원들은 다른 학부모들과 달리 무척 신경이 쓰인답니다. 어쩔 땐 선생님들보다 더 신경을 써요.”</p>
      <p>대뜸 10년의 경험을 꺼낸 것으로 보아, 자신의 경험이 일반적인 경험이라고 말하고 싶은 모양이었다.</p>
      <p>강산이 변한다는 긴 시간을 10년이라고 표현하니까, 10년이면 그럴만한 시간이긴 했다.</p>
      <p>윤미옥 위원장은 마치, <span className={ss.quo_mini}>‘너는 잘 모르겠지만, 내가 겪은 바로는 그래.’</span>라는 투로 말을 이어갔다.</p>
      <p className={ss.quo}>“운영위원이든 아니든 학부모들 마음은 다 똑같아요. 학교는 아이들을 위해서 있는 거잖아요. 아이들이 학교생활에 집중할 수 있도록, 다른 골치 아픈 일은 없는 것이 좋죠.”</p>
      <p className={ss.quo}>“네…….”</p>
      <p>아무래도 윤미옥 위원장은 독도 씨가 학교장과 면담한 일을 염두에 두고 말을 꺼내는 것 같았다.</p>
      <p>독도 씨는 다음 말이 궁금했다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“부위원장님. 그러지 마세요.”</p>
      <p className={ss.quo}>“네?”</p>
      <p className={ss.quo}>“교장선생님한테 결산 자료를 내 놓으라고 하셨다면서요? 세상에……. 지금까지 그런 일을 듣도 보도 못했네요. 교장선생님도 긴 교직생활 동안 처음 들어봤다고 하더군요. 도대체 무슨 생각으로 그러셨는지 궁금하네요.”</p>
      <p className={ss.quo_mini}>‘역시 그일 때문이었군.’</p>
      <p>독도 씨는 예의를 지키고 싶었다.</p>
      <p>학교의 운영위원장이기도 하고, 무려 10년차 베테랑 운영위원이 아닌가.</p>
      <p>그래서 자신의 입장을 최대한 성실하게 설명하고 싶었다.</p>
      <p className={ss.quo}>“예·결산안의 심의는 운영위원의 가장 중요한 임무에 속한다고 들었어요. 제가 건설회사에서 들여다보는 수십억에서 수백억 규모의 공사 내역서는 학교 결산서보다 훨씬 분량이 많아요. 그래도 공사 내용만 알면 쉽게 파악할 수 있어요. 그런데 학교 결산서를 보니 내용을 몰라서 그런지 너무 낯설더라고요.”</p>
      <p>&nbsp;</p>
      <p>독도 씨는 자신의 경험과 비교해서 설명을 해 나갔다.</p>
      <p className={ss.quo}>“양식이 약간 차이나는 것은 계속 들여다보니 벌써 익숙해지는 것 같아요. 또 회계 용어라든지 예산의 주요 내용 같은 것도 시간이 지나면 차차 익숙해지겠지요. 하지만 공사 내역서가 제대로 작성되었는지 알려면 도면이나 시방서 같은 설계도서의 내용을 봐야 하고, 동시에 공사에 대한 이해도 있어야 하듯이, <span className={ss.bold}>예·결산의 작성 근거와 학교 운영에 대한 이해가 모두 있어야 예·결산서를 이해할 수 있을 것이고, 그래야 무엇에 중점을 두고 심의해야할지 알 수 있을 것</span>이겠죠.”</p>
      <p>편의상 독도 씨는 자신의 직업에서의 경험에 비유해서 설명을 하였는데, 사실 공사 내역서의 내용을 이해하는 것은 학교 예산서의 내용을 이해하는 것 이상으로 전문적인 일이기 때문에 건설회사와 관련이 없는 윤미옥 위원장에게 설명하기에는 적절치 않은 비유였다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 설명을 이어갔다.</p>
      <p className={ss.quo}>“그런데 학교 운영에 대해 설명하는 자료는 없더라고요. 차차 익히는 수밖에 없겠죠. 하지만 예산이 무슨 근거로 어떻게 작성되었는지는 자료가 있을 거예요. 마치 설계도서처럼 무엇이 왜 필요한지 그 근거가 되는 자료 말이에요. 제가 듣기로는 ‘부서별 예산요구서’라는 자료가 있다고 하더군요. 그리고 결산서 자체도 예산 편성의 근거 자료 중 하나라고 하더군요. 그런데 이번에는 예산 심의가 아니니까 부서별 예산요구서는 보류하고요.”</p>
      <div className={ss.annotation_wrap}>
        <p>그 당시에는 몰랐지만 나중에 알게 된, 예산 편성 근거 자료로서 아주 중요한, 시·도 교육청에서 매년 배포하는 ‘학교회계 예산편성 기본지침’이란 것이 있다.</p>
        <p>「초·중등교육법」은 학교회계의 설치에 필요한 사항을 시·도의 교육규칙으로 정하게끔 하고 있는데, 각 시·도의 공립학교회계 규칙에서는 교육감으로 하여금 예산편성 기본지침을 작성하여 학교에 배포하도록 하고, 학교는 이에 따라 예산안을 편성하도록 하고 있다.</p>
        <p>따라서 ‘학교회계 예산편성 기본지침’을 보면 학교의 예·결산을 이해하고, 이를 심의하는데 많은 도움이 된다.</p>
        <p>그런데 운영위원이 예·결산안 심의를 할 때, 이를 보여주거나 참고하라고 알려주는 학교는 없다.</p>
        <p>제대로 심의하기 위해서 꼭 필요하다고 할 수 있는, 매우 유용한 자료인데 말이다.</p>
        <p>그러다보니 ‘학교회계 예산편성 기본지침’이란 것이 있다는 것을 아는 학부모위원은 거의 없다.</p>
        <p>지난 10년간, 독도 씨가 만난 수백 명의 학부모위원 중 이를 알고 있는 학부모위원은 부종교육포럼 운영진 5명을 제외하고는 단 한 명도 없었다.</p>
        <p>하긴 학부모위원이 알아야 하는데 모르는 것이 어디 이것뿐이겠나.</p>
        <p>너무 많아서, 그냥 아무 것도 모른다고 말해야할 정도이다.</p>
        <p className={ss.bold}>정보를 감춤으로써 인지하지 못하게 하는 현실이, 학교운영위원회를 통해 학교자치를 실현하겠다며, 교육의 민주성·투명성·공정성을 천명한 우리 공교육의 현실이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>내용이 길어져서 독도 씨는 잠시 숨을 고르고서 말을 이었다.</p>
      <p className={ss.quo}>“결산 심의니까 결산서를 이해해야 하는데, 실제 예산을 집행한 세부 내역을 들여다보면 결산 내용을 구체적으로 알 수 있을 것이라 판단했어요. 예를 들자면 식비로 100만원을 썼다고 하면 알 수 있는 것이 많지 않지만, 쌀을 몇 ㎏ 구매했고, 과일이나 야채는 무엇을 얼마큼 샀는지, 고기는 어떤 종류로 얼마나 샀는지, 외식은 얼마나 했는지 등이 구체적으로 적혀 있다면 알 수 있는 것이 아주 많을 거예요. 식단의 종류, 식사의 양, 영양의 균형 등을 알 수 있고, 구매 패턴, 그러니까 어디서 어떻게 샀는지, 적정한 가격에 샀는지 같은 것들도 알 수 있겠죠.”</p>
      <p>&nbsp;</p>
      <p>독도 씨의 이야기는 계속해서 이어졌다.</p>
      <p className={ss.quo}>“그러면 어떤 문제가 있는지 파악이 가능할 것이고, 향후에 보다 효율적으로 식비를 지출하는데(계획하는데) 도움이 될 것이라 생각해요. 아무런 분석 없이 무조건 식비를 늘이거나 줄이자고 하면 말이 안 되잖아요?”</p>
      <div className={ss.annotation_wrap}>
        <p>아무 분석 없이 급식비를 올리자는 주장을 했던 사례가, 독도 씨가 고등학교에서 운영위원을 할 때 실제 발생했다.</p>
        <p>당시 독도 씨는 문제의 원인을 분석하고 그 해결책을 제시함으로써 무책임한 급식비 인상을 막았다.(2018년 이야기에서 다룰 예정이다.)</p>
        <p>예산과는 조금 다른 문제이긴 하지만, 이 사례에서 독도 씨의 말과 관련하여 생각해볼 것이 세 가지가 있다.</p>
        <p>첫째, 문제를 해결하기 위해서는, 다양한 관점에서 구할 수 있는 자료를 최대한 모아서 그 원인을 분석해야한다.</p>
        <p>그런 분석 없이 관례대로 만든 안건을 제출하는 학교장이나, 무분별하게 찬성만 하는 거수기 위원들은 학교 운영의 적이다.</p>
        <p>둘째, 단위 학교에서 학교 운영 관련 자료를 생성하면, 이를 보관하고 손쉽게 접근할 수 있도록 홈페이지에 공개해야 한다.</p>
        <p>학교 구성원 중 누군가는 이를 통해 문제점을 분석하고 해결책을 제시할 지도 모른다.</p>
        <p>셋째, 정리하기 전의 원시 데이터가 매우 중요하다.</p>
        <p>풀이과정을 보지 않고 답만 채점해서는 정확한 평가가 어려울 수 있듯이, 원시 데이터가 없이 정리된 문서만으로 안건을 심의하는 데는 한계가 있을 수 있다.</p>
        <p>결산서의 경우도 마찬가지이다.</p>
        <p>세부 지출 내역이 있어야 심도 있는 평가(심의)가 가능하다.</p>
        <p>학생회 결산으로 100만원 사용했다고 한 줄 적혀 있으면, 무엇을 어떻게 썼는지도 모르고 학생자치를 잘 했다고 평가할 수 있겠는가?</p>
        <p>그런 평가 없이 다음해에 다시 100만원을 편성하는 게 합리적인 예산 편성인가?</p>
      </div>
      <p>&nbsp;</p>
      <p>독도 씨는 이제 결론을 이야기했다.</p>
      <p className={ss.quo}>“그러니까 단순하게 ‘운영비 100만원’이라고 적혀있는 결산서보다는, 구체적으로 무엇을 얼마나 어떻게 구매했는지 적혀있는 세부 내역을 보는 것이 결산서를 훨씬 더 잘 이해할 수 있고, 나중에 예산 심의의 근거 자료로도 활용할 수 있을 것이라 생각했습니다. 결산의 심의란 그런 것이 아닐까요? 학교 운영에 대해서 잘 모르지만, 상식선에서 생각해 본 것입니다. 그래서 세부 지출 내역서를 요청했습니다. 물론 교장선생님은 규정 핑계로 거부할 수 있었으니 거부했고요.”</p>
      <div className={ss.annotation_wrap}>
        <p>정상적인 민주주의 시스템에서는 늘 규정이 규정대로 작동한다.</p>
        <p>그러나 <span className={ss.bold}>독재 시스템에서의 규정은, 평상시에는 창고나 책장 구석에 묻혀 있다가, 권력자가 필요할 때만 꺼내 쓰는 전가의 보도로 작동한다.</span></p>
        <p>한비중학교에서도 그랬다.</p>
        <p>의견 수렴이나 회의 공개 규정, 운영위원회 회의 소집 방식, 지역위원 선출 방식 등 중요한 규정들을 제대로 지키지 않다가, 서류제출에 대해서는 운영위원회 재적위원 3분의1 이상의 찬성이 필요하다는 규정을 칼 같이 들어 거부했다.</p>
        <p>지키지 않은 규정을 지적하면 한 번만 봐달라고 하면서, 자신에게 필요한 상황이 닥치면 규정은 신성불가침의 절대 질서가 된다.</p>
        <p>이것 역시 한비중학교만의 일은 아니다.</p>
        <p>우리 공교육의 민주주의 수준이 그러하다.</p>
        <p>물론 독도 씨는, 유리하거나 불리하거나 규정은 규정에 맞게 작동해야 된다고 생각한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>긴 설명이 끝나자, 윤미옥 위원장은 기다렸다는 듯이 이의를 제기했다.</p>
      <p className={ss.quo}>“그건 부위원장님 생각이고요. 그렇게까지 안 해도 지금껏 아무 문제가 없었습니다. 선생님들이 알아서 잘 했겠지요. 부위원장님은 교육에 대해서 잘 모르시잖아요? 잘 모르시면서 전문가가 하는 일을 그렇게 꼬치꼬치 알려고 하면 서로 피곤해 집니다. 병원에 가서 의사선생님한테 그런 식으로 따져보세요. 치료 못 받습니다. 안 그래요?”</p>
      <p>&nbsp;</p>
      <p>역시나 지금까지 아무 문제가 없었다는 말과 선생님을 믿는다는 말이 먼저 나왔다.</p>
      <p>문제가 있는지 없는지 여태 생각조차 안 해봤다는 이야기다.</p>
      <div className={ss.annotation_wrap}>
        <p className={ss.bold}>의심하지 않으면, 문제는 발견되지 않는다.</p>
        <p>의심은 부정적인 면만 있는 단어가 아니다.</p>
        <p>정보 분석에 있어서는 비판적 사고를 촉진하고, 안전에 있어서는 자신을 보호 하며, 결정에 있어서는 맹목적 신뢰를 피함으로써 균형 잡힌 판단을 가능케 한다.</p>
        <p>의심이 없었다면, 아마도 인류는 원시시대에 이미 멸종했을 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={ss.quo}>“아니, 그건 아니죠. 우리의 일이니까 해야 하는 것이고요. 전문가가 아니라도 할 수 있는 한 열심히 해야지요. 학부모가 잘 모르는 것은 교원위원이나 지역위원이 알려주고, 반면에 교사가 잘 모르는 것은 학부모위원이나 지역위원이 알려주면서 함께 해결해 가야지요. 그래도 안 되면 소위원회에 전문가를 초빙해서 도움을 받을 수도 있고요. 위원장님 말씀대로라면 아예 학부모는 운영위원회에서 빠져야 맞는 게 아닌가요? 가만히 있을 거면 학부모위원이 왜 필요한가요? 그리고 병원에서도 필요하면 따져야지요. 따진다고 치료 안 해주면 의사가 아니죠. 의사든 변호사든 또 어떤 기술자든 그 앞에서 따지지 못하면 그 사람은 호구 아닌가요? 그리고 아무 문제가 없었다는 말씀은 확인된 바가 그렇다는 것인가요, 아니면 그냥 추정인가요?”</p>
      <p>독도 씨는 윤미옥 위원장의 말에 조목조목 반론을 제기했다.</p>
      <p>&nbsp;</p>
      <p>물론 윤미옥 위원장도 독도 씨의 말에 계속해서 이의를 제기했다.</p>
      <p className={ss.quo}>“아무 일도 없었으니 아무 문제가 없다는 것이죠. 당연한 것 아닌가요? 그리고 저한테까지 자꾸 이렇게 따지시는 걸 보니 참 피곤하신 분 같네요. 그리고 교장선생님 말씀이, 서류제출은 운영위원회에서 동의를 해야 되는 일이라고 하시네요. 그럼 저한테 먼저 말씀을 하셨어야죠. 물론 저는 반대합니다. 다른 위원님들도 마찬가지고요. 더 이상 이런 일로 학교 찾아가지 마셨으면 좋겠어요. 무슨 일이 있으면 먼저 저한테 말씀해 주세요. 절차를 지키시라고요.”</p>
      <p>&nbsp;</p>
      <p>피곤한 사람이라고?</p>
      <p>독도 씨는 조금 답답함을 느꼈다.</p>
      <p className={ss.quo}>“문제가 있어도 터질 때까지는 안 보이는 경우가 많습니다. 문제가 있는지 없는지는 합의된 체크리스트로 체크를 해봐야 아는 것이고요. 그러니까 아무 문제가 없다는 말씀은 그냥 위원장님 추정일 뿐 체크된 바가 없다는 것으로 생각할게요. 그리고 우리의 일이 바로 그 체크하는 일입니다. 저는 체크리스트를 만들기 위해 결산 자료를 요청한 것이고요. 그리고 저는 절차를 무시한 것이 아닙니다. 요구하러 간 것이 아니고 요청하러 간 것이에요. 교장선생님께도 차이점을 분명히 밝혔습니다.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“흠, 어쨌든 앞으로는 절차를 잘 지켜주시기를 바랄게요.”</p>
      <p>윤미옥 위원장은 쌀쌀맞은 표정으로 대꾸하였다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 열심히 설명했지만, 대답은 조금씩 어긋나고 있었다.</p>
      <p className={ss.quo}>“다시 한 번 말씀드리지만, 절차와 관계없는 요청이었어요. 그리고 절차를 지켜야한다는 것은 저 역시 당연하다고 생각합니다. 그러니까 이전에도 그렇고 앞으로도 그렇고 실수라면 몰라도 고의로 절차를 훼손하는 일을 없을 거예요.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘흥, 한 마디를 안 지려고 하네. 잘났다, 정말.’</p>
      <p>윤미옥 위원장은 딱딱하게 굳은 표정으로 대답을 뱉어냈다.</p>
      <p className={ss.quo}>“그래요. 앞으로 잘 지켜볼게요.”</p>
      <p>&nbsp;</p>
      <p>이번에는 독도 씨가 윤미옥 위원장에게 질문했다.</p>
      <p className={ss.quo}>“그런데 왜 저더러 학교를 찾아가지 말라고 하시는 거죠? 필요한 일이 있으면 가야지요.”</p>
      <p>&nbsp;</p>
      <p>윤미옥 위원장은 당연한 것을 왜 묻느냐는 듯, 약간 어이없어 하는 말투로 대답했다.</p>
      <p className={ss.quo}>“자꾸 그런 일로 찾아가면 조용한 학교가 시끄러워지잖아요. 이번에 교장선생님이나 행정실장님이 많이 곤란해 하셨다고 들었어요. 아까도 말씀드렸지만 아이들이 공부에만 집중할 수 있도록, 다른 골치 아픈 일은 없는 것이 좋아요. 부위원장님 개인적인 욕심 때문에 학교가 소란스러워지면 안 되지 않을까요?”</p>
      <p>&nbsp;</p>
      <p>개인적인 욕심이라고?</p>
      <p>그리고 학교장이 곤란한 일을 겪으면 학생들에게 피해가 간다고?</p>
      <p>잘못은 학교장이 하고 있는데?</p>
      <p>&nbsp;</p>
      <p>윤미옥 위원장이 이어서 말을 했다.</p>
      <p className={ss.quo}>“그리고요, 처음이라서 뭔가 나서고 싶은 욕심이 있나본데, 그러지 마세요. 원래 어딜 가나 처음에는 나대지 않고 조용히 있는 것이 예의랍니다. 인간사가 다 그래요. 서로 잘 알지도 못하는 사이인데 막 이것저것 따지고 그러면 미운털 박힌다고요. 그리고 나이도 저보다 한 살 어리시던데 인생 선배가 하는 말 잘 귀담아 들으세요.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘하! 이건 뭐……. 더 나은 운영을 위해 의견을 제시하는 것은 공적인 영역인데, 예의 운운하며 사적인 감정을 들먹이는 구나. 게다가 나이 이야기까지 나오고…….’</p>
      <p>독도 씨는 갈수록 입을 떡 벌어지게 만드는 말을 하는 윤미옥 위원장을 보면서, 어쩌면 저렇게 아무렇지도 않은 얼굴로 저런 이야기를 할 수 있을까 생각했다.</p>
      <p className={ss.quo_mini}>‘위원장이야말로 예의가 없네. 알았다고 대답할 수도 없고. 참 힘든 일이구나.’</p>
      <p>독도 씨는 운영위원에 도전한 것이 잘못된 선택은 아니었는지 순간 고민했다.</p>
      <div className={ss.annotation_wrap}>
        <p>보통은 학교장보다 윤미옥 운영위원장 같은 거수기 학부모를 상대하는 것이 더 힘든 경우가 많다.</p>
        <p>사이비 교주와 논쟁하는 것보다 그의 광신도와 논쟁하는 것이 더 어렵다고나 할까?</p>
        <p>광신도는 대개 매우 강한 확증편향을 가지고 있고, 논리적인 이유보다는 감정적이고 심리적인 요인에 의해 움직이므로, 합리적 주장이 통하지 않기 때문이다.</p>
        <p>&nbsp;</p>
        <p>하지만 이 정도는 약과다.</p>
        <p>훗날 독도 씨는, 이보다 더 힘든 두 번째 왕따 사건과, 두 번째보다 더 힘든 세 번째 왕따 사건을 겪게 된다.</p>
        <p>눈물 없이는 듣지 못할, 그런 사연이 등장할 예정이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>애써 논쟁해봐야 소득이 없을 것 같아서 독도 씨는 한 발 물러서기로 했다.</p>
      <p className={ss.quo}>“음……. 그래요. 저는 앞으로도 할 말은 하겠지만, 그래도 가급적 수위 조절이나 속도 조절을 하도록 노력해볼게요.”</p>
      <p className={ss.quo_mini}>‘그래 사실 되는대로 막 지적하기 보다는 좀 더 탐색할 시간이 필요하기는 해. 아직은 잘 모르니까. 요령이란 게 필요하겠지. 머리 아프게 정치할 생각은 없는데…….’</p>
      <p>독도 씨는, 상대해야할 사람이 학교장만이 아니라는 것을 슬슬 깨닫고 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“잘 생각하셨어요. 그래야죠. 이제야 좀 말이 통하네요.”</p>
      <p>윤미옥 위원장의 얼굴에 살짝 만족스러운 웃음기가 돌았다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 화제를 돌릴 겸 다른 질문을 하나 던졌다.</p>
      <p className={ss.quo}>“그런데 제가 교장선생님을 만났다는 것은 누구한테 들으셨어요?”</p>
      <p className={ss.quo}>“아, 며칠 전에 교장선생님이 전화를 하셨어요. 부위원장님이 학교에 대해 불신이나 오해 같은 것이 있는 것 같다면서요. 결산 자료를 달라고 하는 건, 학교를 못 믿어서 그런 거죠? 어쨌든 그래서 저더러 부위원장님을 만나서 오해도 좀 풀어드리고 앞으로 학교와 학생들을 위해서 협조 부탁드린다는 말 전해달라고 하셨죠. 그런데 저도 교장선생님 생각과 같아요. 부위원장님은 뭔가 학교에 불신이 있는 것 같은데, 다른 학교는 몰라도 저희 한비중학교는 그런 문제가 없어요. 그리고 우리 운영위원들이 학교에 협조를 잘해서 학교가 학생들을 위한 교육에 집중할 수 있게 해야지요. 저는 그거야말로 제일 중요한 임무라고 생각해요.”</p>
      <p>윤미옥 위원장의 대답에, 독도 씨는 다시 입이 벌어졌다.</p>
      <p>아무래도 이 사람들과는 가치관이 너무 맞지 않는다는 생각을 했다.</p>
      <div className={ss.annotation_wrap}>
        <p>거듭 하는 말이지만, 예·결산의 심도 있는 심의는 불신이나 오해와 관련이 없다.</p>
        <p>그러한 심의는 보다 효율적인 교육과정을 위한 학교 구성원의 헌신과 노력을 의미한다.</p>
        <p>또한 학교에 문제가 있는지 없는지는 체크해봐야 안다.</p>
        <p>문제란 횡령과 같은 범죄 혹은 영수증 누락과 같은 실수 등의 크고 작은 책임을 요하는 사고만을 뜻하는 것이 아니다.</p>
        <p>비효율적인 예산 편성이 있는지 살펴보거나, 특정 부서의 예산 증액 조치가 타당한지 검토하는 것 등의 적정성 평가를 더 크게 포함하는 개념이다.</p>
        <p>그러므로 문제를 체크한다는 것은, 사고를 발견하자는 것보다는 효율성을 평가해보자는 의미가 더 크다.</p>
        <p>&nbsp;</p>
        <p>그런데도 예·결산 심의를 열심히 하려고 하면 반드시 <span className={ss.fbody}>“요즘은 학교회계가 전부 프로그램화 되어 있고, 각종 입찰·구매 행위도 철저하게 관리되기 때문에 사고가 발생하기 어렵다. 더구나 학교회계는 교육청에서 감사를 받는다. 그런데도 운영위원회에서 내부감사를 하겠다는 것은 교직원을 괴롭히는 것이며, 교직원을 잠재적 범죄자로 취급하는 느낌이 들기 때문에 기분이 좋을 수 없다. 왜 그렇게 학교를 불신하는가?”</span>라는 말이 등장한다.</p>
        <p>&nbsp;</p>
        <p>이것은 ‘논점 일탈의 오류’이다.</p>
        <p>효율성 평가를 위한 행위(예산 편성의 감시 행위)를 먼지 털이(꼬투리 잡기) 행위로 교묘하게 비틀음으로써, 심의를 성실히 하려는 사람을 불신의 아이콘(선량한 교사를 의심하고 꼬투리 잡으려는 모리배)으로 만들고, 공동체에 위해를 가하는 행위라는 프레임을 씌워 심의를 피해가려는 학교장의 전술이다.</p>
        <p>&nbsp;</p>
        <p>학교장도 예·결산 심의의 진짜 의미를 안다.</p>
        <p>그런데 왜 이런 식으로 논점을 비트는 것일까?</p>
        <p>예산 편성은 학교장의 고유 권한이지만, 학교운영위원회에서 심의를 받아야 한다.</p>
        <p>학교장은 심의가 싫은 것이다.</p>
        <p>고유 권한을 넘어 절대 권한이고 싶은 것이다.</p>
        <p>그러므로 논점을 비틀어서 ‘평가의 문제’를 ‘불신의 문제’로 만들고, 평가하자는 사람을 ‘학교를 불신하고 공격하여 교사를 괴롭히는 불순분자’로 만드는 것이다.</p>
        <p>이런 논리는, <span className={ss.fbody}>“학생이 시험을 보는 것은 평가 목적이 아니다. 공부했다는 말을 못 믿기 때문에 이를 확인하기 위한 것이다. 즉 시험은 학생을 불신하는 행위이다.”</span>라고 말하는 것과 같다.</p>
        <p>&nbsp;</p>
        <p>그리고 서로가 주인인 학교자치에서 학교에 협조한다는 것은, 각 구성원 그룹의 요구사항을 이해하거나, 정책의 적극적인 제안과 면밀한 검토 노력 등 전체 구성원의 이익을 위한 행동을 뜻하는 것이지, 특정 구성원의 지시에 잘 따르고 지원해주는 것을 뜻하는 것이 아니다.</p>
        <p>&nbsp;</p>
        <p>마지막으로, 학교운영위원의 가장 중요한 임무는, 학교 구성원들의 의견을 정확하게 수렴하여, 학교를 운영하는데 필요한 각종 규정과 정책(교육과정)의 결정에 참여하는 것이지 학교장의 통치에 협조하는 것이 아니다.</p>
        <p>거수기가 앉는 자리가 아니라, 매우 막중한 책임과 높은 부담을 짊어져야 하는 자리이다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={ss.quo}>“하……. 아니에요. 불신 같은 거. 그냥 운영위원의 일이잖아요. 오해 같은 것 없습니다. 저 역시 학교가 잘 운영되기를 바라고 협조하고 싶어요. 어떤 행동이 협조인지는 관점에 따라 다르겠지만요. 어쨌든 교장선생님께서 어떤 생각을 하시는지는 대충 알겠네요.”</p>
      <p>독도 씨는 한숨을 내쉬었고, 윤위원장은 조금 더 만족했는지 미소를 짓고 있었다.</p>
      <p className={ss.quo}>“이제라도 아셨으면 됐죠. 앞으로 잘 좀 해주세요.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“저, 그런데 위원장님.”</p>
      <p className={ss.quo}>“네? 왜요?”</p>
      <p className={ss.quo}>“혹시 학교운영위원회에서 사전에 의견 수렴을 해야 하는 절차가 있는 것 아세요?”</p>
      <p className={ss.quo}>“네? 의견 수렴이요?”</p>
      <p>윤미옥 위원장은 처음 듣는 이야기인 듯 했다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 간단하게 설명했다.</p>
      <p className={ss.quo}>“네. 졸업앨범이나 체험학습 같은, 학부모들이 돈을 내는 안건은, 운영위원회 하기 전에 5일 동안 홈페이지에 공지해서 학부모 의견을 받아야 해요.”</p>
      <p className={ss.quo}>“누가 그래요?”</p>
      <p className={ss.quo}>“법이 그래요. 「초중등교육법 시행령」하고 진부도교육청의 「학교운영위원회 설치·운영 조례」가 그래요.”</p>
      <p>&nbsp;</p>
      <p>윤미옥 위원장은 이내 대수롭지 않다는 듯이 대꾸했다.</p>
      <p className={ss.quo}>“그런가요? 그럼 그런가 보죠. 그게 왜요?”</p>
      <p className={ss.quo}>“좀 전에 절차를 잘 지키자고 하셨잖아요. 그래서 알려드린 거예요. 꼭 지켜야하는 절차니까.”</p>
      <p>&nbsp;</p>
      <p>독도 씨가 절차 이야기를 끄집어내자, 윤미옥 위원장은 언성을 높였다.</p>
      <p>마치 독도 씨가 <span className={ss.quo_mini}>‘절차를 지키라고 하더니 정작 당신은 왜 안 지켜?’</span>라고 핀잔을 준 것이라고 생각한 모양이었다.</p>
      <p className={ss.quo}>“아니 무슨, 그걸 왜 나한테 그러세요? 학교에다 말해야지요.”</p>
      <p>&nbsp;</p>
      <p>윤미옥 위원장과 달리 독도 씨는 차분하게 대꾸했다.</p>
      <p className={ss.quo}>“무슨 일이 있으면 먼저 위원장님한테 말해달라고 하셨잖아요. 그래서 말씀드린 거예요. 학교에 말씀드려주세요. 절차 잘 지키자고요.”</p>
      <div className={ss.annotation_wrap}>
        <p>이 절차는 운영위원장의 일이라고 앞에서 설명했다.</p>
        <p>하지만 독도 씨가 그것을 깨달은 것은 2017년 이후의 일이었다.</p>
        <p>이 시점에서는 독도 씨도 이 절차가 학교장의 의무라고 생각하고 있었다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={ss.quo}>“좋아요. 알겠어요. 일단 제가 좀 알아볼게요. 그런데 그렇게 하나하나 따지면 학교도 일이 너무 많을 거예요. 어떻게 일일이 의견 수렴을 하나요? 학교는 알아서 결정하면 되고, 학부모는 그냥 돈을 내면 됩니다. 그래야 서로 편해요.”</p>
      <p>윤미옥 위원장은 언짢다는 투로 거칠게 말했다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“그럴 거면 운영위원회를 할 필요가 없죠. 사람마다 생각이 다를 텐데, 위원장님이야 학교에서 결정한대로 따르자는 생각이겠지만, 누군가는 다른 의견이 있을 수도 있잖아요. 그래서 의견을 들어보고 반영해야 가급적 많은 사람이 만족할 수 있고 보다 합리적인 결론을 내릴 수 있는 거죠.”</p>
      <p>다시 또 논쟁이 시작될 분위기였다.</p>
      <p>&nbsp;</p>
      <p>하지만 윤미옥 위원장은 순간, <span className={ss.quo_mini}>‘이 사람은 쓸데없이 꼬장꼬장한 원칙주의자고 요령도 없고 현실을 몰라. 그러니 대꾸할 가치가 없어. 내가 열을 낼 필요가 없겠어.’</span>라는 생각을 했다.</p>
      <p>빠르게 평정심을 되찾은 윤미옥 위원장은, 다시 옅은 미소를 지으며 말했다.</p>
      <p className={ss.quo}>“어머나 정말, 부위원장님은 너무 원칙만 따지세요. 힘들지 않으세요?”</p>
      <p className={ss.quo_mini}>‘커피가 맛있네. 한 잔 더 시킬까?’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“네? 지금……, 그게 아니잖아요. 아…….”</p>
      <p>독도 씨는 더 이상 대화를 이어가기가 어렵다고 생각했다.</p>
      <p>운영위원회가 의견 수렴을 해야 된다는 이야기를 하고 있는데, 원칙주의자는 피곤하다는 비아냥거림은 또 무엇이란 말인가?</p>
      <p>&nbsp;</p>
      <p>잠시 정적이 흘렀다.</p>
      <p>독도 씨는 어떤 주제로 이야기를 더 진행해야할지 판단할 수 없었다.</p>
      <p className={ss.quo_mini}>‘그래 뭐, 무슨 일로 나를 찾아왔는지는 이제 알았고, 앞으로 여러모로 신경 써서 상대해야할 것 같고, 더 이상 대화는 잘 안 될 것 같고, 이제 그냥 미팅을 끝내야겠네.’</p>
      <p>두 사람 모두 커피는 다 마셨고, 분위기도 얼추 할 말이 다 끝난 분위기였다.</p>
      <p>&nbsp;</p>
      <p>그때, 잠시 골똘하게 무엇인가 생각을 하는 듯했던 윤미옥 위원장은, 독도 씨에게 자못 진지한 표정으로 말을 건넸다.</p>
      <p className={ss.quo}>“부위원장님. 엄마의 마음을 아세요?”</p>
      <p className={ss.quo}>“엄마의 마음이요?”</p>
      <p className={ss.quo}>“엄마들은요. 아빠들이랑 달라요. 아빠들은 뭘 자꾸 따지고 뭔가 거창하게 일을 하려고 하죠. 그게 다 알고 보면 자신을 위한 거예요. 그런데 엄마들은 오로지 자식 걱정만 해요. 나를 희생해서라도 자식을 돌보는 것이 엄마의 마음이에요. 학교에 가서 선생님들 비위 맞추는 것이 쉬울 것 같으세요? 아니에요. 하고 싶지 않아요. 그래도 자식 생각해서 하는 거예요. 그런 엄마들한테 원리 원칙 아무리 들이밀어도 소용없어요. 그건 아빠들의 욕심이에요. 선생님들 곤란하게 하지 마세요. 그러면 다 아이들한테 영향이 가요. 저나 다른 엄마들 모두 이제 부위원장님을 경계하고 주시하고 있다는 점 잊지 마세요. 모난 돌이 정 맞는다는 말 아시죠? 조심하셨으면 좋겠네요.”</p>
      <p>이야기를 하면서 윤미옥 위원장의 눈빛이 날카롭게 변해갔다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 윤미옥 위원장을 멍하니 쳐다보았다.</p>
      <p>이런 이야기가 나올 줄은 미처 생각하지 못했기 때문이었다.</p>
      <p>&nbsp;</p>
      <p>자신을 돌보지 않으면서까지 자식을 보호하려는 엄마의 행동은 숭고하다.</p>
      <p>모성애는 절대적인 사랑이란 것을 독도 씨도 잘 안다.</p>
      <p>절대적인 사랑은 계산하지 않는 무조건적인 사랑이다.</p>
      <p>하지만 이성적 판단이 없는 맹목적인 사랑은 자신과 상대방을 모두 다치게 할 수도 있다.</p>
      <p>집착을 수반하고 과도한 의존이나 통제를 행하기도 한다.</p>
      <p>윤미옥 위원장이 말하는 ‘엄마의 마음’이란 절대적인 사랑일까 아니면 맹목적인 사랑일까?</p>
      <p>&nbsp;</p>
      <p>이유 불문하고 교사를 곤란하지 않게 하여야한다는 것은 이성적인 판단일까?</p>
      <p>교사의 잘못된 부분을 지적하면, 아이들에게 나쁜 영향을 끼칠까?</p>
      <p>왜 선생님들의 비위를 맞추어야 하는 것일까?</p>
      <p>국가 교육이 소리 높여 외치는 ‘민주성·투명성·공정성’은 하등 쓸모없는 개념일까?</p>
      <p>교육을 생각하는 엄마와 아빠의 마음은 다른 것일까?</p>
      <div className={ss.annotation_wrap}>
        <p>대답할 필요도 없는 질문들이다.</p>
        <p>겉으로는 교사를 존중하는 척 하지만, 단지 비위를 맞추는 것뿐이라고 스스로 실토하고 있다.</p>
        <p>대개 이런 학부모는 사건·사고가 발생하면 맹목적으로 교사를 공격한다.</p>
        <p>단지 ‘점수’를 위해서 비위를 맞추겠다는 것이고, 오로지 진학을 위한 ‘점수’만이 교육의 목적이라고 생각하는 교육관이다.</p>
        <p>점수를 목적으로 한다고 해서 이를 비판할 생각은 없다.</p>
        <p>하지만 그 목적을 달성하기 위해서 학교자치를 왜곡하고 ‘민주성·투명성·공정성’(점수와는 다른 가치들)을 무가치하게 여기는 교육관은 비판받아 마땅하다.</p>
        <p>누누이 말하지만, 공교육은 입시학원이 아니다.</p>
        <p>국가 교육과정(학교민주주의)을 무시하고 ‘점수’만 받으려 한다면, 좀 더 빠른 검정고시를 택하지 않고 왜 굳이 학교를 고집하는가?</p>
        <p>공교육을 망가뜨리면서까지 자신의 욕심을 채우려는 행위를, 비뚤어진 교육관을, 맹목적인 사랑과 절대적인 사랑을 구분하지 못하는 탐욕을 ‘엄마의 마음’이라고 포장하는 것은 궤변이다.</p>
        <p>그리고 원리 원칙을 따지는 것은 아빠의 특성도 아니고, 욕심이나 자기만족도 아니다.</p>
        <p>엄마든 아빠든 그런 사람이 있을 뿐이며, ‘민주성·투명성·공정성’을 위한 목소리일 뿐이다.</p>
        <p>독도 씨는, 그런 사람이 많아야 된다고 주장하는 인물이다.</p>
        <p>피곤하다고 해서 그것을 피해서는 안 된다고 말한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>독도 씨는 더 이상 할 말이 없었다.</p>
      <p className={ss.quo}>“알겠습니다. 제가 다른 스케줄이 있어서요, 이만 일어났으면 좋겠습니다.”</p>
      <p>&nbsp;</p>
      <p>윤미옥 위원장도 하고 싶은 말은 다 한 모양이었다.</p>
      <p className={ss.quo}>“그래요. 그만 일어서죠. 오늘 나눈 이야기 잘 새겨들으셨기를 바랄게요.”</p>
      <p>&nbsp;</p>
      <p>독도 씨는 먼저 자리에서 일어났다.</p>
      <p className={ss.quo}>“먼저 가겠습니다. 위원장님도 조심해서 가세요.”</p>
      <p className={ss.quo}>“네. 다음에 뵈어요.”</p>
      <p>윤미옥 위원장의 인사를 뒤로 하고 독도 씨는 “조선 카페”를 나왔다.</p>
      <p>도로는 여전히 한산했으나, 독도 씨의 마음은 심란함으로 가득 찼다.</p>
      <p>그냥 아무 것도 아닌 일로 치부하고 신경 안 쓰면 그만인 것을, 그동안 모르고 살았던 것처럼 앞으로도 그렇게 살면 그만인 것을, 왜 자꾸 독도 씨의 생각은 학교를 향하고 있는 것일까?</p>
      <p>학교는, 아직 햇빛이 환한 오후였지만, 닫힌 교문 안쪽에 우리가 모르는 어떤 괴물이 숨어 사는 것처럼, 어둡고 음산하게 느껴졌다.</p>
      <p>&nbsp;</p>
      <p>한편, <span className={ss.quo_mini}>‘당신을 경계하고 주시하고 있다. 모난 돌이 정 맞는다. 조심하라.’</span>는 윤미옥 위원장의 말은 확실한 경고였다.</p>
      <p>실제로 이후 다른 학부모위원들은 독도 씨를 대놓고 경계했다.</p>
      <p>그들의 싸늘한 시선을 견디는 것은 쉽지 않은 일이었다.</p>
      <p>이날 이후, 모두 12명의 운영위원 가운데에서 독도 씨는 말 그대로 독도가 되었다.</p>
      <p className={ss.quo_mini}>‘이야. 이 나이에 학교에서 왕따를 다 경험해보네. 거참 고독하구만. 흐흐흐.’</p>
      <p>&nbsp;</p>
      <p>언젠가 술자리에서 김봉식 교감은 이렇게 말했다.</p>
      <p className={ss.quo}>“혼자 그래봐야 아무도 협조해 주지 않아. 그러면 당신이 지치지 않고 배겨?”</p>
      <p>뭐, 솔직히 그때 지치기는 했었지.</p>
      <p className={ss.bold}>그런데 말이야, 지친다고 포기하면 아무것도 지킬 수 없어.</p>
      <p className={ss.bold}>나도, 가족도, 나라도.</p>
    </div>
  )
}

export default Year2015_22