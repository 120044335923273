import React from 'react'
import ss from './MainText.module.css'

const Year2015_16 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>16. 당신의 자녀가 상을 받을 겁니다.</h1>
      <p>때는, 첫 회의가 끝나고 한 달쯤 지난 뒤의 일이었다.</p>
      <p>앞서 말했다시피, 그 사이에, 독도 씨가 교육청에 문의 민원을 넣은 일로 인해, 한비중학교의 임춘옥 교장은 잠시 긴장을 하기도 했다.</p>
      <p>하지만 지원청 조진일 팀장이 독도 씨로부터 더 이상 문제 삼지 않겠다는 약속을 받은 덕분에, 임춘옥 교장은 한 숨 돌리고 긴장을 풀 수 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘이거 그동안 대충 해도 문제가 없었는데, 쉽게 생각하면 안 되겠네. 그래도 이 사람은 잘 구슬리면 그럭저럭 넘어가는 모양이야. 지난번 민원이 생각보다 수월하게 해결된 걸 보면 말이지.’</p>
      <p>임춘옥 교장은 방금 <span className={ss.quo_mini}>‘잘 대처해야 한다.’</span>고 말한 김봉식 교감과, 교장실 앞에서 헤어져 독도 씨가 기다리고 있는 행정실로 걸어가는 중이었다.</p>
      <p className={ss.quo_mini}>‘그나저나 결산 자료 요청이라니, 듣도 보도 못한 해괴한 요청이네. 참 특이한 사람이야. 이거 어떻게 대응해야 하는 거야? 아휴, 머리 아파.’</p>
      <p>&nbsp;</p>
      <p>생각지도 못한 일들이 임춘옥 교장의 심기를 불편하게 했다.</p>
      <p><p>일주일 전, 난데없이 걸려온 독도 씨의 전화도 그랬다.</p>
        독도 씨는 갑자기 결산과 관련한 자료를 보고 싶다며, 제출을 요청했다.</p>
      <p>무척 당황스러운 일이었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“결산 자료요? 어떤 것을 말씀하시는 건지?”</p>
      <p className={ss.quo}>“돈을 쓰면 어디에 얼마를 어떻게 썼는지 기록을 하잖아요? 에듀파인이라는 학교회계프로그램에서 엑셀로 다운로드 할 수 있죠? 사업별, 항목별 지출 세부 내역이 기록된 파일을 보면 예산에 맞게 지출했는지 볼 수 있고, 역으로 예산을 적정하게 책정했는지 등도 확인할 수 있잖아요. 다른 것까지는 아직 모르겠지만, 이 정도는 알아야 결산 심의를 할 수 있을 것 같아서요.”</p>
      <div className={ss.annotation_wrap}>
        <p>예를 들자면, 심의 때 보았던 결산서에는 교재 구입비 200만 원, 비품 구입비 300만 원 등 지출한 금액이 항목별로 총액만 표기되어 있었는데, 독도 씨는 실제 어떤 물품을 구입했는지 그 세부 내역을 알고 싶었다.</p>
        <p>과학부에서는 실험기자재 구입비가 모자라 쩔쩔 매는데, 기술부에서는 컴퓨터실 비품 구입비가 남아서 잘 쓰지 않는 마우스 패드며 각종 케이블을 대량 구매했다가 결국 낡아서 버리는 경우가 있을 수 있다.(올해 예산을 남기면 다음 해 예산을 확보하기 어려워진다. 사업수행실적을 반영하기 때문이다. 그래서 불필요하더라도 배정된 예산을 모두 소비하려는 경향이 있다. 이런 부분을 잘 잡아내야 예산 배정을 합리적으로 할 수 있다.)</p>
        <p>학교마다 부서별로 이런 경우는 심심찮게 발생한다.</p>
        <p>급하지 않은 곳에는 예산이 충분하고, 반드시 필요한 곳에는 막상 예산이 부족하여 지출을 줄여야 하는 경우라 할 수 있다.</p>
        <p>예산의 우선순위를 정하는 것은 작성자의 주관이다.</p>
        <p>운영위원회에서 심의할 때 우선순위가 높은 곳에 예산을 제대로 배정했는지 확인하는 것도 중요하지만, 급하지 않은 곳에 과하게 배정된 예산이 없는지 확인하는 것도 필요하다.</p>
        <p>결산 심의를 제대로 하면 예산이 효과적으로 배정되었는지를 확인하는데 큰 도움이 된다.</p>
        <p><span className={ss.fbody}>“선생님들이 알아서 잘하고 있을 것이다.”</span>는 말은 제발 하지 말자.</p>
        <p>그런 조직은 세상에 없다.</p>
        <p>정부와 지방행정기관도, 의회도, 사법기관도, 검찰과 경찰도, 공기업도, 사기업도, 병원도, 언론도, 시민단체도, 심지어 천사의 모습을 한 봉사기관(기구, 단체)도 <span className={ss.bold}>아무도 들여다보지 않으면 아주 쉽게 부패한다.</span>(학교를 들여다보는 기구가 운영위원회이다. 하지만 거수기는 학교를 들여다보지 않는다.)</p>
        <p>사람은 믿을 수 없을 만큼 약한 존재다.</p>
        <p>견제하고 감시하는 시스템이 잘 작동해야 사회는 건강을 유지할 수 있다.</p>
        <p>학교가 청렴하고 깨끗할 것이라는 일반의 기대는 신기루와 같다.</p>
        <p>독도 씨의 이야기를 다 듣고 나면, 잘하고 있을 것이라는 믿음은, 아무 근거도 없는 허망한 믿음임을 알 수 있을 것이다.</p>
      </div>
      <p className={ss.quo}>“아니 부위원장님, 결산 심의는 이미 끝났잖아요?”</p>
      <p className={ss.quo}>“네. 그렇지만 궁금해서요. 세부 지출 내역 인쇄하는 것이 업무에 크게 지장이 되지는 않겠죠? 물론 신경이야 쓰이겠지만, 일의 중요성을 생각해서 도와 주셨으면 합니다. 부탁드려요.”</p>
      <p>임춘옥 교장은 생각지도 못한 요청에 정신이 멍해짐을 느꼈다.</p>
      <p className={ss.quo_mini}>‘아니, 이게 무슨 소리야. 결산 심의는 끝났는데 궁금하다고 자료를 요청해? 응? 그래도 되나?’</p>
      <p className={ss.quo}>“저, 부위원장님. 일단 제가 생각을 좀 해봐야겠네요. 가능한 일인지도 모르겠고, 해도 되는 일인지도 모르겠고요. 제가 행정실장하고 이야기를 해보고 연락을 드릴게요.”</p>
      <p className={ss.quo}>“네. 알겠습니다. 전화 주세요.”</p>
      <p>&nbsp;</p>
      <p>그 뒤 임춘옥 교장은 김미희 행정실장을 호출해 관련 대화를 나눴다.</p>
      <p>놀란 김미희 행정실장은 분노하며 어쩔 줄 몰라 했다.</p>
      <p className={ss.quo}>“그런 이야기는 들어본 적이 없어요! 무슨 행정감사 하는 것도 아니고, 그걸 왜 줘야하는데요?”</p>
      <p className={ss.quo}>“그러게 말이에요. 제가 혹시나 싶어 우리 학교 운영위원회 규정을 봤는데, 일단 심의에 직접 관련된 자료를 학교에 요구하려면 운영위원회 재적위원 과반수의 의결이 필요하더라고요. 게다가 이미 심의가 끝난 뒤라서, 심의에 직접 관련된 자료라고 볼 수도 없고요.”</p>
      <p className={ss.quo}>“아니 참, 골 때리는 사람이네. 안 된다고 하세요. 규정에 없는 일이라면 저는 못합니다.”</p>
      <p className={ss.quo}>“그게 사실은. 음……, 조금 문제가 있어서 막 쉽게 거절하고 그럴 상황은 아닙니다.”</p>
      <p>&nbsp;</p>
      <p>임춘옥 교장은 며칠 전 지원청 조 팀장과의 두 번째 전화를 떠올렸다.</p>
      <p>조 팀장은, <span className={ss.quo_mini}>‘교장선생님, 당분간 나독도 위원에게는 각별하게 신경을 써 주셔야 합니다. 어차피 시간이 지나면 그 분도 다른 위원들처럼 평범하게 될 겁니다. 원래 처음에 의욕이 넘쳐서 그러시는 분들이 종종 있어요. 그런 사람들을 잘 다루시는 게 관리자의 업무잖아요.’</span>라고 말했다.</p>
      <p>&nbsp;</p>
      <p>지친 표정의 임춘옥 교장은 행정실장을 달래듯이 차분히 말했다.</p>
      <p className={ss.quo}>“일단 알겠습니다. 규정도 그렇고 전례도 없는 일이니까 안 되는 것은 확실하고요. 저도 그럴 마음은 없어요. 다만, 단칼에 거절할 상황은 아니라서, 제가 설득을 해야겠죠. 전화를 한 번 해볼게요. 실장님은 신경 쓰지 마세요.”</p>
      <p>그리고 임춘옥 교장은 독도 씨에게 전화를 걸어, ‘쉽게 결정할 일이 아닙니다. 한 번 뵙고 이야기를 나누시죠.’라면서 독도 씨와 면담 일정을 잡았다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“안녕하세요? 잘 지내셨나요?”</p>
      <p>반갑다는 표정을 지으며 임춘옥 교장이 행정실로 들어섰다.</p>
      <p>행정실에는 다섯 명의 직원이 있었는데 모두 조용히 업무에 집중하고 있었고, 밖의 운동장에서는 체육활동을 하는 학생들의 소리가 나지막이 들려왔다.</p>
      <p>교장이 들어서자, 소파에 앉아 기다리고 있던 독도 씨가 일어섰다.</p>
      <p className={ss.quo}>“교장선생님, 안녕하세요?”</p>
      <p className={ss.quo}>“어휴, 한 달 만에 뵙네요. 별일 없으셨죠?”</p>
      <p className={ss.quo}>“그럼요. 별일 없었죠.”</p>
      <p>의례적인 인사말이 오갔고, 임춘옥 교장은 행정실 직원에게 차 두 잔을 부탁했다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“세부 지출 내역을 달라고 하셨죠?”</p>
      <p className={ss.quo}>“네. 그렇습니다.”</p>
      <p className={ss.quo}>“그런데 좀 곤란합니다. 사실 서류 요청을 하려면 운영위원회 의결이 있어야 해서요.”</p>
      <p className={ss.quo}>“저도 압니다. 운영위원회에서 의결을 하면 그때는 의무적으로 제출을 하셔야 하고요. 그건 요구에 해당되는 일이죠. 저는 요구가 아니라, 교장선생님께 부탁을 드리는 겁니다.”</p>
      <p>독도 씨는 서류를 제출할 의무와, 그렇지 않은 상황에서의 요청을 구분하고 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“왜 그러시죠? 제가 잘 이해가 안 돼서요.”</p>
      <p className={ss.quo}>“그건, 그때도 말씀드렸지만, 중요한 심의임에도 불구하고 아무도 심의할 줄을 모르기 때문입니다. 당장 나중에 있을 예산 심의, 그리고 앞으로 매년 하게 될 결산 심의가 이런 식이면 운영위원회가 있을 필요가 없다고 생각이 돼서요. 그래서 공부를 하고 싶습니다. 공부를 해서 참고할 수 있는 ‘가이드북’ 같은 것을 만들어보려고요. 그러면 앞으로 한비중학교 운영위원들이 예·결산 심의할 때 도움이 되지 않겠어요?”</p>
      <p>학교장에게 이것은 일종의 도발이었다.</p>
      <p>그렇지 않겠는가? 예·결산 심의를 정석대로 꼼꼼하게 한다는 것은, 교장의 입장에서는 아주, 매우, 끔찍하게 싫은 일일 수밖에 없다.</p>
      <p>교장이 좋아할 리가 없는 일을 당당하게 요구한다는 것은 도발이다.</p>
      <p>그러나 독도 씨는, 학교장이 좋아하지는 않으리라 생각했지만, 혹시 순수한 의도라면 협조해주지 않을까 하는 기대를 가졌었다.</p>
      <p>도발하고자 하는 마음은 없었다.</p>
      <div className={ss.annotation_wrap}>
        <p>예·결산 심의를 제대로 하는 것은 당연히 옳은 일이므로, 학교장은 준비를 잘하고 긍정적으로 받아들여야 한다.</p>
        <p>그러나 그동안 그렇게 운영되지 않은 것이 너무 좋아서, 꿀만 빨았기 때문에, 정상적인 심의를 받아들이기 어려운 것이다.</p>
        <p>학교는 더욱 솔선수범해야 하는 ‘교육’기관이라는 사실을 우리는 다시 한 번 상기해야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘이 사람은 나를 놀리는 것일까? 아니 가계부 검사한다고 내놓으라고 하면 좋아할 주부가 있어?’</p>
      <div className={ss.annotation_wrap}>
        <p>임춘옥 교장의 이런 비유는 합당하지 않다.</p>
        <p>그런데 실제로 대화를 나눠보면 이렇게 생각하는 교장이 상당히 많다.</p>
        <p>이는 민주주의에 대한 이해가 부족하기 때문이다.</p>
        <p>민주주의를 제대로 배우지 못한 세대가 민주주의를 가르치려니 여러 가지로 어렵기는 할 것이다.</p>
      </div>
      <p>임춘옥 교장은 어떻게 거절하는 것이 좋을까 생각해 보았다.</p>
      <p className={ss.quo_mini}>‘그래, 김봉식 교감 말처럼, 규정대로 하자고 하면 문제될 것이 없겠지.’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“부위원장님, 무슨 말씀인지는 알겠습니다. 그런데 일단은요, 학교 규정상 안 되는 일이라서 어렵겠습니다. 부탁한다고 다 들어드릴 수는 없어요. 행정실장의 일이라서 제가 마음대로 지시할 수도 없고요. 정 원하시면 운영위원회에서 의결해 주세요. 그러면 저도 마음 편하게 공개할 수 있습니다.”</p>
      <p>그렇다.</p>
      <p>부탁한다고 다 들어줄 필요는 없다.</p>
      <p>또한 부탁을 들어주지 않는다고 해서 비판해서도 안 된다.</p>
      <p>반드시 필요한 것이라면 의결을 거치면 된다.</p>
      <p>독도 씨도 이 점은 이해를 했다.</p>
      <p className={ss.quo}>“알겠습니다. 교장선생님 뜻이 그러시다면 더 부탁드리기는 어렵겠네요. 다음번 운영위원회 때 건의해서 의결해보도록 하겠습니다.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘어느 정도 예상은 했다. 그래도 혹시나 하는 기대가 있었는데 역시나 안 되네.’</p>
      <p>&nbsp;</p>
      <p>독도 씨는 다른 이야기를 꺼내기로 했다.</p>
      <p className={ss.quo}>“그런데 교장선생님, 저도 규정은 지켜야한다고 생각하는 사람인데요. 이번 운영위원회 관련해서 몇 가지 규정에 어긋나는 것이 있어서, 이왕 온 김에 말씀 좀 드려야겠습니다.”</p>
      <p>임춘옥 교장은 자신의 몸이 순간 경직되는 것을 느꼈다.</p>
      <p className={ss.quo_mini}>‘뭐지? 뭐가 또 문제가 있었나?’</p>
      <p>&nbsp;</p>
      <p>독도 씨는 먼저 의견 수렴의 문제를 지적했다.</p>
      <p className={ss.quo}>“졸업앨범 안건과 체험학습 안건 같은 학부모가 경비를 부담하는 안건의 경우, 사전에 5일간 학부모에게 공지를 하고 의견을 수렴해야 하는 규정이 있습니다. 시행령에도 있고 조례에도 있고, 우리 학교 규정에도 있습니다. 그런데 공지하시 않으셨죠?”</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“아, 그런가요? 그게…….”</p>
      <p>임춘옥 교장은 몹시 당황했다. 생각지도 못한 일이었다.</p>
      <p className={ss.quo_mini}>‘그랬던가? 그런 조항이 있었던 것 같기는 한데…….’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“실장님!”</p>
      <p className={ss.quo}>“네. 교장선생님.”</p>
      <p className={ss.quo}>“우리 학교 운영위원회 규정 좀 볼 수 있을까요?”</p>
      <p className={ss.quo}>“네. 잠시만 기다려주세요.”</p>
      <p>김미희 행정실장은 파티션 뒤에서 두 사람의 대화를, 처음부터 주의 깊게 듣고 있었다.</p>
      <p className={ss.quo_mini}>‘어? 저 사람 그것도 알고 있네? 그거 귀찮기도 하고 알려봐야 좋을 것 없다고 교장선생님이 하지 말라고 하셨던 일인데.’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“여기 있습니다.”</p>
      <p>잠시 후 김미희 행정실장이 한비중학교 「학교운영위원회 규정」이 인쇄된 종이를 가지고 와서 임춘옥 교장에게 건넸다.</p>
      <p>임춘옥 교장은 빠르게 해당 조항을 찾아 읽었다. 그리고 읽자마자 기억이 떠올랐다.</p>
      <p className={ss.quo_mini}>‘아, 맞다. 내가 이거 알릴 필요 없다고 했어. 일일이 공지하기도 귀찮고, 또 괜히 알려봐야 귀찮은 일만 생긴다고 내가 하지 말라고 지시했지. 아니 근데 이런 건 또 어떻게 알았대?’</p>
      <p className={ss.quo}>“맞습니다. 공지해야 하는 일입니다. 깜박 했습니다. 원래는 저도 알고 있었는데, 절차란 게 워낙 많고, 그러다 보니 다 기억하기 힘든 부분이 있습니다. 실수했네요.”</p>
      <p>&nbsp;</p>
      <p>독도 씨는 다소 어이없다는 듯이 임춘옥 교장을 바라보았다.</p>
      <p className={ss.quo}>“지역위원 선출 절차도 그렇고, 아니 어째서 중요한 절차를 다 잊을 수가 있을까요?”</p>
      <p>&nbsp;</p>
      <p>임춘옥 교장은 어떻게 변명하는 것이 좋을지 생각하느라 정신이 없었다.</p>
      <p className={ss.quo}>“그렇게 되었네요. 학년 초라서 업무가 한꺼번에 몰리다보니 이런 실수가 종종 있습니다.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“그것뿐이 아닙니다. 운영위원회 개최 시 가정통신문이나 학교 홈페이지 등에 공지해야 합니다. 그것도 규정을 위반하셨네요.”</p>
      <p>임춘옥 교장은 점점 궁지에 몰리는 듯한 기분이 들었다.</p>
      <p className={ss.quo}>“네. 그것도 실수했네요. 이번에 실수가 좀 많았습니다. 죄송합니다.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“학부모들이 운영위원회를 하는 지도 모르고, 경비를 부담할 때 의견을 내지도 못하고, 이러면 안 되는 것으로 알아요. 이거 심의 다시 해야 하는 거 아닙니까?”</p>
      <p>말하다보니 조금 흥분이 되어, 독도 씨는 격앙된 목소리로 따지기 시작했다.</p>
      <p>&nbsp;</p>
      <p>임춘옥 교장은 등 뒤로 식은땀이 흐르는 것을 느꼈다.</p>
      <p>분위기가 좋지 않았다.</p>
      <p>이제 좀 더 적극적으로 대응할 필요가 있었다.</p>
      <p className={ss.quo}>“부위원장님. 일단 저희가 실수한 것은 맞고요. 그렇지만 규정이 그런 것이지 실제로 공지하지 않았다고 해서 그다지 문제될 일은 없습니다. 어차피 운영위원 중에 학부모위원님들이 있어서 학부모님들의 뜻은 다 반영이 되고요. 홈페이지에 올린다고 해서 그것을 읽거나, 또 의견을 내는 학부모님들도 사실 없어요. 실질적으로는 불필요한 절차에요.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“그렇게 생각하지 않습니다. 읽는 사람이 없을 것이라고 어떻게 장담하세요?”</p>
      <p className={ss.quo}>“다른 공지사항의 예를 봐도 그래요. 읽는 사람이 없습니다. 홈페이지에는 아예 방문을 안 하세요. 가정통신문을 보내도, 자녀들과 직접 관련이 있는 내용이 아니면 잘 읽지 않습니다. 반응이 없어요. 저도 교직생활을 30년 넘게 했습니다. 정말 불필요한 일이에요. 그러다 보니 저희가 소홀하게 되는 면도 있습니다. 이해해 주시면 좋겠어요.”</p>
      <p>&nbsp;</p>
      <p>아주 틀린 말은 아니었다.</p>
      <p>대개의 학부모들은 그런 일에 별로 관심을 가지지 않는다.</p>
      <p>하지만 독도 씨는 그렇다고 해서 안 해도 될 일이라고는 생각하지 않았다.</p>
      <p className={ss.quo}>“투표율이 낮다고 해서 투표를 안 해도 그만이라고 생각해서는 안 되겠죠. 그렇다고 해도 우리는 투표를 독려해야 하는 겁니다. 이 규정도 마찬가지에요. 사람들이 관심이 없다고 해도 우리는 공지를 해야 합니다. 그럴수록 관심을 가지게 하는 것이 필요한 일이지 규정을 무시해도 되는 것이 아니라고 생각합니다. 투표나 이런 규정들이 민주주의의 모든 것을 의미하지는 않지만, 최소한의 절차로서 반드시 지켜져야 하는 겁니다.”</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘뭐 이런 백면서생 같은 사람이 다 있어. 그걸 누가 몰라? 현실적으로 생각을 해야지. 다들 그렇게 잘 지키면 우리나라가 벌써 선진국 되었겠지.’</p>
      <p>임춘옥 교장은 자신을 가르치려 드는 독도 씨가 싫어지기 시작했다.</p>
      <p>그러나 참아야했다.</p>
      <p>별 수 없었다.</p>
      <p>문제를 키우기보다는 어르고 달래서 덮는 것이 자신과 교육계를 위해서 필요한 일이었다.</p>
      <p className={ss.quo}>“네, 잘 알겠습니다. 저도 그렇게 생각합니다. 다만 현실에는 다소 어려움이 있다는 것이지요. 다음부터는 잘 지키도록 하겠습니다.”</p>
      <p>&nbsp;</p>
      <p>자신이 너무 흥분한 것은 아닌가 싶어, 감정을 가라앉히기 위해 독도 씨는 잠시 눈을 감았다.</p>
      <p>짧은 시간 정적이 흘렀다.</p>
      <p>학교장은 계속해서 불안한 표정으로 독도 씨를 지켜보았다.</p>
      <p>&nbsp;</p>
      <p>잠시 후 눈을 뜬 독도 씨는 한 가지 의견을 제시했다.</p>
      <p className={ss.quo}>“교장선생님, 지역위원 선출 절차 문제는 따지지 않기로 했으니까 넘어갈게요. 하지만 의견 수렴 절차를 위반한 것은, 그냥 넘어갈 일이 아니라고 생각합니다. 두 안건에 대해서는 심의를 다시 하시죠?”</p>
      <p>&nbsp;</p>
      <p>이게 무슨 말도 안 되는 소린가!</p>
      <p>심의를 다시 한다는 것은 있을 수 없는 일이다.</p>
      <p>회의를 소집하고 안건을 설명하고 가결되어 종료될 때까지, 아무 것도 아닌 것 같지만 학교장 입장에서는 여간 스트레스 받는 일이 아니다.</p>
      <p>그 귀찮은 일을 다시 하자고?</p>
      <p>게다가 자신이 잘못했다는 근거가 회의록 등의 기록으로 남게 된다.</p>
      <p>&nbsp;</p>
      <p>하지만 그렇다고 무시했다가, 독도 씨가 교육청에 민원을 넣으면 문제가 더 커질 것이다.</p>
      <p className={ss.quo}>“부위원장님, 그건 있을 수 없는 일입니다. 끝난 심의를 다시 하다니요. 그러면 아무리 빨라도 다시 2~3주 걸립니다. 위원님들 참석 가능한 일정 잡는데 1~2주 걸리고, 또 최소 일주일 전에 일정을 통보해야 하니까요. 그렇게 하다보면 학사 일정에 지장이 많이 생깁니다. 당장 다음 주에 있을 체험학습도 못갑니다. 이번에 미루면 다시 날짜 잡기도 어려워요. 실망한 아이들의 반발도 클 것이고 학부모들도 문제를 제기할 겁니다. 파장이 커요.”</p>
      <p>임춘옥 교장은 쉬지 않고 말을 이어갔다.</p>
      <p className={ss.quo}>“다음부터는 규정대로 하겠습니다. 그러니 이번에는 그냥 넘어가주시죠. 부탁드립니다.”</p>
      <p>&nbsp;</p>
      <p>이런 대화는 힘들고 지치는 대화다.</p>
      <p className={ss.quo_mini}>‘설마 또 민원을 넣는 것은 아니겠지?’</p>
      <p>임춘옥 교장은 독도 씨를 빨리 설득해서 대화를 끝내고 싶었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“제 부탁은 안 들어 주시면서 저한테 부탁은 잘하시네요. 그럼 이렇게 하시죠. 이번에 있었던 모든 규정 위반 사항은 다 모르는 일로 하겠습니다. 대신 결산 자료 주세요.”</p>
      <p>독도 씨는 나름 합리적이라고 생각해서 거래를 요청했다.</p>
      <p>하지만 두 가지 모두 불가능한 것을 양자택일 하라고 하면 거래가 이루어질 수 없다.</p>
      <p>&nbsp;</p>
      <p>그래서 기어이, 임춘옥 교장은 최후의 카드를 꺼낼 수밖에 없었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“아드님이 부위원장님 닮아서 그런지 똑똑하고 야무지더군요. 학교생활을 아주 잘합니다.”</p>
      <p className={ss.quo_mini}>‘어라? 이게 무슨 소리지? 왜 갑자기?’</p>
      <p>독도 씨는 뜬금없는 아들 이야기에 잠시 하던 이야기를 잊었다.</p>
      <p className={ss.quo}>“이번에 밤톨이가 상을 하나 받게 되었어요. 학교생활에 모범인 학생에게 주는 학교장 특별상입니다.”</p>
      <p className={ss.quo}>“예? 갑자기 상이요?”</p>
      <p className={ss.quo}>“네. 좋으시겠어요.”</p>
      <p>&nbsp;</p>
      <p>전혀, 생각지도 못한 전개였다.</p>
      <p>그것은, 정말……, 상상을 초월한, 믿을 수 없는, 자신의 귀를 의심할 수밖에 없는, 그런 제안이었다.</p>
      <p>만일 서 있었다면, 아마도 독도 씨는 크게 휘청거렸을 것이다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“이번에는 그냥 넘어가 주시죠.”</p>
      <p>임춘옥 교장은 조용히, 단호하게 거래조건을 제시했다.</p>
      <p>마치 <span className={ss.quo_mini}>‘이 정도 조건이면 무조건 받을 수밖에 없어.’</span>라고 말하는 듯한 표정이었다.</p>
      <div className={ss.annotation_wrap}>
        <p>부종시는 비평준화 지역이고, 중학생이 교내에서 받는 각종 대회 수상과 표창장 등은 하나당 0.5점으로 내신 성적에 포함된다.</p>
        <p>경쟁이 치열한 고등학교에 입학원서를 넣을 경우, 소수점 단위에서 당락이 결정되기 때문에 0.5점은 매우 큰 점수이다.</p>
        <p>3년 동안 이런 상을 4개 받으면 못 받은 학생에 비해 무려 2점의 이득이 발생한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그다지 대단할 것 없는 이야기라도, 뜻밖의 순간에 들으면 잠시 정신이 혼미해지기 마련이다.</p>
      <p>하물며 이 이야기는, 보는 관점에 따라서 매우 심각한 이야기가 될 수 있다.</p>
      <p>독도 씨의 머릿속에서 갑자기 오만가지 생각이 날뛰기 시작했다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘상이라고? 지금 모른 척 넘어가면, 아들한테 상을 주겠다는 거지?’</p>
      <p className={ss.quo_mini}>‘학생에게 주는 상을 학부모와 거래하기 위해서 줄 수도 있다고?’</p>
      <p className={ss.quo_mini}>‘그러니까 교장한테 협조 잘하면 상 준다는 거잖아? 운영위원이나 학부모회나 기타 학교에서 학부모가 열심히 활동하면 애들이 점수를 잘 받고 상을 잘 받는다고 하는 게 사실이었어? 아니 이게 음모론이 아니고 진짜 있는 일이야? 진짜?’</p>
      <p className={ss.quo_mini}>‘여기는 비평준화 지역이라서, 상을 받으면 내신점수가 올라가고, 고등학교 입시에 유리해지는 거잖아? 그런데 상을 가지고 거래를 한다고?’</p>
      <p className={ss.quo_mini}>‘이건! 불공정한 일이잖아? 입시 비리라고!’</p>
      <p>정신을 차릴 수가 없었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘잠깐만, 그럼 반대로, 애들이 아무리 잘해도, 학부모가 미우면 상을 안 줄 수도 있겠네?’</p>
      <p className={ss.quo_mini}>‘정량 평가가 되는 상이면 몰라도, 정성 평가되는 상은 모조리 포기해야 되는 거네?’</p>
      <p className={ss.quo_mini}>‘상은커녕 수행 평가 점수도 불리할 수 있겠네?’</p>
      <p className={ss.quo_mini}>‘학교가……, 그런 곳이었어?’</p>
      <p>생각은 꼬리에 꼬리를 물고 끝없이 비약하고 있었다.</p>
      <p>&nbsp;</p>
      <p>아주 짧은 시간이었지만, 독도 씨에게는 무척 긴 시간이 흐른 것처럼 느껴졌다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“음……. 교장선생님, 무슨 말씀을 드려야할지 모르겠습니다. 아이가 잘 해서 상을 주신다는 건지, 아니면 저한테 협조를 요구하시는 것인지 잘 모르겠습니다.”</p>
      <p className={ss.quo}>“아이가 잘해서 준다는 것이지요. 상 받는 걸 미리 말씀드린다고 문제가 되지는 않습니다. 그리고 협조를 부탁드리는 것이 맞습니다만, 협조의 대가로 상을 주겠다는 것은 아닙니다. 그런 일은 있을 수 없는 일이지요. 큰일 날 일입니다. 그렇게 생각하지는 말아주세요.”</p>
      <p>임춘옥 교장은 차분하게, 아무 문제가 없는 일이라고 설명했다.</p>
      <p>&nbsp;</p>
      <p>이제는 반대로 독도 씨가 땀을 흘리고 있었다.</p>
      <p className={ss.quo_mini}>‘그래 뭐, 술도 마셨고 운전도 했지만, 음주운전은 아니라고 우기는 것이겠지.’</p>
      <p>이 시점에서 독도 씨가 고민한 것은, 아들이 상을 받느냐 마느냐의 문제가 아니었다.</p>
      <p>반대로 아들이 학교생활에서 피해를 입지는 않을까하는 걱정이었다.</p>
      <p className={ss.quo_mini}>‘나는 아들 성적이나 입시에 목숨 거는 사람은 아니야. 그건 아들 본인이 알아서 할 문제니까 내가 도와줄 것은 없어. 하지만 나 때문에 아들이 피해를 입어서는 안 되지. 어쩔 수 없이 그렇게 될 것이라면 최소한 아들한테 물어보고 결정해야해.’</p>
      <p>독도 씨는 빠른 결정을 내렸다.</p>
      <p className={ss.quo_mini}>‘역시 애들이 볼모라는 말은 틀린 말이 아니네. 더럽고 치사한 일이야. 오늘은 그만 물러서야겠군.’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“좋습니다. 교장선생님. 제가 좀 무리한 부탁을 한 것 같습니다. 자료 요청은 철회하겠습니다. 그리고 절차 위반한 부분도 그냥 제가 조언한 것으로 받아들여 주세요. 문제 삼지 않겠습니다.”</p>
      <p className={ss.quo}>“어휴, 감사합니다. 앞으로는 실수 없도록 잘하겠습니다.”</p>
      <p className={ss.quo}>“그런데 특별상 이야기는 못들은 것으로 하겠습니다. 저로서는 순수한 의미로 받아들여지지 않습니다.”</p>
      <p className={ss.quo}>“아, 그러세요? 그러시면……. 참고하겠습니다.”</p>
      <p className={ss.quo}>“그럼, 교장선생님. 저는 이만 돌아가 보겠습니다.”</p>
      <p className={ss.quo}>“네. 부위원장님. 오늘 좋은 말씀 많이 해주셔서 감사합니다. 또 뵙겠습니다.”</p>
      <p className={ss.quo}>“네. 감사합니다. 수고하십시오.”</p>
      <p>&nbsp;</p>
      <p>독도 씨가 한비중학교에 간 것은 이 날이 세 번째였다.</p>
      <p>학부모위원 후보 접수하러 한 번.</p>
      <p>첫 회의 때 한 번.</p>
      <p>그리고 오늘 임춘옥 교장과 면담하기 위해 한 번.</p>
      <p>처음 학교에 갔다 오는 길은 몸이 가벼웠지만, 그다음부터는 돌아오는 길의 발걸음이 점점 더 무거워졌다.</p>
      <p>학교가 아이들을 잡아 가둔 괴물처럼 느껴지는 것은, 자신이 학생일 때나 학부모가 된 뒤에나 다를 바가 없었다.</p>
    </div>
  )
}

export default Year2015_16