import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './School3_detail.module.css'
import char2 from '../../img/char2.png'
import char1 from '../../img/char1.png'
import dokdo from '../../img/dokdo.png'
import { jwtDecode } from 'jwt-decode'

const School3_detail = () => {

   const { year } = useParams()
   const navigate = useNavigate()

   const inputRef1 = useRef(null);
   const inputRef2 = useRef(null);

   const data = [
      { year: 'prologue', index: 1, title: '학교 그리고 명제.', },
      { year: 'prologue', index: 2, title: '학교의 역할은 무엇인가?', },
      { year: 'prologue', index: 3, title: '우리가 학교에서 처음 배우는 것.', },
      { year: 'prologue', index: 4, title: '규정은 누가 만들었을까?', },
      { year: 'prologue', index: 5, title: '대한민국은 민주공화국이다.', },
      { year: 'prologue', index: 6, title: '민주주의의 의미.', },
      { year: 'prologue', index: 7, title: '학교의 주인은 누구인가?', },
      { year: 'prologue', index: 8, title: '그래도 학생이 주인이다?', },
      { year: 'prologue', index: 9, title: '규정개정심의위원회.', },
      { year: 'prologue', index: 10, title: '있는데 없다.', },
      { year: 'prologue', index: 11, title: '배우지 못한 절차.', },
      { year: 'prologue', index: 12, title: '질문의 시작점.', },
      { year: 'prologue', index: 13, title: '전제주의거나 독재거나.', },
      { year: 'prologue', index: 14, title: '세뇌의 이유.', },
      { year: 'prologue', index: 15, title: '질문의 대답.', },
      { year: 'prologue', index: 16, title: '백년을 위한 걸음.', },
      { year: '2015', index: 1, title: '교장실에서.', },
      { year: '2015', index: 2, title: '가정통신문.', },
      { year: '2015', index: 3, title: '무투표 당선.', },
      { year: '2015', index: 4, title: '무언가 이상한 것.', },
      { year: '2015', index: 5, title: '첫 번째 회의.', },
      { year: '2015', index: 6, title: '폐회 선언.', },
      { year: '2015', index: 7, title: '회식이 필요했던 이유.', },
      { year: '2015', index: 8, title: '학교장의 위법 행위.', },
      { year: '2015', index: 9, title: '지역위원은 균형의 추.', },
      { year: '2015', index: 10, title: '우리가 관심을 가져야할 것.', },
      { year: '2015', index: 11, title: '다른 문제들(1).', },
      { year: '2015', index: 12, title: '다른 문제들(2)', },
      { year: '2015', index: 13, title: '교육청에 문의하다.', },
      { year: '2015', index: 14, title: '교육지원청의 전화(1).', },
      { year: '2015', index: 15, title: '교육지원청의 전화(2).', },
      { year: '2015', index: 16, title: '당신의 자녀가 상을 받을 겁니다.', },
      { year: '2015', index: 17, title: '아들에게.', },
      { year: '2015', index: 18, title: '다른 문제들(3).', },
      { year: '2015', index: 19, title: '학부모가 학교에 가는 이유.', },
      { year: '2015', index: 20, title: '스승의 그림자도 밟지 마라.', },
      { year: '2015', index: 21, title: '칼럼(1) - 원탁의 기사.', },
      { year: '2015', index: 22, title: '첫 번째 왕따.', },
      { year: '2015', index: 23, title: '즐거운 운영위원 연수.', },
      { year: '2015', index: 24, title: '배고파요, 아빠.', },
      { year: '2015', index: 25, title: '급식 논쟁(1).', },
      { year: '2015', index: 26, title: '급식 논쟁(2).', new: true },
      { year: '2015', index: 27, title: '급식 논쟁(3).', },
      { year: '2015', index: 28, title: '칼럼(2) - 조선시대의 학부모에게.', },
      { year: '2015', index: 29, title: '학교 홈페이지 운영의 문제.', },
      { year: '2015', index: 30, title: '예산 심의, 그리고 혼자가 아니다.', },
   ]

   useEffect(() => {
      if (!(year === '2015' || year === '2016' || year === '2017' || year === '2018' || year === '2019' || year === '2020' || year === 'prologue' || year === 'epilogue')) {
         navigate('/not-found')
      }
   }, [year])

   useEffect(() => {
      const handleInputChange = () => {
         if (inputRef1.current && inputRef2.current) {
            if (inputRef1.current.checked === true) {
               sessionStorage.setItem(`${year}`, '1')
            }
            if (inputRef2.current.checked === true) {
               sessionStorage.setItem(`${year}`, '2')
            }
         }
      };

      if (inputRef1.current && inputRef2.current) {
         inputRef1.current.addEventListener('change', handleInputChange);
         inputRef2.current.addEventListener('change', handleInputChange);
      }

      return () => {
         if (inputRef1.current && inputRef2.current) {
            inputRef1.current.removeEventListener('change', handleInputChange);
            inputRef2.current.removeEventListener('change', handleInputChange);
         }
      };
   }, [])

   useEffect(() => {
      if (sessionStorage.getItem(`${year}`) && sessionStorage.getItem(`${year}`) === '2') {
         changeToPage2()
      }
   }, [year])

   const convertTitle = (title) => {
      switch (title) {
         case 'prologue':
            return '『학교 3.0』 서문'
         case 'epilogue':
            return '맺는 말'
         default:
            return title
      }
   }

   const handleClick = (itemYear, itemIndex) => {
      navigate(`/read/${itemYear}_${itemIndex}`)
   }

   const goViewAll = () => {
      navigate(`/read/${year}_all`)
   }

   const changeToPage2 = () => {
      if (inputRef2.current) {
         inputRef2.current.checked = true;
      }
   };

   return (
      <div className={styles.wrap}>

         <div className={styles.cover} style={{ marginTop: '100px' }}>
            <div className={styles.book}>
               <label htmlFor="page-1" className={`${styles.book__page} ${styles.book__page__1}`}>
                  <div className='center-col' style={{ margin: 'auto' }}>
                     <h1 style={{ fontFamily: 'gg_head', marginBottom: '15px' }}>{convertTitle(year)}</h1>
                     <p className={styles.view_all} onClick={() => goViewAll()}>한 번에 읽기</p>
                     {year === 'prologue' && (
                        <p className={styles.view_all} onClick={() => navigate('/school3.0/2015')}>2015 읽기</p>
                     )}
                     {year === '2015' && (
                        <p className={styles.view_all} onClick={() => navigate('/school3.0/prologue')}>서문 읽기</p>
                     )}
                  </div>
                  <img src={char2} style={{ position: 'absolute', width: '50px', left: 20, bottom: 20 }} />
               </label>

               <label htmlFor="page-2" className={`${styles.book__page} ${year === 'prologue' ? styles.book__page__4 : styles.book__page__1}`}>
                  {year === 'prologue' && (
                     <>
                        <img src={char2} style={{ position: 'absolute', width: '50px', right: 20, bottom: 20 }} />
                        <div style={{ position: 'absolute', bottom: 55, left: 30 }}>
                           <p style={{ fontFamily: 'gg_body', marginBottom: '10px' }}>나독도作, Prison in the Island,</p>
                           <p style={{ fontFamily: 'gg_body' }}>수채화, 2015년 겨울.</p>
                        </div>
                     </>
                  )}
                  {year === '2015' && (
                     <div className={styles.page__content}>
                        <img src={char2} style={{ position: 'absolute', width: '50px', right: 20, bottom: 20 }} />
                        <h1 className={styles.page__content_title}>목차</h1>
                        {data.filter(item => item.year === year && item.index > 20 && item.index <= 30).map((item, idx) => (
                           <div key={idx} onClick={() => handleClick(item.year, item.index)}>
                              <p className={styles.content_p}>{`${item.index}. ${item.title}`}{item.new === true && <span className={styles.is_new_emblem}>최신</span>}</p>
                           </div>
                        ))}
                     </div>
                  )}

               </label>

               {/* <!-- Resets the page --> */}
               <input type="radio" name="page" id="page-1" ref={inputRef1} />

               {/* <!-- Goes to the second page --> */}
               <input type="radio" name="page" id="page-2" ref={inputRef2} />
               <label className={`${styles.book__page} ${styles.book__page__2}`}>

                  <div className={styles.book__page_front}>
                     <div className={styles.page__content}>
                        <h1 className={styles.page__content_title}>목차</h1>
                        {data.filter(item => item.year === year && item.index <= 10).map((item, idx) => (
                           <div key={idx} onClick={() => handleClick(item.year, item.index)}>
                              <p className={styles.content_p}>{`${item.index}. ${item.title}`}</p>
                           </div>
                        ))}
                        <div className={styles.page__number} style={{ textAlign: 'right', padding: '0 30px 5px' }}>다음</div>
                     </div>
                  </div>

                  <div className={styles.book__page_back}>
                     <div className={styles.page__content}>
                        <h1 className={styles.page__content_title}>목차</h1>
                        {(() => {
                           const filteredData = data.filter(item => item.year === year && item.index > 10 && item.index <= 20);
                           const itemsToShow = [...filteredData, ...Array(Math.max(0, 10 - filteredData.length)).fill(null)];
                           return itemsToShow.map((item, idx) => (
                              item ? (
                                 <div key={idx} onClick={() => handleClick(item.year, item.index)}>
                                    <p className={styles.content_p}>
                                       {`${item.index}. ${item.title}`}{item.new === true && <span className={styles.is_new_emblem}>최신</span>}
                                    </p>
                                 </div>
                              ) : (
                                 <div key={idx} style={{ width: '100%', height: '47px' }}>
                                    <p className={styles.content_p}>&nbsp;</p>
                                 </div>
                              )
                           ));
                        })()}
                        <div className={styles.page__number} style={{ textAlign: 'left', padding: '0 30px 5px' }}>이전</div>
                     </div>
                  </div>

               </label>
            </div>
         </div>


         <div className={styles.mobile_wrap}>
            <h1 className={styles.mobile_title}>{convertTitle(year)}</h1>
            <p className={styles.view_all} style={{ marginBottom: '10px' }} onClick={() => goViewAll()}>한 번에 읽기</p>
            {data.filter(item => item.year === year).map((item, idx) => (
               <div key={idx} onClick={() => handleClick(item.year, item.index)}>
                  <p className={styles.mobile_content_p}>
                     {`${item.index}. ${item.title}`}{item.new === true && <span className={styles.is_new_emblem}>최신</span>}
                  </p>
               </div>
            ))}
            {year === 'prologue' && (
               <p className={styles.view_all_mobile} onClick={() => navigate('/school3.0/2015')}>2015 읽기</p>
            )}
            {year === '2015' && (
               <p className={styles.view_all_mobile} onClick={() => navigate('/school3.0/prologue')}>서문 읽기</p>
            )}
         </div>







      </div>
   )
}

export default School3_detail