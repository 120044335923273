import React from 'react'
import ss from './MainText.module.css'

const Year2015_24 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>24. 배고파요, 아빠.</h1>
      <p>6월 마지막 주에, 독도 씨는 두 번째 운영위원회가 7월 초에 개최된다는 연락을 받았다.</p>
      <p>학부모운영위원 연수를 다녀온 다음날의 일이었다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 운영위원회 개최 빈도가 낮다고 생각했다.</p>
      <p>한 달에 한 번쯤 열리지 않을까 싶었는데 3개월 만에 다시 열리는 것을 보니, 학교운영위원회가 중요도에 비해서 할 일이 그리 많은 것은 아니구나 싶었다.</p>
      <p>&nbsp;</p>
      <p>그러나 회의 개최 횟수가 적은 이유는 한 번에 많은 안건을 심의하기 때문인 것이지, 심의할 안건이 적어서 그런 것이 아니다.</p>
      <p>나중에서야 독도 씨도 알게 되지만, 운영위원회의 심의 안건은 특별한 일이 발생하지 않는 한 매년 비슷하다.(반복된다.)</p>
      <p>따라서 언제 어떤 심의가 필요하다는 것을 아는 학교장이나 행정실장은, 연간 회의 개최 스케줄을 잡고(분배하고) 각 담당자에게 미리 알려줌으로써 개최 시기에 맞춰 안건을 준비하게 한다.</p>
      <p>미리 계획해서 안건을 준비할 수 있기 때문에 한 번에 여러 안건을 몰아서 제출할 수 있는데, 이렇게 하면 분기에 한 번 꼴로 회의를 개최해도 큰 무리가 없이 모든 안건의 심의가 가능하다.</p>
      <p>&nbsp;</p>
      <p>다만, 상급기관의 지시나 그 밖의 매우 다양한 이유로, 예기치 않게 운영위원회의 심의가 필요한 일이 발생할 경우가 있는데, 어떤 해에는 하나도 없을 수도 있고, 어떤 해에는 매달 발생할 수도 있다.</p>
      <p>이런 예기치 않은 일(비정기적인 안건)이 많이 발생하면, 그 해에 개최되는 회의의 횟수는 많아진다.</p>
      <p>&nbsp;</p>
      <p>2015학년도 1학기의 한비중학교에서는, 예기치 않은 사건이 없어서 단 두 번의 운영위원회만으로 필요한 안건을 모두 심의할 수 있었다.</p>
      <p>&nbsp;</p>
      <p>그런데 이렇게 몰아서 하는 회의에는 치명적인 단점이 있다.</p>
      <p>바로 그날 회의에서 처리해야하는 안건의 수가 많기 때문에, 상당한 시간이 소요된다는 것이다.</p>
      <p>독도 씨의 이야기를 계속 읽다보면 알겠지만, 제대로 토론하면서 심의에 임하면, 안건 하나를 처리하는데도 많은 시간이 필요하기 마련이다.</p>
      <p>어떤 안건은 의견 대립이 심하여 한 시간 이상 토론하는 경우도 있는데, 독도 씨가 운영위원장이었을 때, 단 3개의 안건으로 오후 6시부터 10시까지 회의를 한 적도 있었다.</p>
      <p>가장 시간이 많이 걸렸던 안건은 학교운영위원회 규정 개정안이었는데, 무려 두 시간 반을 토론 했었다.(규정 개정의 경우, 심의해야할 세부 조항이 많으면 오래 걸린다.)</p>
      <p>그리고 예산안과 결산안 같은 안건은, 제대로 꼼꼼하게 심의하면, 하루에 끝나지 않을 수도 있다.(그래서 소위원회를 거치는 것이 필요하다.)</p>
      <p>그러므로 성실히 심의한다는 가정 하에, 한 회의에서 처리해야할 안건이 10개가 넘어간다면, 아무리 매년 하는 심의라서 특별히 토론할 쟁점이 없다고 하여도, 기본적으로 살펴보아야할 내용의 검토만 해도 상당한 시간이 소요되기 때문에, 짧게는 두어 시간에서 길게는 서너 시간이 족히 걸리게 된다.</p>
      <p>이렇게 되면 체력소모가 크고 정신도 많이 지치게 되어, 나중에 처리하는 안건의 경우 심의의 질이 하락할 우려가 있다.</p>
      <p>따라서 많은 안건을 한 번에 몰아서 심의하는 것은, 정상적인 경우라면 권장하기 어려운 일이다.</p>
      <p>&nbsp;</p>
      <p>하지만 실제로는, 거의 대부분의 학교가, 한비중학교처럼 안건을 몰아서 개최한다.</p>
      <p>제아무리 안건이 많아도 장시간 회의하는 일은 발생하지 않기 때문에, 체력이 크게 소모될 일도 없고 정신이 크게 피로할 일도 없다.</p>
      <p>말했다시피 거수기들로 구성된 운영위원회라면, 10개 정도의 안건이야 한 시간 내 처리가 우스울 정도로 쉽다.</p>
      <p>1년의 살림살이가 적혀 있는 15억짜리 예산안이나 결산안도 5분 내 심의를 끝내는 천부적인 재능을 보여주는 자들인데, 말해 무엇 하랴!</p>
      <div className={ss.annotation_wrap}>
        <p>자신의 돈이라면, 15만 원짜리 물건을 사도 아주 꼼꼼하게 살펴볼 사람들이 말이다.</p>
      </div>
      <p>그래서 학교는 아무 부담 없이 안건을 몰아서 회의를 개최한다.</p>
      <p>&nbsp;</p>
      <p>아니, 오히려 몰아서 개최해야 <span className={ss.quo_mini}>‘오래 회의하면 힘들다.’</span>라며 안건을 대충 처리한다.</p>
      <p>안건을 대충 처리할수록 학교장의 얼굴에는 환하게 빛이 난다.</p>
      <p>만일, 회의 후에 회식자리까지 예약해 놓는다면, 이는 금상첨화다.</p>
      <p>위원들은 회식에 늦지 않기 위해 <span className={ss.quo_mini}>‘얼른 합시다!’</span>라며 합심하여 빛의 속도로 안건을 처리한다.</p>
      <p>그래서 안건 몰아서 회의하기는, 학교 입장에서는 매우 큰 장점이 있는 선택이다.</p>
      <p>게다가 회의 개최 횟수를 줄일수록 <span className={ss.quo_mini}>‘위원님! 위원님!’</span>하면서 가면을 쓰고 고개를 수그릴 일이 줄어든다는 장점도 추가된다.</p>
      <p>학교는, 생각보다 훨씬 더 영리하고, 학부모는 생각보다 훨씬 더 호구이다.</p>
      <p>&nbsp;</p>
      <p>어쨌든, 한비중학교는 이번 학기의 두 번째이자 마지막인 운영위원회를 개최한다고 통보하였고, 독도 씨는 곧 심의 안건이 도착하겠거니 생각하고 차분히 기다리기로 했다.</p>
      <p>&nbsp;</p>
      <p>개최 통보를 받은 그날 오후, 신규 개발 프로젝트의 기획서 작업에 한참 몰두하고 있느라 점심을 거른 줄도 모르고 있었던 독도 씨의 귀에 낯익은 목소리가 들려왔다.</p>
      <p className={ss.quo}>“다녀왔습니다.”</p>
      <p>아들 밤톨이의 목소리였다.</p>
      <p>태양은 어느새 오후 4시를 지나가고 있었다.</p>
      <p className={ss.quo_mini}>‘어라? 벌써 시간이 이렇게 되었나?’</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“엄마! 나 배고파. 밥 줘요. 얼른!”</p>
      <p>주방 쪽에 대고 외치는 밤톨이의 목소리가 다급하게 느껴졌다.</p>
      <p className={ss.quo}>“밥 얼른 먹고, 동식이랑 구대랑 같이 농구하러 가야해요. 빨리요.”</p>
      <p>거참, 한창 성장기 때는 돌아서면 금방 배가 고프기 마련이니, 점심 먹었어도 이맘때면 배가 꽤 고플 거야.</p>
      <p>&nbsp;</p>
      <p>곧바로 밤톨이 엄마가 식탁에 밥을 차려주는 소리가 들렸다.</p>
      <p>독도 씨는 모니터에서 잠시 눈을 떼고 일어나, 밤톨이에게 다가가면서 말을 건넸다.</p>
      <p className={ss.quo}>“점심 굶었냐? 학교에서 밥 안줘?”</p>
      <p>그냥 무심코 던진 농담이었다.</p>
      <p>하지만 밤톨이의 대답은 농담기가 하나도 없이 진지했다.</p>
      <p className={ss.quo}>“주긴 주는데 너무 조금 줘요.”</p >
      <p>&nbsp;</p>
      <p>여전히 독도 씨는 대수롭지 않은 듯 대꾸했다.</p>
      <p className={ss.quo}>“흠, 원래 네 나이 때는 워낙 많이 먹으니까, 충분히 줘도 적다고 하지.”</p >
      <p className={ss.quo}>“아니요! 진짜 조금 줘요.나만 그런 게 아니라 친구들도 다 적다고 투덜거려요.”</p >
      <p>밤톨이는 억울하다는 투로 말을 했다.</p>
      <p>맺힌 것이 많은 모양이었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘응? 진짜 적게 주나? 이런 일을 과장할 녀석이 아닌데…….’</p>
      <p>결국 독도 씨의 물음도 진지해졌다.</p>
      <p className={ss.quo}>“진짜야? 밥이 적다고?”</p>
      <p className={ss.quo}>“네. 너무 적어요!”</p>
      <p className={ss.quo}>“왜 그렇게 조금 주지? 혹시 많이 주는데 너만 적다고 그러는 거 아냐?”</p>
      <p className={ss.quo}>“아니라니까요. 제 친구들도 다 적다고 불만이에요.”</p>
      <p className={ss.quo}>“요즘 갑자기 그런 거야?”</p>
      <p className={ss.quo}>“아니요. 처음부터 그랬어요. 학기 초부터. 밥 더 달라고 해도 절대 안 줘요. 정해진 양만큼만 줘야 한 대요.”</p>
      <p className={ss.quo}>“그럴 리가! 처음부터 모든 학생이 밥이 모자랐다면 학부모들 사이에서 벌써 말이 나왔을 텐데…….”</p>
      <p>말을 하다 보니, 독도 씨는 어떤 이야기(소문이나 의견 따위)를 들을 수 있는 방법이 자신에게는 없다는 것을, 문득 깨달았다.</p>
      <p>&nbsp;</p>
      <p>급식을 포함해서 학교 행정이나 교육과정 등 학교 운영상에 어떤 불만이 있을 경우, 혹은 크고 작은 문제가 발생할 경우, 이것을 인지한 학부모가 이를 신고하거나 공론화할 방법이 한비중학교에 있었던가?</p>
      <p>그리고 그런 방법이 있다면, 그런 신고나 의견을 학교운영위원이 수렴할 수 있었던가?</p>
      <p>비록 운영위원이 된지 4개월이 채 되지 않아서 아직 모르는 것일 수도 있지만, 현재로서는 그런 방법이 전혀 보이지 않았다.</p>
      <p className={ss.quo_mini}>‘없어도 문제, 있는데 모르고 있어도 문제네.’</p>
      <p>&nbsp;</p>
      <p>독도 씨는 학교홈페이지를 떠올렸다.</p>
      <p className={ss.quo_mini}>‘학교 운영과 관련하여 개선을 요하는 일이 있을 때, 누구나 직접 제한 없이 이를 건의·제안·요청·요구할 수 있고 공론화 할 수 있는 창구가 있어야 하는데, 지금 눈에 보이는 것은 아무것도 없다. 학교홈페이지에 건의게시판이라도 만들어 놓으면 좋으련만. 하긴 학교홈페이지는 사실상 아무도 이용하지 않더라. 만들어 놓아도 아무도 이용하지 않으면 소용이 없겠지. 학교홈페이지는……, 확실히 큰 문제야.’</p>
      <p>학교홈페이지 관련한 문제는 독도 씨가 진작 인지한 중요한 문제인데, 지면상 나중에 설명하기로 했었다.</p>
      <p>그러므로 뒤에서 설명할 텐데, 일단 학교홈페이지는 제대로 운영되지 않고 있었다.</p>
      <p>그리고 한비중학교 홈페이지에는 건의게시판 같은 창구도 없었다.</p>
      <p>그렇다면 그 외에는 다른 어떤 방법이 있을까?</p>
      <p>&nbsp;</p>
      <p>말 나온 김에, 학부모가 학교에 어떤 의견을 전달하고 싶을 때, 어떤 방법으로 전달할 수 있는지에 대해 살펴보도록 하자.</p>
      <p>&nbsp;</p>
      <p>학교 운영과 관련하여 학부모가 의견을 전달할 일은 생각보다 많다.</p>
      <p>의견의 종류는 다양하다.</p>
      <p>개선을 요하는 사항의 건의나 제안은 말할 것도 없고, 크고 작은 불만이나 해결이 필요한 사건의 신고, 각종 요구사항 등 학교에 전달해야 할 다양한 의견들은 끊임없이 발생하고 늘 존재한다.</p>
      <div className={ss.annotation_wrap}>
        <p>1. 학교가 소비자(학생, 학부모)의 의견을 적극 반영해야 하는 이유.</p>
        <p>그동안 계속 학교민주주의에 초점을 맞춰서 이야기했는데, 좀 더 포괄적으로 학교의 문제를 지적하자면, 학교가 가진 근본적인 문제 중의 하나이자 가장 심각한 문제는 변화가 매우 느리다는 것이다.</p>
        <p>&nbsp;</p>
        <p>단순히 느린 정도가 아니라, 변화를 거부하는 수준으로 느리다.</p>
        <p>학교는 외부의 힘(압력)이 잘 미치지 않는 극단적으로 폐쇄적인 사회이다.</p>
        <p>눈에는 보이지 않지만 내·외부를 구분 짓는 단단한 장벽을 두르고 있는데, 외부에서 안을 들여다보기조차 어려울 정도이다.</p>
        <p>극단적으로 폐쇄적인 사회이다 보니, 외부 사회의 시계와 다른 속도로 움직이고 있어도 스스로는 불편함을 모를뿐더러 그러한 사실을 인지하지도 못한다.</p>
        <p>학교민주주의의 수준이 낮은 것도 변화를 받아들이지 못하는 것에서 파생된 문제의 하나이다.</p>
        <p>&nbsp;</p>
        <p>변화가 느려서, 뒤처져 있는 정도가 심하니까, 사회의 변화를 빨리 따라잡기 위해서 교육청은 학교의 혁신을 부르짖는다.</p>
        <p>오죽하면 혁신을 부르짖을까.</p>
        <p>하지만 사람들이 한 걸음 갈 때 학교는 두 걸음을 간다고 해도, 한참을 쫒아가야 간신히 따라잡을 수 있을 텐데, 여전히 한 걸음도 제대로 따라가지 못하고 있으니 혁신은 실패하고 공교육은 점점 더 뒤처지고 있다.</p>
        <p>&nbsp;</p>
        <p>그렇다면 어떻게 해야 변화를 촉진할 수 있을까?</p>
        <p>어떻게 해야 사회의 변화를 따라잡고 뒤처지지 않을 수 있을까?</p>
        <p>효과적인 방법은 분명히 있다.</p>
        <p>외부 환경에서 생활하고 있는 학생과 학부모가 끊임없이 학교 내부의 변화를 이야기함으로써 변화를 이끌어 내는 것이 그 방법이다.</p>
        <p>크고 작은 <span className={ss.bold}>의견을 끊임없이 제시하고 이를 적용하려 노력</span>할 때 혁신은 이루어진다.</p>
        <p>&nbsp;</p>
        <p>하지만 변화가 정체된 학교는, 구성원의 의견을 잘 받아들이지 않는다.</p>
        <p>의견을 받아들이지 않으면 의견을 내려는 노력도 쉽게 사라진다.</p>
        <p>그렇게 되면 변화가 더욱 힘들어지는 악순환에 빠진다.</p>
        <p>학교의 변화를 이끌어내는 힘은 구성원의 적극적인 의견 개진에서 나오는 것이므로, 어떻게든 한 번은 악순환을 깨야한다.</p>
        <p>껍질을 깨야, 비로소 새가 날개 짓을 할 수 있는 것처럼 말이다.</p>
        <p>&nbsp;</p>
        <p>2. 학교가 소비자(학생, 학부모)의 의견을 반영하는 효과적인 방법.</p>
        <p>오늘날, 소비자의 눈높이와 학교가 제공하고 있는 서비스의 수준 차이는 외부에서 상상하는 것보다 훨씬 크다.</p>
        <p>이러한 차이를 메워 나가려면, 적극적으로 소비자의 의견(요구)을 반영해야 한다.</p>
        <p>소비자의 의견을 바탕으로 공급자가 할 수 있는 일을 서로 합의하여 찾아나가야 한다.</p>
        <p>그런데 학교 구성원의 요구는 구성원의 수만큼이나 다양하다.</p>
        <p>다양한 요구를 하나의 정책(교육부, 교육청 등의 정책)으로 묶어서 해결하는 것은 불가능에 가깝다.</p>
        <p>따라서 단위 학교에서 사안별로 합의하여 방법을 찾는 것이 좀 더 유연하고 효과적이다.</p>
        <p>&nbsp;</p>
        <p>문제가 발생할 때마다 하나의 방향을 설정하여 정책을 만들고 들이미는 것은, 다양한 요구로 얽혀있어서 학교 마다 다르게 발현되는 문제의 양상을, 더 복잡하게 만들 수 있다.</p>
        <p>따라서 그러한 정책은 가급적 지양하고, 오히려 단위 학교에서 <span className={ss.bold}>학교자치를 통해 합의</span>하여 해결할 수 있도록, 자치 지원을 더욱 강화하는 정책이 필요하다.(시스템 정비, 실무 연수 강화, 관련 예산 지원 등)</p>
        <p>단위 학교 차원에서의 해결책들이 효과가 있을 때, 그것이 보편적인 해결책으로 적용 가능하다고 검증되면, 그때 정책으로의 도입을 검토하는 것이 지향해야할 방식이다.</p>
        <p>&nbsp;</p>
        <p>한편, 학교자치를 방해하고 잘 만들어진 시스템을 사문화시키는, 교육계 내부의 적폐 기득권 세력을 견제하고 감시하는 일도 소홀히 해서는 안 된다.</p>
        <p>소비자의 의견을 은폐하고, 왜곡하며, 혁신을 거부하는 전형적인 독재 정치 때문에 학교는 더욱 변화하지 않는다.</p>
      </div>
      <p>&nbsp;</p>
      <p>또한 무엇인가를 결정하기 위해서 의견을 수렴하기도 한다.</p>
      <p>교복, 급식, 체험 학습, 건강 검진, 학사 일정, 방과 후 학교, 교과서, 각종 규정 등 학교 운영과 관련한 다양한 사항의 결정에 있어서 학부모의 의견은 반드시 필요하다.</p>
      <div className={ss.annotation_wrap}>
        <p>당사자인 학생의 의견이 필요한 것은 말할 것도 없다.</p>
        <p>다만, 여기서는 일단 학부모 의견 수렴에 대해서만 다룰 것이고, 학생 의견 수렴에 대해서는 다른 부분에서 다룰 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>대개 이러한 의견 수렴은 가정통신문을 등을 통해서 이루어진다.</p>
      <p>하지만 특정 기간의 의견 수렴이 아닐 경우, 상시(수시로) 의견이 발생할 경우에는 어떻게 의견을 제시할 수 있을까?</p>
      <p>&nbsp;</p>
      <p>건의, 제안, 불만, 신고, 의견 수렴 등 학교 운영과 관련하여 학부모가 자신의 생각(의견)을 제시하고 공론화 시킬 수 있는 방법에는 어떤 것이 있을까?</p>
      <p>상식선에서 생각해보면 쉽게 알 수 있다.</p>
      <p>&nbsp;</p>
      <p>먼저, 의견을 접수할 수 있는 창구를 생각해보자.</p>
      <p>&nbsp;</p>
      <p>1. 가장 기본적인 것은 직접 말하는 것이다.</p>
      <p>교장실(학교장), 행정실(직원), 교무실(담임교사, 교과담당교사) 등에 직접 방문하거나 전화를 하여 의견을 전하는 방법이 그것이다.</p>
      <p>물리적 공간이 존재하므로 방문이 가능하고, 공개된 전화번호가 존재하므로 통화가 가능하기 때문에 확실하게 보장된 방법이다.</p>
      <p>다만, 기업의 고객센터처럼 민원을 전담으로 하는 창구가 아니므로, 교직원의 업무에 크고 작은 지장을 줄 가능성이 있다는 단점이 있다.</p>
      <p>&nbsp;</p>
      <p>2. 다음으로는 학부모회를 이용하는 방법이 있다.</p>
      <p>학부모회로 의견이 모이는 가장 흔한 방식은, 먼저 개별 학부모끼리 의견을 주고받으면서 형성된 소규모 여론이 학급 학부모회 임원(반대표)에게 전달되고, 여러 학급의 반대표 사이에서 좀 더 큰 규모의 여론이 형성되어 전체 학부모회 임원(학부모회장)에게 전달되는 방식이다.</p>
      <p>간혹 중간 과정을 건너뛰고, 개별 학부모에게서 바로 학부모회장에게 의견이 전달되기도 한다.</p>
      <p>이렇게 전달된 의견은 학부모회가 학부모의 대표로서 학교장 등에게 전달한다.</p>
      <p>이는 여론의 형식이므로, 앞서 개별 학부모가 직접 말하는 방법에 비해 보다 강한 힘(압력)을 발휘할 수 있다.</p>
      <p>&nbsp;</p>
      <p>그런데 이와 같은 과정에는 일정 조건이 필요하다.</p>
      <p>첫째, 당연하게도 학부모회가 구성이 되어야 한다.</p>
      <p>단순히 조직이 구성되는 것 뿐 아니라, 학부모회 임원이 활동을 하고 있어야 한다.</p>
      <p>간혹 학부모회 조직은 형식적으로 구성되지만, 실제로는 거의 활동을 하지 않는 경우가 있는데, 이런 학교에서는 몇 몇 학부모 사이에서 소규모 여론이 형성되어도 그 여론을 받아줄 학부모회 조직이 활동하고 있지 않으므로 공론화되기 어렵다.</p>
      <p>안타깝지만, 학부모회가 있으나마나한 학교가 꽤 존재한다.</p>
      <p>둘째, 학부모회 임원이 열심히 활동을 하고 있더라도, 물리적인 여론 수렴 창구가 있어야 한다.</p>
      <p>직접 의견을 전달하기 위해 방문할 수 있는 공간(학부모회실 또는 학부모상주실 등)이 있거나, 학부모회 임원의 전화번호 등이 공개되어 있어야 한다.</p>
      <p>이러한 창구가 없으면 의견을 전달하기 어려울 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>개인정보보호 등의 이유로 학부모회 임원의 개인 전화번호를 공개하지 않는 경우가 많다.</p>
        <p>업무용이 아닌 개인 전화번호이기 때문에 공개를 강요하기는 어렵다.</p>
        <p>그렇다고 학교로부터 업무용 전화번호를 따로 할당받는 것도 쉬운 일은 아니다.</p>
        <p>이 문제를 해결할 수 있는 방법은 의외로 간단한데, 이는 학교홈페이지 운영에 관한 글에서 다룰 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>한편, 스마트폰이 대중화 되면서 휴대폰 앱을 통한 학부모 상호간의 커뮤니케이션이 쉬워졌는데, 학급의 학부모들이 모여서 이야기를 나눌 수 있는 단체 채팅방을 운영하거나, 학교의 전체 학부모가 가입하여 의견을 나눌 수 있는 모임 앱을 사용하는 학부모회가 늘고 있다.</p>
      <p>이러한 소통 도구는 기존의 물리적인 창구(공간)보다 더 편리하게 이용할 수 있고, 그 생성과 가입이 쉽기 때문에, 학부모회를 이용한 의견 수렴 방식에 더 없이 안성맞춤인 도구이다.</p>
      <p>다만, 공식적인 도구가 아니라서 관리에 어려움이 있을 수 있다.</p>
      <p>사적으로 생성하고 관리하기 때문에 지속성과 정통성을 확보하기 어렵다.</p>
      <p>따라서 이러한 도구가 교육청이나 학교를 통해 공식적으로 제공되어야할 필요가 있다고 하겠다.</p>
      <div className={ss.annotation_wrap}>
        <p>이와 관련하여 독도 씨와 부종교육포럼은 수년간 진부도교육청에 학부모 소통 앱 제작을 반복적으로 제안한 바 있었다.</p>
        <p>물론 진부도교육청은 줄기차게 관심을 주지 않았다.</p>
        <p>생각해보면, 그들(기득권) 입장에서는 학교민주주의에 도움이 되는 도구를 적극적으로 도입할 이유가 없다!</p>
        <p>역시나, 아주 웃기는 일이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>정리하자면, 물리적인 공간이 확보되거나, 학부모회 임원의 전화번호가 공개되거나, 학부모 전체가 가입된 모임 앱을 사용하거나, 학급별 단체 채팅방 등이 활성화된 학교라면, 학부모회를 통한 의견 수렴이 충분히 가능한 학교라고 할 수 있겠다.</p>
      <p>&nbsp;</p>
      <p>3. 의견을 접수할 수 있는 세 번째 창구는, 학교운영위원회를 이용하는 것이다.</p>
      <p>학교운영위원회실에 의견을 접수할 수 있는 상설 게시판을 설치하거나 혹은 의견 접수함 같은 것을 비치하거나, 학교운영위원의 전화번호나 이메일 등을 공개하여 의견을 받는 방법이 있다.</p>
      <p>또한 학교홈페이지에, 학교 혹은 학교운영위원회에 건의·제안할 수 있는 건의게시판을 운영하는 방법도 있다.</p>
      <p>무엇이 되었든, 다양한 방식으로 학교 구성원의 의견을 듣는 것은 학교운영위원의 주요 업무이자 바람직한 자세라고 할 수 있다.</p>
      <p>&nbsp;</p>
      <p>그런데 아쉽게도, 현실에서는 이런 방법들을 전혀 사용하지 않는다.</p>
      <p>상설 게시판도 의견 접수함도 없고, 건의게시판을 운영하지도 않는다.</p>
      <p>학교운영위원회가 은폐되고 폐쇄적으로 운영되고 있기 때문에, 학교 구성원들은 학교운영위원회에 의견을 전달할 수 있다는 생각 자체를 하지 못하며, 학교는 이런 상황을 유지시키기 위해 학교운영위원회와 관련한 의견 접수창구를 운영하지 않는다.</p>
      <div className={ss.annotation_wrap}>
        <p>학교홈페이지에 건의게시판을 운영하는 학교는 매우 드물다.</p>
        <p>부종시의 경우, 2015년에 독도 씨가 조사했을 당시, 조사 대상 50개 학교 중 고작 5개 학교만 건의게시판을 운영하고 있었는데, 그마저도 단 한 개의 학교에만 단 한 개의 건의 글이 있었고 나머지 학교의 건의게시판은 수년 간 개점휴업 상태였다.</p>
        <p>물론 건의 따위는 전혀 필요 없는 이상적인 학교이기 때문에, 게시판을 운영하지도 않고 운영하더라도 게시물이 없는 것이라고 믿고 싶다.</p>
        <p>다만 최근에는, 진부도교육청에서 시행한 학교홈페이지 개선 사업(모바일 반응형 홈페이지로 업그레이드)의 결과로, 통일된 템플릿이 도입되면서 건의게시판이 만들어진 학교가 많아졌다.</p>
        <p>여전히 건의하는 내용은 없지만, 그래도 한 걸음은 전진한 것이라 평가하고 싶다.</p>
        <p>열 걸음은 더 가야하는데 말이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>4. 학부모가 학교에 어떤 의견을 전달하는 네 번째 방법으로, 국민신문고 등의 민원 창구를 이용하는 방법이 있다.</p>
      <p>주로 신고나 불만을 접수할 때 사용하는데, 수신자를 학교로 하여 직접 민원을 제기할 수도 있고, 상급 기관인 교육청을 수신자로 하여 민원을 제기할 수도 있다.</p>
      <p>이러한 민원은, 반드시 해야 하는 경우도 있지만, 바람직하지 않은 경우도 많다.</p>
      <p>두 경우에 대해서는 바로 밑에서 이야기할 ‘의견이 처리되는 방식’에서 설명하겠다.</p>
      <p>&nbsp;</p>
      <p>이처럼 학부모가 의견을 접수할 수 있는 창구는 네 가지 정도 있다.</p>
      <p>① 교장실, 행정실, 교무실 등에 직접 말하는 방법.</p>
      <p>② 학부모회를 이용하는 방법.</p>
      <p>③ 학교운영위원회에 의견을 제시하는 방법.</p>
      <p>④ 국민신문고 등에 민원을 접수하는 방법.</p>
      <p>&nbsp;</p>
      <p>그렇다면 이제 접수된 의견이 처리되는 방식(절차)에 대해 알아보자.</p>
      <p>&nbsp;</p>
      <p>의견이 처리되는 가장 보편적인 방식은, 의견 접수 후 처리 당사자에 의해 직접 처리가 이루어지는 것이다.</p>
      <p>요즘 급식에 나오는 반찬이 너무 맵다는 아이들이 많다고 영양교사에게 의견을 전달하면, 영양교사가 이를 확인하고 <span className={ss.quo}>“이번에 바뀐 거래처에서 들어온 고춧가루가 지난번보다 많이 맵다는 것을 확인했습니다. 우선은 조리 시 고춧가루의 투입량을 줄이고, 다음부터는 매운 정도를 꼼꼼히 확인하여 지난번과 같은 수준의 고춧가루를 구매하도록 하겠습니다.”</span>라고 조치하는 식이다.</p>
      <p>&nbsp;</p>
      <p>물론 모든 의견이 다 받아들여지는 것은 아니다.</p>
      <p>다수의 반대 의견이 있거나, 담당자의 권한을 넘어서는 무리한 요청이거나, 규정에 부합하지 않는 의견 등은 보류되거나 거절당할 수 있다.</p>
      <p>학부모는, 납득할만한 사유가 있다면 의견의 거절도 받아들일 수 있어야 한다.</p>
      <p>&nbsp;</p>
      <p>반면에 합당한 의견임에도 불구하고 부당하게 거절당하는 경우가 있을 수 있다.</p>
      <p>대개 의견이 처리되는 과정에서 문제가 발생하는 경우는, 의견이 거절당할 때 그 사유가 불분명하거나 부당(불공정, 불합리, 규정 위반 등)할 경우에 주로 발생하며, 간혹 의견이 전달되는 경로에 문제가 있을 때에도 발생한다.</p>
      <p>&nbsp;</p>
      <p>예를 들어보자.</p>
      <p>급식 만족도가 예년에 비해 폭락하고, 인근 학교 평균보다 지나치게 낮아서 이를 개선 해달라는 요구를 전달했음에도 불구하고, 학교장이 <span className={ss.quo}>“급식은 영양교사의 권한이라서 내가 이래라 저래라 할 수 없다.”</span>며 아무런 조치를 취하지 않는 경우나, 수업시간에 욕설과 부적절한 발언을 자주하여 학생들에게 모욕·불쾌감을 주는 교사에게 주의를 주고 수업의 질을 개선 해달라는 요구를 전달했지만, 학교장이 <span className={ss.quo}>“수업 시간에 일어난 일에 대해 개입하는 것은 교사의 권한을 침해할 수 있는 일이어서 곤란하다.”</span>며 소극적으로 대처하는 경우 등은 학교장이 자신의 임무임에도 불구하고 납득하기 어려운 핑계를 대면서 의견을 처리하지 않는 부당한 경우라고 할 수 있다.</p>
      <p>한편, 방과 후 학교의 개설 과목을 학생·학부모가 선호하는 과목으로 바꾸어 달라는 학부모 다수의 요구가 있었으나 이를 학교에 전달하지 않고 <span className={ss.quo}>“학교가 알아서 할 일이지 학부모가 나설 일이 아니다.”</span>라며 임의로 묻어버린 학부모회장의 경우와, 체육관 사용 허가를 받고 운동하던 동아리 학생들을 일방적으로 쫒아내고 자신이 지도하는 체육부 학생들을 운동시킨 체육교사의 행위가 부당하니 사과와 재발방지 약속을 받아달라는 어떤 학부모의 요구를, 귀찮다는 이유로 학부모회에 바로 전달하지 않고 곧 잊어버린 반대표의 경우 등은 의견 전달 통로가 임의로 단절되어 문제가 발생한 경우라 할 수 있다.</p>
      <p>&nbsp;</p>
      <p>정당한 의견이 부당하게 처리되는 경우, 학교 내에서 이를 바로잡을 수 있는 기회가 최소한 한 번 이상 존재한다.</p>
      <p>교직원이 부당한 일처리를 할 경우, 관리감독자인 학교장을 통해 시정을 기대해볼 수 있다.</p>
      <p>만일 학교장마저 부당하게 일을 처리할 경우, 학교장을 감시·견제하는 위치에 있는 기구인 학교운영위원회가 작동하여 시정을 요구할 수 있다.</p>
      <p>학교운영위원회는 예컨대 ‘합당한 처리를 위한 시행(안)’ 등을 발의하고 상정하여 이를 가결(심의 통과)함으로써 학교장에게 시정 압력을 가할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>2016년, 한비중학교에서 실제 이러한 일이 일어났었다.</p>
        <p>여러모로 매우 중요한 사건이었는데, 2016년 편에서 자세하게 설명할 예정이다.</p>
      </div>
      <p>학교장은 이를 거부할 수 있으나, ‘불가능하거나 불법적인 결정 혹은 교육적이지 못한 결정이거나 학교 규정을 벗어난 과도한 요구 등’이 아닌 이상, 임의로(타당한 이유 없이) 거부할 경우 교육청(교육감)의 시정 명령을 받게 된다.</p>
      <p>상식적이고 합리적인 수준의 시정 요구라면, 교육청도 운영위원회의 판단을 존중해줄 것이다.</p>
      <p>그러므로 학교장의 전횡이나 배임 등은, 학교민주주의가 올바르게 작동하고, 교육청이 민주적이라면, 충분히 감시할 수 있고 견제할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>학교장이나 교직원이 불법이거나 규정을 위반한 부당 행위를 했다면, 학교운영위원회를 통할 필요 없이 그냥 국민신문고를 통해 교육청에 신고를 하면 된다.</p>
      </div>
      <p>&nbsp;</p>
      <p>따라서 합리적이고 다수가 공감할 수 있는 학부모의 의견(건의, 제안, 요청, 요구, 의견 수렴 등)이라면 절차에 따라 무난하게 처리될 수 있다.</p>
      <p>&nbsp;</p>
      <p>그런데 만일, 학교운영위원회가 제대로 작동되지 않는다면 어떻게 처리될까?</p>
      <p>부당한 일이 발생해도 학교운영위원회는 시정을 요구하지 않을 것이므로, 학부모의 의견은 제대로 처리되지 않을 것이다.</p>
      <p>또한 학교장의 일방적이고 독단적인 결정을 학교운영위원회가 견제하기는커녕 형식적인 심의로 통과시켜 주었다면, 그 절차에 불법적인 요소가 없는 이상 이는 누구도 되돌릴 수 없는 결정이 되므로, 학교장이나 학교운영위원회에서 스스로 재심의를 요청하지 않는 이상, 더 이상 이를 시정할 방법은 없다.</p>
      <p>&nbsp;</p>
      <p>이처럼 학교운영위원회가 제대로 작동되지 않아서, 부당한 일처리를 학교 내에서 시정할 수 없다면, 어떤 방법으로 학부모의 의견을 처리할 수 있을까?</p>
      <p>학부모의 의견을 접수할 수 있는 창구 중에 국민신문고 등에 민원을 접수하는 것이 있다고 했는데, 학부모의 의견이 부당한 이유로 처리되지 않을 때 대부분의 사람들이 마지막으로 기대하는, 교육청에 민원을 제기하여 처리하는 방식(절차)이 있다.</p>
      <p>&nbsp;</p>
      <p>그러면 이제, 교육청에 제기되는 민원의 유형에 따라, 그 처리되는 절차를 살펴보자.</p>
      <p>위에서 국민신문고 등을 통해 교육청에 민원을 접수하는 것이, 반드시 해야 하는 경우도 있겠지만, 바람직하지 않은 경우도 많다고 했는데, 왜 그런 것인지 알 수 있을 것이다.</p>
      <p>&nbsp;</p>
      <p>일단 우리는 소위 학부모 갑질이라고 불리는 부적절한 민원은 제하고 알아보자.</p>
      <div className={ss.annotation_wrap}>
        <p>말로 다하기 힘든, 수준 이하의 억지 민원이 많다.</p>
        <p>각종 갑질 민원의 사례를 들어보면, 부끄러운 줄 모르는 비뚤어진 이기심을 가졌거나 상식이 부족한 사람이 생각보다 많은 듯하다.</p>
        <p>독도 씨는 이 역시 학교민주주의가 성숙해져야 해결될 수 있는 일이라고 믿는다.</p>
        <p>사회의(학교의) 문화 수준이 성장하지 않고서는, 특정 정책만으로는 해결되기 어려운 일이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>우선 설명이 가장 간단한 유형은, 불법을 저지르거나 규정을 위반한 학교장 또는 교직원에 대해 민원을 제기하는 경우다.</p>
      <p>교육청은 민원을 통해 상황을 인지하면, 조사에 착수한다.</p>
      <p>조사 결과에 따라 조치를 취하는데, 불법이나 규정 위반이 확인되면, 대개 해당 사건은 법령이나 규정에 맞게 시정된다.</p>
      <div className={ss.annotation_wrap}>
        <p>하지만 간혹 문제를 덮는 경우도 있다.</p>
        <p>앞서 이야기한 ‘지역위원 선출 시 법령 위반’ 같은 경우가 그러한 사례이다.</p>
        <p>회유하고 종용하여 민원 취하를 유도하는 일은 꽤 흔하다.</p>
        <p>만일 당신이 이러한 종류의 민원을 제기하려 한다면, 팔은 안으로 굽는다는 사실을 반드시 염두에 두고 전략적으로 대응해야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>다음으로, 불합리하지만 불법이나 규정 위반이 아닌 부당한 일처리에 대한 민원이 있다.</p>
      <p>학교 구성원 다수가 원하는 일이기 때문에 응당 해줄 것으로 기대했으나, 소극적으로 대처하거나 거절하거나 혹은 그 반대로 행하는 경우 등이 이에 해당된다.</p>
      <p>이 경우는 학교장 또는 교직원의 행위가 불법이 아니므로 교육청이 제제를 가할 수 없다.</p>
      <p>권고 정도를 할 수는 있겠지만 강제력은 없다.</p>
      <p>이런 일은 위에서 말했듯이 학교운영위원회가 나서야 하는 일이다.</p>
      <p>그러나 이미 학교운영위원회가 정상적으로 작동되지 않아서 교육청에 민원을 제기한 상황이라면, 더 이상의 해결책은 없다.</p>
      <p>&nbsp;</p>
      <p>마지막으로, 학교운영위원회의 심의 결과와 관련된 불만 민원이 있다.</p>
      <p>불합리하지만 불법이나 규정 위반이 아닌 부당한 일처리이면서, 동시에 학교운영위원회가 심의 통과(가결)시킨 일에 대한 민원이다.</p>
      <p>교복, 급식, 체험 학습, 건강 검진, 학사 일정, 방과 후 학교, 교과서, 각종 규정 등 학교 운영과 관련한 다양한 사항의 결정이 학교운영위원회의 심의를 거쳐 확정된다.</p>
      <p>이렇게 학교운영위원회의 심의를 거쳐 결정된 사항은, 이를 민원으로 제기해도 교육청에서 처리해줄 수 없다.</p>
      <p>학교운영위원회의 심의를 거친다 함은, 공식적으로 학교 구성원의 뜻을 충실히 반영하여 결정된 것임을 의미한다.</p>
      <p>비록 진실과 다르더라도, 규정된 절차에 의해 학교 구성원의 뜻에 따라 결정된 사안을 민원 때문에 되돌릴 수 있겠는가?</p>
      <div className={ss.annotation_wrap}>
        <p>간혹 학교운영위원회가 심의 과정에서 규정을 위반한 경우가 있다.</p>
        <p>반드시 의견 수렴을 거치라고 했는데 안 한 경우 등이다.</p>
        <p>이런 심의 결과라면 무효화 할 수 있다.</p>
        <p>그러나 그래봤자 재심의에서 원래대로 처리하면 그만이다.</p>
        <p>학부모 대다수의 의견이 A라고 해도, 운영위원들이 검토한 결과 B로 하는 것이 학교를 위한 결정이라고 하면, 비판은 할 수 있을지언정 결과를 되돌릴 수는 없다.</p>
        <p>지역이나 사회 전반의 여론이 크게 형성되고 각계의 압박이 더해져서 학교장이나 운영위원들이 스스로 백기를 들지 않는 이상 그들의 결정을 강제할 수는 없다.</p>
        <p>참고로, 모든 학부모들이 들고 일어나 탄핵을 주장해도, 관련 규정이 없기 때문에 운영위원의 탄핵은 불가능하다.</p>
        <p>한 번 선출된 운영위원은, 규정만 준수하면 임기가 끝날 때까지 무적이다.</p>
      </div>
      <p>교육청은, 학교자치에 따라 학교운영위원회의 심의를 거쳐 단위 학교에서 결정한 사항을, 바꿀 수 있는 명분도 권한도 없다.</p>
      <p>그래서 결국 민원의 답변은 <span className={ss.quo_mini}>‘해당 사안은 귀 학교의 학교운영위원회의 심의를 거쳐 결정된 사안으로, 학교운영위원회로 문의하시기 바랍니다.’</span>로 정리되기 마련이다.</p>
      <p>&nbsp;</p>
      <p>정리하자면, 학교에서 발생한 일이 부당하다며 교육청에 제기한 민원은, 불법이나 규정을 위반한 일이 아닌 이상 교육청이 해결해 줄 수 없다.</p>
      <p>대개 불법이 아닌 부당한 일은 학교운영위원회가 해결해야할 일인데, 학교운영위원회가 제대로 작동하지 않아서 해결하지 못한 일이거나, 아예 학교운영위원회가 문제의 원인(심의한 일)이라면 해결은 불가능하다.</p>
      <p>해결이 불가능한 민원이기에, 이런 종류의 민원은 교육청에 접수하는 것이 바람직하지 않다.</p>
      <p>특히 학교운영위원회가 심의한 일에 대한 민원은, ‘단위 학교에서 결정해야하는 일’에 대해서 교육청더러 간섭 해달라는, 교육청의 권한을 넘어서 조치 해달라는 잘못된 민원이다.</p>
      <p>그러므로 교육청에 민원을 접수할 것이 아니라, 단위 학교에서 학교운영위원회가 제대로 작동될 수 있도록 학교운영위원을 대상으로 심의 정상화를 촉구하거나, 애초에 학교운영위원회가 올바르게 구성될 수 있도록(거수기가 되지 않도록) 심혈을 기울이는 것이 훨씬 바람직한 일이다.</p>
      <div className={ss.annotation_wrap}>
        <p>한때 A출판사의 고등학교 역사교과서 문제로 사회가 시끄러웠던 적이 있었다.</p>
        <p>당시 A교과서가 역사를 왜곡하고 독재를 미화했다며, 채택을 반대한다는 사회 전반의 목소리가 높았다.</p>
        <p>그때의 상황을 조금 각색하여 재구성 해보면 위의 내용들이 보다 잘 이해될 것이다.</p>
        <p>&nbsp;</p>
        <p>교과서의 선정은 학교운영위원회 주요 심의 안건 중 하나이다.</p>
        <p>먼저 각 교과의 담당 교사들이 과목당 2종 이상의 교과서를 후보로 올린다.</p>
        <p>그리고 후보 교과서를 평가하고 그 점수를 집계하여, 과목당 1종의 교과서를 채택한다.(드물지만 여러 종을 채택할 수도 있다. 이 경우 어떤 교과서를 구매할 것인지는 학생 개인이 자율적으로 결정한다.)</p>
        <p>교과협의회에서는, 후보 교과서와 채택된 교과서, 그리고 평가한 내역 등을 모두 안건에 포함(기록)하여 학교운영위원회에 제출한다.</p>
        <p>학교운영위원회는 채택이 타당한지 심의하여, 타당하다면 가결하고 문제가 있다면 보완을 요구하거나 채택을 거부하는 등 수정 가결, 심의 보류, 부결 등의 결정을 내릴 수 있다.</p>
        <p>&nbsp;</p>
        <p>B학교는 교과협의회에서 A교과서를 채택했다.</p>
        <p>그러나 학교운영위원회는, 학생과 학부모의 압도적인 반대도 있고, 사회 전반의 지적(역사왜곡 및 독재미화)이 타당하다고 판단하였기 때문에, A교과서 채택을 거부(부결)했다.</p>
        <p>B학교의 학교장 및 교과협의회는 학교운영위원회의 결정을 존중하여, 다른 교과서를 채택하고 수정된 안건을 제출했다.</p>
        <p>학교운영위원회가 학부모의 의견을 수렴한 사례라 하겠다.</p>
        <p>&nbsp;</p>
        <p>C학교는 교과협의회에서 A교과서를 채택했다.</p>
        <p>학생과 학부모의 압도적인 반대가 있었으나, 학교운영위원들의 생각은 달랐다.</p>
        <p>그들은 치열한 토론 끝에 A교과서 채택을 인정(가결)했다.</p>
        <p>C학교의 학생과 학부모는 분노했고, 교육청에 민원을 제기했다.</p>
        <p>학생과 학부모 대다수가 반대하므로, 심의가 무효라는 취지의 민원이었다.</p>
        <p>그러나 학교운영위원회의 결정 절차에 불법이 없었기 때문에, 교육청은 어찌할 수 없었다.</p>
        <p>심의 결과는 뒤집혀지지 않았다.</p>
        <p>아무리 분노가 거세도, 법을 무력화할 수는 없는 법이다.</p>
        <p>그러나 수주가 지나도록 반발은 끊이지 않았고 사회 여론도 점점 거세지자, 견디지 못한 학교운영위원회는 재심의를 통해 다른 교과서를 채택하였다.</p>
        <p>이는 학교운영위원회의 결정을 상급기관이 개입하여 바꿀 수는 없지만, 학교운영위원회 스스로는 바꿀 수 있다는 것을 보여준 사례라 하겠다.</p>
        <p>&nbsp;</p>
        <p>D학교는 교과협의회에서 A교과서를 채택했다.</p>
        <p>그런데 학교운영위원회는 흔한 거수기였고, 당연하게도 심의 결과 무조건 가결되었다.</p>
        <p>이에 학생과 학부모의 격렬한 반대가 발생했다.</p>
        <p>그렇지만 학교운영위원들은 침묵했다.</p>
        <p>비판 여론은 모두 학교장에게 쏠려 있었고, 거수기 운영위원들은 학교장의 결정만 기다렸다.</p>
        <p>학생, 학부모, 운영위원, 학교장, 교직원 등 모든 사람들은, 결정은 학교장이 하는 것이라 생각했다.</p>
        <p>결국 수일이 지나, 학교장이 채택을 철회하겠다고 선언하고, 재심의를 위한 학교운영위원회를 개최하였다.</p>
        <p>다른 교과서를 채택하겠다는 안건이 제출되었고, 거수기 운영위원들은 늘 하던 대로 가결시켰다.</p>
        <p>그리하여 A교과서 채택은 철회되었다.</p>
        <p>&nbsp;</p>
        <p>E학교는 교과협의회에서 A교과서를 채택했다.</p>
        <p>그런데 학교운영위원회는 흔한 거수기였고, 심의 결과 가결되었다.</p>
        <p>이에 학생과 학부모의 격렬한 반대가 발생했다.</p>
        <p>학교장은 끝까지 철회하지 않겠다고 했고, 운영위원들은 침묵했다.</p>
        <p>엄청난 양의 민원이 교육청으로 쏟아졌다.</p>
        <p>하지만 학교장은 요지부동이었고, 교육청은 어찌할 수 없었다.</p>
        <p>결국 E학교는 A교과서 채택을 최종 확정지었다.</p>
        <p>&nbsp;</p>
        <p>F학교는 최초 교과협의회에서 Z교과서를 채택했다.</p>
        <p>그런데 안건이 제출될 때는 A교과서가 채택되어 있었다.</p>
        <p>학교장이 임의로 바꾼 것이다.</p>
        <p>학교운영위원회는 흔한 거수기였고, 심의 결과 가결되었다.</p>
        <p>역시 이곳에서도 학생과 학부모의 격렬한 반대가 발생했다.</p>
        <p>학교장은 끝까지 철회하지 않겠다고 했고, 운영위원들은 침묵했다.</p>
        <p>엄청난 양의 민원이 교육청으로 쏟아졌다.</p>
        <p>하지만 학교장은 요지부동이었고, 이번에도 교육청은 어찌할 수 없어 보였다.</p>
        <p>그런데 안건이 학교장에 의해 조작되었다는 익명의 제보가 교육청에 접수되었다.</p>
        <p>교육청은 조사에 착수했고, 학교장에 의한 안건 조작 혐의가 사실로 드러났다.</p>
        <p>앞선 심의는 무효가 되었고, 최초 Z교과서를 채택했던 안건으로 다시 심의를 했다.</p>
        <p>F학교는 Z교과서 채택을 최종 확정지었다.</p>
        <p>&nbsp;</p>
        <p>G학교는 교과협의회에서 A교과서를 채택했다.</p>
        <p>그런데 학교운영위원회는 흔한 거수기였고, 심의 결과 가결되었다.</p>
        <p>그리고 이 결과는 공지되지 않았으며, 심의에 참여한 운영위원들도 모두 쉬쉬했다.</p>
        <p>이 학교의 학생과 학부모는 새로운 학기가 시작할 때까지도 그 사실을 몰랐다.</p>
        <p>평소 학교운영위원회 심의 결과가 공지되고 있었다는 사실을 몰랐으니, 학교홈페이지에 공지가 올라오지 않았어도 이의를 제기하는 사람은 없었다.</p>
        <p>결국 신학기가 시작되었고, G학교의 학생들은 A교과서를 구매할 수밖에 없었으며, 이미 시간이 많이 지났기도 하거니와, 소수의 A교과서 채택 학교 중 E학교를 빼고 모두 채택을 철회한 상황이라, 사회 여론은 더 이상 역사교과서 이슈에 관심을 가지지 않았고, G학교의 일은 바로 잊혀졌다.</p>
        <p>G학교의 학교장은 회심의 미소를 지었다.</p>
        <p>역시 은폐하고 알리지 않는 것이 학교장을 위한 최선의 방책이라는 진리를 다시 한 번 확인시켜준 사례였다.</p>
        <p>&nbsp;</p>
        <p>B~G학교의 사례는 이해를 돕기 위해 각색하여 재구성한 사례이다.</p>
        <p>일단 학교운영위원회에서 ‘교과서 선정(안)’을 제대로 심의한 학교는 B학교와 C학교 둘 뿐이다.</p>
        <p>C학교의 학교운영위원회는, A교과서를 채택한 사실 때문에 비판을 받을 수는 있겠지만, 심의(논의)를 제대로 했다는 점에서 B학교와 마찬가지로 높은 점수를 받을 만하다.</p>
        <p>C학교는 수주 만에, D학교는 수일 만에 채택을 철회하였는데, 좀 더 빨리 철회(학교구성원의 의견을 수렴)했다고 해서 D학교가 더 잘했다고 칭찬할 것은 아니다.</p>
        <p>C학교는 학교운영위원회의 의지로 재심의 했고, D학교는 여전히 학교장 독재로 재심의 했기 때문에, 바람직한 학교는 C학교라고 할 수 있다.</p>
        <p>C·D·E학교는 모두 불법이 없다면 교육청이 개입할 수 없다는 것을 보여주었는데, 특히 E학교의 경우는 ‘스스로 바꾸지 않으면 결정을 번복할 방법이 없다’는 것을 확인시켜 주었다.</p>
        <p>F학교는 불법이 드러났기 때문에 교육청이 개입할 수 있다는 것을 보여준 사례이며, G학교는 가장 최악의 학교로서, <span className={ss.bold}>학교 구성원의 눈을 가림으로써 독재를 완성</span>한 사례이다.</p>
        <p>그리고 현실의 학교는 대부분 D·E·F·G 타입의 학교이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이처럼 접수된 의견이 처리되는 방식에는 크게 3가지가 있다고 할 수 있다.</p>
      <p>① 의견을 전달 받은 처리 당사자가 공정하고 합리적으로 의견을 처리하는 것이, 접수된 의견이 처리되는 일반적인 방식이다.</p>
      <p>② 의견을 처리함에 있어서 공정성이 결여되고 부당하게 처리할 경우, 학교 내 가장 상위의 감시·견제 기구인 학교운영위원회가 나서서 일을 바로 잡을 수 있다.</p>
      <p>③ 학교장이나 교직원 또는 학교운영위원회가 불법 또는 규정을 위반하여 학부모 의견을 부당하게 처리할 경우, 교육청에 민원을 제기하여 바로 잡을 수 있다.(반드시 필요한 민원)</p>
      <p>그러나 학교운영위원회가 어용기구화 되고 부당하게 운영될 경우라도, 그 운영에 불법적인 요소가 없다면 교육청이 개입할 수 없다.(바람직하지 않은 민원)</p>
      <p>&nbsp;</p>
      <p>학부모의 의견이 공정하게 처리되기 위해서는 당연히 처리 담당자의 합리적 판단이 우선이지만, 세상 모든 사람이 합리적이라고는 기대할 수 없고, 어떤 사람이라도 항상 합리적이라고는 할 수 없으므로, 결국 학교운영위원회가 균형을 갖추고 심의에 많은 노력을 기울여야만, 언제든 일어날 수 있는 부당한 일의 발생을 예방하고 바로 잡을 수 있다.</p>
      <p>따라서 학교 내의 학부모 의견이 잘 수렴되고, 그것이 잘 반영되어 학교가 물 흐르듯 좋은 방향으로 변화하며 순조롭게 운영되려면, 여러 조건이 필요하겠지만, 학교운영위원회의 민주적 작동이 가장 필요한 조건이라고 하겠다.</p>
      <p>&nbsp;</p>
      <p>한편, 위에서 의견이 전달되는 경로에 문제가 있을 때에도 의견 처리에 문제가 발생한다고 하였는데, 이와 같은 일도 자주 발생하는, 빈도가 꽤 높은 일이다.</p>
      <p>주로 학부모회를 이용한 의견 전달 과정에서 자주 발생하는데, 이는 학부모가 학부모회의 역할을 제대로 인식하지 못하는 데에서 주로 기인한다.</p>
      <div className={ss.annotation_wrap}>
        <p>이와 관련한 주요 사례는 2018년에 등장한다.</p>
        <p>그리고 그 때문에 독도 씨는 그 다음해인 2019년에 학부모회장에 출마하게 된다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학부모회는 학부모의 대표 기구이다.</p>
      <p>대표 기구의 가장 중요한 역할은 조직을 구성하는 구성원의 의사를 대표하는 것이다.</p>
      <p>즉, 학부모의 의견을 수렴하여 <span className={ss.quo_mini}>‘이것이 학부모의 뜻입니다.’</span>라고 표하는 것이 학부모회의 핵심 역할이다.</p>
      <p>또한 합의된 전체의 뜻이 아닐지라도, 다수의 의견이 아닌 일부 소수의 의견일지라도, 어떤 의견이 있으면 <span className={ss.quo_mini}>‘이러한 의견이 있습니다.’</span>라고 정확히 밝히거나 전달하여야 한다.</p>
      <p>그러므로 어떠한 의견이 중도에 사라지고 전달되지 않는다는 것은, 전달 과정에 위치한 학부모회 임원, 대의원 등이 학부모회와 자신의 책임을 가볍게 보고 있다는 것을 의미한다.</p>
      <p>한편으로는, 학부모회가 어용기구화 되어 있을 때도 이런 일이 발생할 수 있다.</p>
      <p>&nbsp;</p>
      <p>그렇다면 이런 일(전달 경로상의 문제)을 예방 또는 해결할 수 있는 방법에는 무엇이 있을까?</p>
      <p>소위 핫라인이라고 하는, 직접 연결되는 전달통로가 필요하다고 하겠다.</p>
      <p>고의든 실수든 반대표가 잊으면, 개별 학부모가 학부모회장에게 직접 연락할 수 있는 방법이 있어야 한다.</p>
      <p>또한 학부모회장에게 문제가 있을 경우, 학교운영위원에게 연락할 수 있는 방법도 있어야 한다.</p>
      <div className={ss.annotation_wrap}>
        <p>중요하게 전달해야할 사항이 있는데, 전달이 안 되면 얼마나 답답하겠는가?</p>
        <p>특히 그것이 안전에 관한 일이라면, 자칫 심각한 결과를 야기할 수도 있다.</p>
        <p>그래서 학교의 가장 상위 기구인 학교운영위원회에 의견을 전달할 수 있는 핫라인은 반드시 있어야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>앞서 학부모 전체가 가입하여 의견을 나눌 수 있는 모임 앱이 좋은 도구라고 했는데, 이 모임에 학부모회장도 학교운영위원도 모두 가입되어 있다면 별도의 핫라인이 필요 없다.</p>
      <p>누군가 의견(게시글)을 올리면, 모든 사람이 읽을 수 있기 때문이다.</p>
      <p>전달 과정에서 의견이 누락되는 일은 없을 것이며, 소수의 의견에서 출발하여 공론화가 일어나는 일도 자연스러울 것이다.</p>
      <p>&nbsp;</p>
      <p>여기까지의 설명을 정리하자면, 학교는 항상 학부모의 의견을 수렴하고 이를 반영할 수 있도록 열려 있어야 하며, 그래야만 소비자의 눈높이에 부응할 수 있고 사회의 변화에 무리 없이 적응하고 발맞추어 나아갈 수 있다.</p>
      <p>따라서 다양한 형태의 의견을 받아들이기 위해 모든 창구를 열어 놓고 운영해야 한다.</p>
      <p>또한 의견 수렴에 있어서 가장 중요한 기구인 학부모회가 매끄럽게 운영될 수 있도록 적극적으로 지원해야 한다.(학부모상주실 제공, 행정적 지원 등)</p>
      <p>학교운영위원회, 학부모회 등의 학교자치 기구가 민주적으로 작동할 수 있도록 필요한 환경(규정 준수, 위원 연수, 정보 공개, 자료 제공, 예산 지원 등)을 갖추어야 하며, 공동체의 자치 역량을 끌어올리기 위한 실무 교육과정을 적극적으로 운영해야 한다.</p>
      <div className={ss.annotation_wrap}>
        <p>학부모회 지원의 1순위는 예산의 지원이다.</p>
        <p>그리고 그다음 순위는 채팅 앱이나 모임 앱을 홈페이지 수준에서 지원하는 것이다.</p>
        <p>하지만 기술 개념이 부족한 상태에서 당장 그렇게 할 수 있는 학교는 없다.</p>
        <p>따라서 사설 앱이라도 원활하게 사용할 수 있도록, 학부모의 사설 앱 가입 활동에 협조(지원)를 해주는 것이 필요하다.</p>
        <p>물론, 개인정보보호 문제라든가 기타 관리의 문제가 있기 때문에 적절한 묘수를 내지 않으면 협조가 쉽지 않다.</p>
        <p>그럼에도 불구하고 규정을 현명하게 해석하여 적극적으로 협조하는 학교는, 규정을 보수적으로 해석하여 몸을 사리는 학교에 비해 학교자치의 수준이 크게 높다.</p>
        <p>이와 관련한 상세한 이야기는 차차 설명하도록 하겠다.</p>
      </div>
      <p>&nbsp;</p>
      <p>하지만 안타깝게도, 그렇게 운영되는(열려있는) 학교는 거의 없다.</p>
      <p>지금과 같이 폐쇄적인 학교 현장에서는, 학부모의 의견 수렴이 제대로 되지 않는다.</p>
      <p>그러므로 변화가 촉진되지도 않고, 혁신이 일어날 수도 없다.</p>
      <p>교육계는 왜 그렇게 30년 동안 폐쇄적으로 학교를 운영하고 있을까?</p>
      <p>한 세대가 온전히 바뀌는 긴 시간 동안이나 변화를 거부한 이유가 무엇일까?</p>
      <p>답은 이미 여러 차례 말했고, 앞으로도 계속 말할 것인데, 지면관계상 여기서는 더 말하지 않겠다.</p>
      <p>&nbsp;</p>
      <p>사회의 변화보다 한 세대나 뒤처져 있기 때문에, 학생들이 행복한 학교생활을 하기 바라는 기대는 그 뒤처진 시간만큼 점점 더 멀어지고, 반면에 학생들이 느끼는 고통의 크기는 뒤처진 거리만큼 점점 커진다.</p>
      <p>우리 아이들의 미래는 그만큼 어둡다.</p>
      <p>누가 이렇게 만들었는가?</p>
      <p>누구에게 책임을 물어야하는가?</p>
      <p>교육부총리? 교육감? 교육장? 학교장? 교사단체? 교직원? 학부모?</p>
      <p>이 중에서 누군가를 고르려고 애쓸 필요는 없다.</p>
      <p>모두 다니까.</p>
      <p>&nbsp;</p>
      <p>다만, 학부모는 책임을 져야할 다른 이들과 다른 점이 하나있다.</p>
      <p>책임도 있지만 동시에 <span className={ss.bold}>피해자</span>이기도 하다.</p>
      <p>그러므로 학부모에게는 책임이 없다는 주장도, 반면에 책임만 강조하는 주장도 모두 경계해야 한다.</p>
      <p className={ss.bold}>학부모가 반성하고 변해야한다는 것은 분명한 사실이다.</p>
      <p className={ss.bold}>그러나 그전에, 시스템을 왜곡시키고 공교육을 망가뜨려 놓고도 정작 책임은 하나도 지지 않으며, 피해는 없고 권력만 누린 자들에게 그 책임을 묻는 것이 먼저다.</p>
      <p>냉혹하게, 엄정하게 물어야 한다.</p>
      <p>세상에 공짜 점심은 없다는 것을 철저하게 각인시켜 주어야 한다.</p>
      <p>그래야 우리 교육에 미래가 있다.</p>
      <p>&nbsp;</p>
      <p>자, 지금까지 학부모가 학교에 어떤 의견을 전달하고 싶을 때, 어떤 방법으로 전달할 수 있는지에 대해 살펴보았는데, 이제 다시 독도 씨에게 돌아와서 이야기를 이어가보자.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 곰곰이 생각해보았다.</p>
      <p className={ss.quo_mini}>‘누군가 학교 내의 어떤 문제를 인지하고 그 불편함을 이야기한다면, 나는 그 이야기를 들을 수 있을까?’</p>
      <p>&nbsp;</p>
      <p>당시 한비중학교 학부모회는 진부도교육청 기준으로 바람직한 수준의 학부모회였다.</p>
      <p>그러니까 진부도교육청이 판단했을 때 ‘잘 운영되고 있는’ 학부모회였다.</p>
      <p>&nbsp;</p>
      <p>하지만 진부도교육청이 바람직하다고 판단하는 학부모회의 수준은, 독도 씨가 바람직하다고 평가하는 학부모회의 수준과는 많은 차이가 있다.</p>
      <p>독도 씨가 생각하는 바람직한 학부모회는, 원활한 학교자치를 위해 요구되는 자치역량을 잘 갖춘 학부모회이다.</p>
      <p>자치역량을 갖춘다는 것은, 학부모회가 학부모의 대표(대의) 기구로서 학부모의 의사를 정확하게 반영하는 것을 첫 번째 원칙(임무)으로 삼아야 하며, 의사 반영(의견 수렴)을 위한 합리적인 방법(시스템)을 갖추어야 하고, 학교 운영의 중요한 한 축으로서 민주적 절차를 통해 그 운영에 적극적으로 참여할 수 있어야 한다는 것을 뜻한다.</p>
      <p>&nbsp;</p>
      <p>반면에 진부도교육청은, ‘교육청 정책과 학교장의 교육철학에 협조’하는 것을 학부모회의 가장 중요한 가치라고 판단한다.</p>
      <p>그러한 가치에 길들여진 학부모회는, 학교자치와 같은 개념은 상상도 하지 못한다.</p>
      <p>그저 학부모회는 학교장에 협조하고, 봉사활동을 열심히 하고, 시간 여유가 있는 몇 몇 학부모들을 모아 인당 몇 만 원짜리 취미(연수) 활동을 한두 번 해주는 조직이며, 그런 활동들이야말로 의미 있는 학부모 활동이며, 자신들의 자녀를 위한 교육적 활동이라고 여긴다.</p>
      <p>&nbsp;</p>
      <p>진부도교육청은 학부모회 네트워크를 통해 학부모회 우수사례를 선정하고 홍보하는데, 봉사활동(녹색어머니회, 학부모폴리스 등)을 열심히 하거나 학부모 연수(꽃꽂이, 비누 만들기, 가죽 공예, 케이크 만들기와 같은 소위 평생교육 강좌)를 활발하게 시행하는 학부모회를 우수사례로 홍보한다.</p>
      <p>이렇게 학부모회 활동을 ‘봉사활동과 평생교육 활동’으로 한정하도록 세뇌하면, 학교자치에 대한 개념(수평적 운영 개념)은 가질 수 없고, 당연히 학교에 협조적인(수직적 운영에 협조하는) 학부모회가 된다.</p>
      <p>그러한 학부모회는, 주권을 가지고 학교 운영에 참여하는 존재가 아니라, 학교장의 하부 조직으로서, 학교 내·외부에서 비판이 발생하거나 어떤 문제가 발생했을 때 교육청과 학교장을 지지하기 위해 동원되는 지원군(shield)일 뿐이다.</p>
      <p>학교 내 이슈에 대해서는 <span className={ss.quo}>“우리는 우리 학교의 선생님들을 믿는다.”</span>고 말하며 문제(잘못)를 덮는데 일조하고(불법을 은폐하기도 하고, 2차 가해를 일으키기도 한다), 정치적인 이슈가 발생하면 <span className={ss.quo}>“교육감의 정책을 환영한다.”</span>는 여론을 형성하는데 앞장서는 것이 교육청이 바라는 학부모회의 위상이다.</p>
      <p>&nbsp;</p>
      <p>그러니까 진부도교육청이 바람직하게 생각하는 학부모회는, 학교자치(학교민주주의) 같은 것에 관심을 가져서는 안 되고 봉사활동이나 취미활동 같은 것에만 관심을 가져야 하며, 진부도교육청이 그러한 학부모회를 우수 사례로 홍보함으로써 학부모를 세뇌시키는 것은, 앞 장에서 김만근 씨가 말한 <span className={ss.bold}>우민화 정책</span> 같은 것이라 보면 된다.</p>
      <p>&nbsp;</p>
      <p>당시의 한비중학교 학부모회는, 부종시 학부모회 네트워크에서 우수 사례로 선정할 정도로 봉사활동이 조직적으로 잘 이루어졌고, 학부모 대상 평생교육 활동에 대한 호응도 좋았으며, 심지어 학부모 동아리도 3개나 운영되는 매우 활발한 학부모회였다.</p>
      <p>다만, 안타깝게도 학부모의 의견을 수렴하여 학교에 전달하는 시스템이 제대로 갖추어진 상황도 아니었고, 민주적 절차를 통해 학교 운영에 적극적으로 참여할 수 있는 자치역량을 갖춘 상황도 아니었다.</p>
      <p>또한 학부모를 대상으로 하는 학교자치와 관련한 학부모 연수를 실시한 적도 없었고, 학교민주주의에 대한 인식도 매우 낮았다.</p>
      <div className={ss.annotation_wrap}>
        <p>앞으로 몇 번 언급하겠지만, 한비중학교 학부모회는 2014년부터 전체 학부모를 대상으로 하는 모임 앱을 이용하고 있었다.</p>
        <p>앞서 설명했지만, 잘 운영한다면 이는 매우 바람직한 소통의 도구이다.</p>
        <p>그러나 아쉽게도 두 가지 정도의 문제가 있어서, 소통의 한계가 뚜렷했다.</p>
        <p>&nbsp;</p>
        <p>첫째, 비공식적으로 운영되고 있어서(입소문에 의지하고 있어서) 모든 학부모가 가입하고 있지는 않았다.</p>
        <p>독도 씨도 2학기 중반이 되어서야 그 존재를 인지하고 가입할 수 있었다.</p>
        <p>이는 학교홈페이지 등 공식적인 소통 수단과 연계되지 않아서 발생하는 어쩔 수 없는 한계이다.</p>
        <p>또한, 학교는 이런 앱의 존재를 홍보해주거나 지원해주지 않는다.</p>
        <p>&nbsp;</p>
        <p>둘째, 학부모회의 공지 수단으로써는 적절히 활용하고 있었으나, 의견 수렴의 수단으로써는 거의 활용되지 않고 있었다.</p>
        <p>학부모회 임원의 공지만 가끔 올라오고, 학교생활과 관련한 개별 학부모의 의견(여론)은 올라오지 않고 있었다.</p>
        <p>다양한 의견이 제시되고 여론이 형성되기 위해서는 이용 문화가 성숙되어야 하는데, 그러기에는 아직 부족함이 많아 보였다.</p>
        <p>&nbsp;</p>
        <p>10년이 지난 최근의 사정을 보면, 해가 거듭될수록 나아지고 있으나 아직까지도 부족한 부분이 모두 해소되지는 않았다.</p>
        <p>이런 종류의 앱은 학교자치 활성화에 필요한 도구이지만, 앱의 이용을 활성화하기 위해서는 거꾸로 학교자치의 활성화가 어느 정도 필요하다.</p>
        <p>서로 순환적인 관계이기 때문에, 학교자치가 정체된 상황에서의 발전은 더딜 수밖에 없다.</p>
        <p>&nbsp;</p>
        <p>결론을 말하자면, 한비중학교 학부모회가 모임 앱을 이용한다는 사실은 매우 고무적이지만, 공지 시스템으로 주로 활용하고 있었기 때문에, 의견을 제시하고 여론을 형성할 수 있는 의사 전달 시스템으로서는 많이 부족했다.</p>
        <p>그리고 독도 씨는 당시 모임 앱을 인지하기 전이었으니, 그나마 학부모회에 임원 등에게 의견을 물을 기회조차 없었다.</p>
      </div>
      <p>&nbsp;</p>
      <p>한비중학교 학부모회의 상황이 이러했으며, 학교홈페이지에 건의게시판도 없었으며, 운영위원과 학부모회 임원이 서로 의견을 교환할 수 있는 창구도 없었기 때문에, 독도 씨가 운영위원으로서 학부모의 의견을 수렴할 방법은 아무것도 없었다.</p>
      <div className={ss.annotation_wrap}>
        <p>운영위원과 학부모회 임원이 서로의 연락처를 모르는 경우는 학교 현장에서 매우 흔한 일이다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘다른 아이들은 어떻게 생각하고 있는지 의견을 들어봐야 한다. 급식에 불만이 있는 아이들 중 상당수는 부모에게 말을 할 것이다. 그렇다면 학부모들은 걱정이 되어 다른 학부모들과 서로 이야기를 나눌 것이다. 나로서는 직접 의견을 들을 기회가 없으니 밤톨이 엄마한테 부탁을 해야겠다. 이야기를 나누고 정보를 교환하는 엄마들이 몇 명 있다고 하니 그들과 대화해보면 어느 정도 정보는 얻을 수 있을 거야. 그밖에 다른 방법은 무엇이 있을까?’</p>
      <p>아무래도 당시의 독도 씨는 지금보다 아는 것이 현저히 적었기 때문에 보다 심각하게, 보다 오래 고민을 해야 했다.</p>
      <p>물론 지금의 독도 씨라면, 급식에 문제가 있는지 없는지를 조사하는 방법이나, 문제를 분석하는 방법을 떠올리는 것은 그다지 어렵지 않은 일이다.</p>
      <p>그렇다면 과연 독도 씨가 떠올릴만한 방법에는 어떤 것이 있는지 알아보자.</p>
      <p>&nbsp;</p>
      <p>첫째, 급식 만족도 조사 분석.</p>
      <p>학교는 정기적으로 급식 만족도 조사를 하고 그 내용을 홈페이지를 통해 공개한다.</p>
      <p>급식의 양을 포함하여, 급식의 전반적인 내용에 대해 학생들이 어떻게 생각하고 있는지 아주 확실하게 나타나기 때문에, 홈페이지의 ‘급식 만족도 조사 결과’를 확인해보면 급식의 문제점을 파악하는데 큰 도움이 된다.</p>
      <p>&nbsp;</p>
      <p>둘째, 급식소위원회 활동.</p>
      <p>독도 씨는 급식소위원회 위원이다.</p>
      <p>급식소위원회는 다양한 활동을 하는데, 가장 일반적이고 정기적인 활동으로는 식재료 검수, 조리과정과 배식 등의 모니터링(위생, 안전 등 운영 방식·상황의 점검)이 있고, 핵심적인 활동으로는 학교급식운영계획(안)의 실무 검토, 학교급식 운영 개선에 관한 활동 등이 있다.</p>
      <p>학교급식운영계획(안)은 신학기가 시작되기 전에 이전 년도 운영위원회에서 심의를 하기 때문에 독도 씨는 2015학년도 학교급식운영계획(안)의 심의에는 참여하지 못했다.</p>
      <div className={ss.annotation_wrap}>
        <p>올해 학교급식운영계획(안)은 작년 운영위원이 심의한다.</p>
        <p>이는 금년도 예산을 전년도 말에 심의하는 것과 같은 이치다.</p>
      </div>
      <p>따라서 1학기 중에 독도 씨가 급식소위원회 위원으로서 활동한 내용은 급식소위원회 회의 참석 한 번과 모니터링 활동 한 번뿐이었다.</p>
      <div className={ss.annotation_wrap}>
        <p>기회가 없어서 언급하지 않았지만, 독도 씨는 급식소위원회 첫 회의에서 급식소위원회 위원장으로 선출되었다.</p>
      </div>
      <p>모니터링 때 보았던 바로는, 식재료 검수 절차는 문제가 없었고, 조리실도 깨끗하게 운영되고 있었고, 배식도 학생들이 점심시간 내 모두 식사를 마치는데 문제가 없도록 효율적으로 운영되고 있었다.</p>
      <p>배식은 영양교사, 조리실무사, 그리고 자원봉사 학생들이 하고 있었으며, 정해진 양만큼 공정하게 배식을 하고 있다고 판단했다.</p>
      <p>눈여겨보지 않아서일지도 모르겠으나, 당시 급식의 양이 적어서 불만을 터뜨리는 학생이 있는 것 같지는 않았다.</p>
      <p>만일 급식소위원회 위원인 독도 씨가 필요하다고 판단한다면, 영양교사의 협조를 구해 배식 과정의 모니터링을 다시 할 수 있으며, 학생들의 의견을 공식적으로 묻는 급식 만족도 조사를 추가 실시하도록 운영위원회에 제안할 수 있으며, 공식적으로 학부모회에 의견 수렴을 요청할 수도 있다.</p>
      <p>물론 어떤 행동을 하려면, 행동의 근거가 필요하다.</p>
      <p>밤톨이나 아주 소수의 학생들만 양이 부족하다고 말하는 것이라면, 그것을 근거로 행동하는 것은 쉽지 않을 수도 있다.</p>
      <p>어쨌든, 급식과 관련하여 무언가 의심될 때, 문제가 있는지 조사하거나 분석을 하기 위해, 여러 절차를 활용 수 있는 권한이 급식소위원회 위원인 독도 씨에게는 충분히 있었다.</p>
      <div className={ss.annotation_wrap}>
        <p>만일 누군가 급식 문제를 조사하고 싶은데 급식소위원회 위원이 아니라면, 급식소위원회나 학교운영위원회에 조사를 요청하면 된다.</p>
        <p>정상적인 경우라면, 조사 과정을 참관하거나, 직접 참여할 수도 있다.</p>
        <p>그런데 그렇게 하기 위해서는, 급식소위원회 위원에게 직접 연락이 가능하거나 학부모회를 통해 연락을 할 수 있어야 한다.</p>
        <p>즉, 학부모회가 의견 수렴을 원활하게 하고 있어야 하고, 학부모회와 학교운영위원회가 상호 소통을 하고 있어야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>셋째, 급식모니터링 자료의 분석.</p>
      <p>학교에는 급식모니터링 제도가 운영되고 있다.</p>
      <p>급식모니터링 제도는, 일정 수의 학부모를 급식모니터링위원으로 선발하여 급식의 전반적인 운영 상황을 모니터링 할 수 있도록 만들어진 제도이다.(보통 학급별로 골고루 선발한다.)</p>
      <p>용어의 혼동을 피하기 위해, 편의상 일반 학부모 모니터링이라고 하자.</p>
      <p>일반 학부모 모니터링 활동과 급식소위원회에서 하는 모니터링 활동의 내용은 거의 같다.</p>
      <p>일반 학부모 모니터링도 식재료 검수가 이루어지는 과정, 조리실에서 조리되는 과정, 배식되는 과정을 지켜보고, 직접 급식 시식을 한다.</p>
      <p>학부모는 모니터링(점검) 결과와 제안하고 싶은 내용 등을 적은 활동 일지를 제출하고, 영양교사는 이를 수집하여 급식 운영에 반영한다.</p>
      <p>이러한 일반 학부모 모니터링 활동은 상시적이다.</p>
      <p>기간이나 인원에 특별한 제한은 없으나, 대개 1주나 2주에 한 번 정도, 인원은 주로 한 번에 2명 안팎으로 1년 내내 진행한다.</p>
      <p>반면에 학교운영위원회에서 구성한 급식소위원회의 모니터링 활동은 상시적이지 않다.</p>
      <p>보통 학기에 한두 번 정도 진행한다.</p>
      <p>그리고 급식소위원회의 모니터링 결과는 학교운영위원회에 보고되는 것이 원칙이다.</p>
      <p>&nbsp;</p>
      <p>참고로, 급식소위원회의 활동은 급식 운영의 계획(심의), 감시, 개선 등이 주된 목적이지만, 일반 학부모 모니터링은 감시, 개선 등의 목적을 포함하되 학부모의 학교급식에 대한 이해와 홍보가 주된 목적이다.</p>
      <p>이와 같은 급식모니터링 제도를 이용하는 것도, 급식에 문제가 있는지 조사하거나, 문제가 있다면 무엇이 문제인지 조사·분석하는데 도움이 되는 방법이다.</p>
      <p>상시 운영하여 수집된 일반 학부모의 모니터링 의견서(활동 일지)를 조사·분석하기 위해, 영양교사에게 자료 제출을 요구하는 것도 학교운영위원회를 통해서 할 수 있는 일이다.</p>
      <div className={ss.annotation_wrap}>
        <p>다소 입맛에 맞지 않더라도 <span className={ss.fbody}>‘급식이 참 맛있어요.’</span>라고 의견을 적는 학부모가 많다.</p>
        <p>체면인지 배려인지 모르겠으나, 학생들을 위한 급식 개선에는 도움이 되지 않는다.</p>
        <p><span className={ss.fbody}>‘튀김이 눅눅해요. 국의 간이 세요. 밥이 질어요. 나물을 너무 오래 삶은 것 같아요. 생선의 비린내가 덜 잡힌 것 같아요.’</span>와 같이 구체적인 문제를 지적하거나, 반대로 <span className={ss.fbody}>‘고기를 질기지 않게 잘 삶았어요. 국의 온도가 적당해요. 국수가 적당하게 잘 익었어요.’</span>와 같이 구체적인 칭찬을 적는 것이 좋다.</p>
        <p>구체적인 지적은 식단의 구성, 음식의 조리법(recipe), 조리 과정·방법 등에 반영되어 급식의 개선을 이끌어낸다.</p>
        <p>따라서 솔직하고 합리적인 모니터링은 자녀들의 건강한 식생활에 반드시 필요한 행위이다.</p>
        <p>최근에도 여전히 영양교사나 학교장의 눈치를 보며 의견서를 적어 내는 학부모가 있는데, 지적하는 행위는 결코 나쁜 것이 아니므로 눈치를 보지 말자.</p>
        <p>무조건 별 다섯 개가 예의라고 생각한다면, 당신의 자녀가 맛없는 급식을 먹을 확률은 비약적으로 증가한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이상과 같이, 급식의 어떤 문제를 파악하기 위해서 다양한 방법을 사용할 수 있는데, 불행히도 이때의 독도 씨는 다른 학부모 운영위원과 그들이 활동하는 여러 학부모 참여 위원회에서 은근한 견제(따돌림)를 받고 있는 상황이었다.</p>
      <p>임춘옥 교장이 윤미옥 위원장에게 독도 씨의 이야기를 한 이후, 윤미옥 위원장이 독도 씨와의 만남에서 경고를 했던 즈음부터, 그들과 그들 주변의 학부모들은, <span className={ss.quo_mini}>‘학부모 활동은 엄마들의 일이야. 교육은 아빠들이 나서는 게 아니야. 아빠들이 뭘 알겠어? 저 사람도 뭐가 아이들한테 중요한 건지 몰라. 자꾸 시끄럽게만 만들려고 해. 재수 없게도 자기가 뭐라도 된 것 마냥 설친다니까. 그냥 무시하자고.’</span>라는 의견을 공유하고 그대로 행동하고 있었다.</p>
      <p>&nbsp;</p>
      <p>그리고 이는 급식소위원회에서도 마찬가지였다.</p>
      <p>그래서 독도 씨는 ‘문제가 있는지 알아낼 수 있는 방법’을 고민하면서, 다른 학부모의 협조 없이 혼자서 할 수 있는 방법을 고민해야 했고, 따라서 두 번째와 세 번째 방법에 대해서는 아예 생각조차 미치지 못했다.</p>
      <p>결국 한동안 고민하던 독도 씨는, 이리저리 학교홈페이지를 살펴보고 이전 학교운영위원회 회의록을 뒤적거리다가 ‘급식 만족도 조사’가 있다는 것을 발견하게 되었는데, 바로 첫 번째 방법을 찾아낸 것이다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘어 이런 게 있었네? 이거 괜찮은데? 정말로 급식의 양에 문제가 있는지 없는지 알려면, 직접 먹어보거나 아니면 여러 학생들에게 물어봐야할 텐데, 다수의 학생을 대상으로 한 설문조사가 있으니 충분히 의미가 있겠어.’</p>
      <p>그래서 독도 씨는 급식 만족도 조사 결과 문서를 학교홈페이지에서 내려 받았다.</p>
      <p>&nbsp;</p>
      <p>그 문서에는 다양한 항목의 질문이 있었고, 학년별로 4개 학급이 참여함으로써 약 400여 명의 학생들이 대답한 내용을 각각 숫자(비율)로 표시하고 이를 그래프로 작성하여 보여주고 있었다.</p>
      <p>독도 씨는 이 문서를 꼼꼼히 읽기 시작했다.</p>
      <p>그리고 처음 생각한 것보다 더 심각한 문제가 있음을 알려주는 조사 결과가 그의 머릿속을 헤집고 돌아다니기 시작했다.</p>
      <p>&nbsp;</p>
      <p>이는 독도 씨가 급식소위원회 모니터링 활동에 참여했을 때에는 미처 인지하지 못했던 문제였다.</p>
      <p className={ss.quo_mini}>‘그때 시식도 해볼걸. 배식 지켜보고 아이들 밥 먹는 것과 퇴식 과정만 지켜보고 바빠서 시식은 생략했는데 후회가 되네.’</p>
      <p>&nbsp;</p>
      <p>또한, 며칠 뒤 들은 밤톨 엄마의 이야기로부터, 급식만 문제가 아니라 급식 문제에 대처하는 학부모회에도 문제가 있음을 인지하게 되었다.</p>
      <p className={ss.quo}>“민철이네 엄마가 그러는데 민철이도 학교 끝나고 오면 배고프다고 난리라서 저녁 먹기 전에 한 끼를 더 먹인대. 다 그렇지는 않은데 주로 남자애들 집에서 급식이 작아서 불만이라는 이야기가 종종 나온다네. 뭐, 남자애들이야 워낙 정신없이 뛰어노는데다가 한창 크는 때라서 배고플 수밖에 없다고, 그래서 어쩔 수 없다는 분위기로 흐른다고 해.”</p>
      <p>밤톨 엄마와 친하게 지내는 민철 엄마는, 아는 엄마들이 많고 정보수집이 빨라 정보통으로 불리는 학부모이다.</p>
      <p>따라서 민철 엄마가 하는 말이라면, 사실상 학부모 여론이라고 믿어도 무리가 없었다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 곧바로 반대 의견을 말했다.</p>
      <p className={ss.quo}>“어쩔 수 없다고? 아니야. 내가 보니까 <span className={ss.bold}>남학생들에게는 확실히 적게 줘.</span> 문제가 있어. 애들이 성장기이고 활동량이 많아서 금방 배고파진다는 것도 맞는 이야기인데, 그럴수록 더 줘야지, <span className={ss.bold}>배가 고프다는 건 부족하다는 거야.</span> 어쩔 수 없다고 말하면 안 돼.”</p>
      <p className={ss.quo}>“하지만 학교에서 무한정 더 줄 수도 없잖아? 정해진 양이 있을 텐데……”</p>
      <p className={ss.quo}>“지금 확인중인데……. 아무래도 급식 체계에 문제가 있는 것 같아.”</p>
      <p>&nbsp;</p>
      <p>독도 씨는 밤톨 엄마에게 한 가지를 더 물었다.</p>
      <p className={ss.quo}>“그런데 여자애들은 안 모자라다고 해?”</p>
      <p className={ss.quo}>“여자애들 엄마들은 그런 이야기가 없대. 아무래도 여자애들은 남자애들보다 적게 먹으니까 그런 거 아닐까?”</p>
      <p>&nbsp;</p>
      <p className={ss.quo}>“여자애들이 적게 먹는다는 말은, 평균을 의미하는 말이니까 사실이긴 한데, 그 이유를 알아야 해. 엄밀하게는 체구가 작은 애들이 적게 먹는 거지. 남자애들이 여자애들보다 평균으로는 덩치가 크고 근육도 많으니까, 많이 먹는 순으로 줄을 세우면 앞 쪽에는 주로 남자애들이 서겠지. 여학생 평균보다 체구가 작은 남학생은 평균 체구의 여학생보다 덜 먹을 수도 있어. 반면에 남학생 평균보다 키와 체구가 큰 여학생은 더 많이 먹을 수도 있겠지. 개인의 체질이나 활동량, 식사 습관, 식사 문화 등 여러 가지 다른 변수도 식사량과 연관이 있겠지만, 가장 큰 관계가 있는 것은 체구(체중)라고. 그러니까 평균적으로 여자애들이 적게 먹는다고 해서 모든 여자애들이 적게 먹는다고 판단하면 안 돼. 체구를 고려하지 않고 무조건 성별에 맞춰서 판단하면 성별 평균보다 덜 먹고 더 먹는 학생들이 정상이 아닌 것처럼 인식될 수도 있어.”</p>
      <p>독도 씨는 한 번 호흡을 가다듬고 다시 이야기를 이어갔다.</p>
      <p className={ss.quo}>“대부분의 사람들이 학생 개개인을 볼 때는 체격에 따라 먹는 양이 다르다는 판단을 해. ‘쟤는 키도 크고 체격이 탄탄해서 많이 먹겠구나.’하는 생각이 상식인 것이지. 그런데 집단을 대할 때는 단순하게 성별로만 판단하는 경우가 많더라고. 나는 이게 바람직하지는 않은 것 같아. 지금 내가 「학교급식법」과 관련 시행령, 시행규칙을 찾아서 보고 있는데, 「학교급식법 시행규칙」에 그런 문제가 있더라고. 성별 평균을 고려해서 섭취 칼로리 수치를 산출해 놓았는데, 그건 평균이니까 학교 현장에서는 개인별 차이를 고려하라고 말해줘야 하는데 그런 말이 없어. 그러니까 교사들은 공무원답게 그냥 단순하게 법을 적용하고 있는 거지. <span className={ss.bold}>생각이란 걸 안하는 것 같아.</span> 음……, 「학교급식법 시행규칙」의 문제에 대해서는 좀 더 정리한 다음에 알려 줄게.”</p>
      <p>&nbsp;</p>
      <p>교사들이 공무원답게 생각이란 걸 안한다는 것은, 알 사람은 다 안다.</p>
      <p>이번에 다룰 급식 사례 외에도 그런 사례는 무수히 많다.</p>
      <p>단언컨대 일반화해도 된다.</p>
      <p>이 책의 말미까지 읽는다면, 여러분들은 100% 동의할 것이라고 생각한다.</p>
      <p>&nbsp;</p>
      <p>중요한 부분이니까, 말이 나온 김에 한 가지 사례를 살펴보자.</p>
      <p>“질병 결석임을 증명할 수 있는, 의사의 진단서 또는 처방전, 학부모의견서 등을 제출하여야 한다.”는 규정이 있다.</p>
      <p>그렇다면 뭘 제출하여야 하는가?</p>
      <p>당연히 진단서나 처방전이나 학부모의견서 중에서 아무 것이나 하나 제출하면 된다.</p>
      <div className={ss.annotation_wrap}>
        <p>“등(等, 같을 등)”이라는 단어는 동급의 여러 가지를 나열할 때 사용하는 단어이다.</p>
        <p>표준국어대사전에서는 ‘그 밖에도 같은 종류의 것이 더 있음’, ‘두 개 이상의 대상을 열거한 다음에 쓰여, 대상을 그것으로 한정함’을 나타낸다고 설명하는데, 2개의 뜻이 있으므로 문맥에 따라 해석할 필요가 있다.(후자의 경우 주로 개수를 한정하는 내용이 따라 붙는다. 예를 들면 ‘충청, 전라, 경상 등 3도의 수군’과 같은 형태가 이에 해당된다.)</p>
        <p>하지만, 전자로 해석하든 후자로 해석하든, 나열된 단어들은 모두 같은 급임이 틀림없다.</p>
        <p>그러니까 위 규정에서는 “등”을 사용함으로써, 진단서와 처방전과 학부모의견서는 모두 동급의 효력이 있다고 인정하고 있는 것이다.</p>
        <p>&nbsp;</p>
        <p>증명을 위한 절차라면, 동급의 문서 어느 것으로나 증명이 가능하므로 하나만 있으면 된다.</p>
        <p>“신분을 증명하기 위해 주민등록증, 운전면허증, 여권 등이 필요하다.”고 할 때, 이 세 가지를 다 가지고 가야 신분이 증명되는 것인가?</p>
        <p>아니다.</p>
        <p>아무 것이나 하나만 가져가도 신분 증명이 된다.</p>
        <p>심지어 “등”은 ‘그 밖에도 같은 종류의 것이 더 있음’이라고 해석할 수 있기 때문에 동급의 다른 것을 가져가도 된다.</p>
        <p>이를 테면 주민등록등본이나 건강보험증이나 국가기술자격증 같은 것도 신분을 증명할 수 있다.</p>
        <p>만일, 증빙(자료)의 대상을 한정하려면 ‘주민등록증, 운전면허증, 여권 등 세 가지 중의 하나만 가능하다’라고 명시해야 하며, 그럴 일이야 없겠지만 혹시 다 필요한 상황이라면 ‘주민등록증, 운전면허증, 여권 등 세 가지가 모두 필요하다’라고 명시해야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그런데 이 문구를 해석하는 교사의 유형에는 세 가지가 있다.</p>
      <p>진단서, 처방전, 학부모의견서 이 3개를 모두 가져오라는 교사가 있고, 진단서 또는 처방전 중의 하나와 학부모의견서 이 2개를 가져오라는 교사가 있으며, 셋 중의 하나만 가져오면 된다는 정상적인 교사가 있다.</p>
      <p>놀라지 마시라.</p>
      <p>세 번째 유형의 교사가 가장 적다.</p>
      <p>&nbsp;</p>
      <p>학교 현장에서 실제 벌어지고 있는 일이다.</p>
      <p>여러분 학교의 학업성적관리규정을 보면 대부분 ‘질병으로 인한 결석’을 처리할 때 위와 같은 서류(진단서, 처방전, 학부모의견서 중 하나)를 요구한다.</p>
      <p>그런데 담임교사가 모두 달라고 하면, 혹은 처방전이라도 꼭 달라고 하면, 그냥 주면 그만인 것일까?</p>
      <p>진단서와 처방전 중에 하나라도 꼭 있어야 한다고 요구한다면 과연 어떤 문제가 발생할 수 있을까?</p>
      <p>&nbsp;</p>
      <p>한 번 사례를 들어보자.</p>
      <p>아이가 미열이 있고 몸살기가 있어서 하루 쉬고 싶다고 할 때, <span className={ss.quo_mini}>‘아이가 몸살이 나서 하루 쉽니다. 학부모인 제가 확인했어요. 무단결석 아닙니다.’</span>라고 학부모의견서만 제출하면 되는데, 담임교사가 진단서(처방전)도 같이 제출해야한다고 하면, 아픈 몸을 이끌고 굳이 병원에 가서 진단서(처방전)를 받아야 한다.</p>
      <div className={ss.annotation_wrap}>
        <p>게다가 병원이 집 근처에 있다는 보장도 없다.</p>
      </div>
      <p>병원에 가서 치료를 받는 것이 유리한 질병이 있고, 집에서 쉬는 것이 더 나은 질병이 있다.</p>
      <p>후자의 경우에는 병원에 가는 것이 오히려 더 힘들고, 컨디션을 더 악화시킬 수 있다.</p>
      <p>하지만 반드시 진단서(처방전)를 가져오라고 하니, 이는 하루 이틀 쉬면 나아질 수 있는 병 따위에는 쉬지 말고, 그 병을 키워서 도저히 병원에 가지 않으면 안 될 때에, 병원에 가서 진단을 받고 쉬라는 뜻이다.</p>
      <div className={ss.annotation_wrap}>
        <p>논점과는 별개의 이야기인데, 이는 명백한 <span className={ss.bold}>아동청소년학대이고 인권침해이다.</span></p>
      </div>
      <p>&nbsp;</p>
      <p>또한 대다수 학교의 학업성적관리규정은 지각·조퇴·결과의 경우도 결석일 때와 마찬가지로 처리하라고 되어 있는데, 이 경우는 좀 더 심각하다고 할 수 있다.</p>
      <p>점심 급식을 먹고 체했는지 오후 수업이 너무 힘들었다고 하자.</p>
      <p>토하고 두통도 있고 속도 너무 불편하고 수업에 집중도 안 되어서 조퇴를 하였는데, 집에 와서 잠시 쉬니 괜찮아 졌다고 하자.</p>
      <p>다 나았어도 병원에 가서, <span className={ss.quo}>“아까는 아팠는데, 이제 다 나았어요.”</span>라고 말하고 진단서를 받아야 한다.</p>
      <p>아침에 열감이 있고 목이 따끔해서, 혹시 유행하는 독감인지 몰라 한두 시간 지켜보았는데, 상태가 심하지 않아 보여서 학교에 갔다고 하자.(지각)</p>
      <p>다행히 하교 후에는 증상이 모두 사라졌지만, 이 학생도 병원에 가서 <span className={ss.quo}>“아픈 곳이 없어요.”</span>라고 말하고 진단서를 받아야 한다.</p>
      <p>&nbsp;</p>
      <p>위와 같은 사례를 보면, 상식적으로 판단했을 때, 의사의 진단서나 처방전을 제출하는 것이 불필요한 경우가 있다는 것을 알 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>득보다 실이 많은, 교사에 의한 과잉진료라고 할 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이렇게 일시적인 이상 증세로 결석(지각‧조퇴‧결과)을 하는 경우는 적지 않다.</p>
      <p>그래서 학업성적관리규정은, 상습적이지 않는 결석(지각·조퇴·결과)이라면 학부모의견서만으로도 질병을 증명할 수 있도록 규정하고 있는 것이다.</p>
      <p>&nbsp;</p>
      <p>하지만, 분명히 규정에는 학부모의견서만 있으면 충분하다고 명시되어 있는데, 담임교사는 <span className={ss.quo}>“안 됩니다. 규정에 의해 진단서나 처방전이 꼭 있어야 합니다.”</span>라고 말한다.</p>
      <p>이런 학교, 이런 교사가 너무 많다.</p>
      <p>그들에게 “등”이란 단어를 설명해야 하는 것은 비참한 일이다.</p>
      <p>중학생 정도만 되어도 사전을 찾아보거나 간단하게 설명만 들으면 충분히 이해하는 단어의 뜻을, 학사 이상의 전문 지식인에게 구구절절 예를 들어 설명하는 것은 결코 유쾌한 일이 아니다.</p>
      <div className={ss.annotation_wrap}>
        <p>더욱 놀라운 것은 그중에 국어 선생님도 있다는 것이다.</p>
      </div>
      <p>독도 씨 등이 열심히 설명해주면 답변을 하는데, 답변의 유형에도 세 가지가 있다.</p>
      <p>&nbsp;</p>
      <p>1. 인정은 한다. 그러나 모두 내라.</p>
      <p className={ss.quo}>“안 됩니다. 아버님 말씀은 맞는데, 계속 그래왔어요. 처방전이랑 학부모의견서랑 주세요.”</p>
      <p className={ss.quo_mini}>‘안다. 하나만 내면 된다는 것을. 그러나 관례가 그렇다. 하나만 가져와도 된다고 말하려면, 내가 윗선(부장교사 등)에 결재 올릴 때 이걸 설명해야한다. 만일 윗선에서 아니라고 하면 어쩌겠는가? 내가 설명해도 인정하지 않을 것이고, 오히려 일처리를 복잡하게 한다고 나만 찍히겠지? 설명하기도 힘들고, 인정시키기는 더욱 힘들 텐데, 내가 왜 그럼 부담을 져야하는가? 그냥 내가 달라는 대로 가져오면 되잖아! 나는 바쁘니까, 서류를 내는 당신들이 알아서 할 일이지.’</p>
      <p>&nbsp;</p>
      <p>2. 잘 모르겠으니 일단 모두 내라.</p>
      <p className={ss.quo}>“글쎄요. 그게 일반 문법과 다를 수가 있어요. 규정이란 게 좀 어려워요. 제가 따로 알아보겠습니다. 그러니 일단 이번에는 그냥 처방전도 같이 내세요.”</p>
      <p className={ss.quo_mini}>‘자세히 보니 그렇구나. 선배들이 가르쳐준 대로만 했지 규정을 자세히 볼 생각은 안했어. 그런데 규정에도 일반 문법이 똑같이 적용되나? 원래 규정이란 게 용어도 그렇고 좀 다르지 않나? 잘 모르겠다. 확신이 없으니 일단 전부 내라고 해야겠다. 물론 다음에 알아볼 생각은 없다. 교장선생님이나 교육청 같은 곳에 물어봐야 하는데, 유난스럽다고 윗선으로부터 찍힐 일이다. 윗선에서 학부모의견서만 내도된다고 지시하지 않는 이상 내가 함부로 결정하거나 나서서 바꿀 일이 아니다. 이건 내 책임이 아니야.’</p>
      <p>&nbsp;</p>
      <p>3. 무슨 소리냐? 다 내는 것이 원칙이다.</p>
      <p className={ss.quo}>“아니오, 아버님. ‘등’이라고 되어 있으면 전부 내는 게 맞아요. 그러니까 다 제출하세요. 이건 제가 결정한 게 아니에요. 바쁘니까 이 일로는 더 이야기하지 말아주셨으면 합니다.”</p>
      <p className={ss.quo_mini}>‘문법은 나는 모르겠고, 그냥 계속 그렇게 해 왔으니 그게 맞는 거지. 당신 말이 맞으면 왜 지금까지 그렇게 했겠어? 나한테 판단을 요구하다니! 나는 규정 같은 것을 판단하는 사람이 아니야. 학교에서 시키면 시키는 대로 할 뿐이야.’</p>
      <p>&nbsp;</p>
      <p>그렇다.</p>
      <p>“등”의 의미를, 일부 교사는 인정하고, 일부 교사는 확신하지 못하며, 일부 교사는 아예 판단 자체를 제대로 못한다.</p>
      <p>이들의 이야기를 들어보면, 아마도 오래전 규정은 진단서나 처방전을 꼭 제출하도록 했었고, 그래서 필수적으로 받아왔는데, 사회가 변하면서 어느 순간 규정도 현재와 같이 바뀐 것이라고 추정해 볼 수 있다.</p>
      <p>그리고 학교는 예전에 해오던 그대로 계속 진단서와 처방전을 요구하고 있는 상황이라고 보인다.</p>
      <p>앞서 말한 것처럼, 변화를 쫒아가지 못해서 불편함이 발생하고 있는 것이다.</p>
      <p>&nbsp;</p>
      <p>그리고 이들의 대응 방식은 완전히 똑같다.</p>
      <p>혼자 유별나게(?) 행동 하는 것은 부담스럽다는 것이다.</p>
      <p><span className={ss.quo_mini}>‘옳고 그름을 판단하는 것은 나의 일이 아니고, 시키면 시키는 대로 하는 것이 나의 일이다. 관례를 바꾸기 위한 행동은 대단히 위험한 일이고, 나의 책임을 넘어서는 일이다.’</span>라는 것이 그들의 대응 방식이다.</p>
      <p>생각(판단)하는 것은 책임이 따르는 일이고, 책임은 관리자의 몫이며, 고로 나는 생각할 필요가 없다는 것이 오늘날 교사들의 공통된 마인드이다.</p>
      <p>물론 관리자(학교장)는 관리자대로, 판단의 책임(유권해석)은 자신의 것이 아니라 상급기관의 것이라고 여긴다.</p>
      <p>그래서 관리자도 판단 같은 책임이 필요한 행위는 하지 않는다.</p>
      <div className={ss.annotation_wrap}>
        <p><span className={ss.fbody}>“무슨 소리냐? 학교에서 얼마나 많은 판단을 하는데 그런 소리를 하나? 판단 없이 학교가 운영이 되는 줄 아느냐?”</span>라고 항변하는 사람도 있을 것이다.</p>
        <p>물론 아무 판단도 안 하지는 않겠지만, 그들의 판단 대부분은 사실 판단이 아니다.</p>
        <p>상급기관이 만들어준 매뉴얼대로, 해왔던 관례대로 하는 것뿐이다.</p>
        <p>새로운 규정이(정책이) 만들어졌을 때, <span className={ss.bold}>매뉴얼이 없다면, 학교는 아무 판단도 하지 못한다.</span></p>
        <p>&nbsp;</p>
        <p>다시 한 번 말하지만, <span className={ss.bold}>교육부는 관리자 선발 기준에 문제가 있음을 인정해야 한다.</span></p>
        <p>규정을 주고 매뉴얼 없이 해석하고 판단할 수 있는지, 실무에 어떻게 적용하는지 정도는 평가해봐야 한다.</p>
        <p>판단할 수 있는 관리자를 선발하는 것이 불가능하다면, 있을 수 있는 모든 상황, 모든 예외를 감안한 완벽한 매뉴얼을 만들어서 내려 보내라.</p>
        <p>물론 그럴 경우에는 관리자가 아예 필요 없게 되겠지.</p>
        <p>&nbsp;</p>
        <p>판단이 어려운 관리자는, 매뉴얼에 그 내용이 없는 것들에 대해, 변화를 받아들이지 못하고, 창의적으로 운영하지 못하며, 학교 구성원의 의견도 듣지 않을뿐더러, 민원에 대처하지도 못하고, 사건·사고가 발생해도 합리적으로 처리하지 못하며, 가장 결정적인 결격 사유로서 <span className={ss.bold}>안전하지도 않다.</span></p>
        <p>그러므로 <span className={ss.bold}>학생·학부모·교사 모두가 불행해진다.</span></p>
        <p>즉, <span className={ss.bold}>공교육의 불행의 씨앗</span>이고, 스스로의 의도가 아닐지라도 결국 <span className={ss.bold}>만악의 근원</span>이 된다.</p>
        <p className={ss.bold}>가장 중요한 부분을 한 번 더 강조하자면, 결코 안전하지 않다!</p>
      </div>
      <p>&nbsp;</p>
      <p>하지만 공무원에게 일을 시키는 존재는, 원칙대로 따져보면 “규정(주권자의 명령)”이다.</p>
      <div className={ss.annotation_wrap}>
        <p>교육청·학교장·교사에게 일을 시키는 존재는, 교육·학교·공무원과 관련한 법령이다.</p>
      </div>
      <p>규정에 없는 일은 공무원의 일이 아니다.</p>
      <p>그리고 규정이 시켜서 하는 일은, 당연하게도 그 해석이 정확해야 수행할 수 있다.</p>
      <p>그래서 유권해석을 돕는 국가 기관(법제처)도 있는 것이다.</p>
      <p>그러므로 시키는 일을 제대로 하려면 우선 규정의 해석을 올바르게 해야 한다.</p>
      <p>너무나 당연하게도, 이는 첫 번째 업무 수행 조건이며, 업무 수행 공무원 <span className={ss.bold}>자신의 책임</span>이다.</p>
      <p>따라서 위의 3가지 유형의 답변을 한 교사들은 모두, 시키는 대로만 한다는 주장과 달리, 시키는 일도 제대로 하지 못하고 있는 것이다.</p>
      <p>그러니까 관례나 매뉴얼 혹은 상급자에게 책임을 미루는 것이 문제가 아니라, <span className={ss.bold}>판단이 자기 책임임을 인지하지 못하고 있는 것</span>이 근본적인 문제라 할 수 있다.</p>
      <p>&nbsp;</p>
      <p>수년간 여러 학교에서 이런 논쟁을 해야 했던 독도 씨는, 결국 학부모의견서만으로 병결 처리가 가능하도록 규정의 적용을 바로잡을 수 있었다.</p>
      <p>과연 독도 씨는 규정의 해석이 그렇다는 것을 어떻게 교사들이 ‘인정하도록’ 하였을까?</p>
      <p><span className={ss.quo}>“알아도 모른다.”</span>는 그들에게 말이다.</p>
      <p>&nbsp;</p>
      <p>간단하다.</p>
      <p>교육청에 유권해석을 의뢰하여 그 답변을 받았다.</p>
      <p className={ss.quo}>“민원인의 해석이 맞습니다. 학부모의견서만 제출하면 됩니다.”</p>
      <p>독도 씨는 이 답변을 인쇄하여 학교장에게 들고 갔다.</p>
      <p>이때에도 담임교사에게 들고 가면 안 된다.</p>
      <p>상급기관의 유권해석이므로 학교장은 바로 인정하지만, 담임교사는 학교장이 인정해야만 받아들인다.</p>
      <p>답답해도 할 수 없다.</p>
      <p>그렇게 해서 간신히 바꿀 수가 있었다.</p>
      <p>&nbsp;</p>
      <p>어떤가?</p>
      <p>일반인의 상식으로 보면 유치한 거짓말 같은 일이지 않은가?</p>
      <p>그런데 거짓말이 아니다.</p>
      <p>한 학교에서는 한 번 이의 제기해서 바뀌었으나, 다음 해에 다시 원위치 되어 두 번째 이의를 제기해야 했고, 다른 학교에서는 고친 뒤로 학교장이 바뀌는 바람에 다시 이의를 제기해야 했었다.</p>
      <p>이쯤 되면 교육부에서 교육청과 함께 전국 학교에 일괄적으로 지침을 내려서 올바른 적용을 가르쳐야할 듯하다.</p>
      <p>지금처럼 말이 어려우면(!) 안 되고, 가급적 쉽게 설명해서 말이다.</p>
      <p>&nbsp;</p>
      <p>그렇다.</p>
      <p>교사들은 공무원답게, 생각하지 않는다.</p>
      <p>물론 ‘점심때 무얼 먹지?’같은 생각은 한다.</p>
      <p>하지 않는다는 생각이란, 업무상 책임이 따르는 생각을 뜻한다.</p>
      <p>책임이 발생할 수 있는 생각(판단·기획·설계 등)은 상급자(상급기관)가 시키는 경우에만 어쩔 수 없이 한다.</p>
      <p>능동적으로는 전혀 할 마음이 없다.</p>
      <p>또한 그러한 업무를 진행할 때도 자신의 생각을 최소화 하고, 매뉴얼의 예시(남의 생각)를 그대로 따라하거나 기존에 해왔던 관례(기존의 생각)대로만 한다.</p>
      <p>창의적으로는 전혀 할 마음이 없다.</p>
      <p>따라서 규정의 해석이나 업무의 개선을 요구받아도, 그것은 생각(판단·기획·설계 등)이 필요한 일이므로, 상급자의 지시가 아니라면 절대 행하지 않는다.</p>
      <p>옳고 그름은 전혀 중요한 것이 아니다.</p>
      <p>&nbsp;</p>
      <p>능동적이지도, 창의적이지도 않으며, 책임을 회피하고, 옳고 그름도 중요하게 생각하지 않는 교사가 어떻게 학생들에게 자기주도적 삶, 창의적 삶, 책임지는 삶, 올바른 삶을 가르치겠다는 것일까?</p>
      <p>가능한 일일까?</p>
      <p>공교육이 학생들을 창의성과 자기주도성을 갖춘 인재로 성장시키지 못하고, 민주성·공정성·투명성 등을 가르치지 못하고 있는 현실은, 위와 같은 교사의 모습에 따른 당연한 결과이다.</p>
      <p>오늘날 우리 사회가, 무전유죄·유전무죄와 같은 말이 상식이 되어버린, 배금주의를 숭상하는 사회이며, 세계 최저의 출산율과 최고의 자살률을 보유한 완벽에 가까운 <span className={ss.bold}>“헬조선”</span>을 이룩한 데에는 공교육의 역할이 가장 컸다고 생각한다.</p>
      <p>&nbsp;</p>
      <p>지금, 애초에 빼앗긴 적도 없는 교권을 찾아달라고 아우성칠 때인가?</p>
      <p>교원능력개발평가의 폐지를 주장할 때인가?</p>
      <p>우리 아이들의 미래를 위해서, 국가 교육의 정상화를 위해서, 교사 스스로 “교사의 정상화”를 주장하는 것이 먼저다.</p>
      <p>&nbsp;</p>
      <p>애초에 추락한 적이 없는 교권추락에 대한 이야기나, 망가진 학교자치 속에서 가짜로 운영된 교원능력개발평가에 대한 이야기도 뒤에서 자세하게 다룰 예정이다.</p>
      <p>이들 문제의 핵심은 교권이 아니다.</p>
      <p>문제의 핵심은, 적폐 기득권과 그에 종속된 교사, 배제된 학부모, 통제된 학생, 독재하는 교장, 학교민주주의 부재, 가짜 학교자치 등이다.</p>
      <p>잘 연결되지 않는가?</p>
      <p>인내심을 가지고 들어주길 바란다.</p>
      <p>독도 씨가 하고 싶은 이야기는, 아직 10%도 하지 않았다.</p>
      <p>&nbsp;</p>
      <p>참고로, 최근에는 병결 처리 절차를 정상적으로 적용하는 학교가 많아졌다.</p>
      <div className={ss.annotation_wrap}>
        <p>그렇기 때문에 독도 씨의 주장이 근거 없다고 해서는 안 된다.</p>
        <p>몇 년 전까지는 위의 사례가 일반적이었고, 최근 바뀌었다고 해도 문제의 본질에는 전혀 변함이 없기 때문이다.</p>
      </div>
      <p>독도 씨 같은 사람들이 갑자기 많아져서일까?</p>
      <p>아니면 교사들이 갑자기 각성하여 책임감이 강해져서일까?</p>
      <p>그럴 가능성은 적어 보인다.</p>
      <p>아마도 코로나19 유행(pandemic)으로, 한 동안 가벼운 증세에도 무조건 쉬게 하는 등 병결(공결)이 급증하였을 것이고, 당시에는 병원에 가는 것조차도 조심스러웠던 비대면 시기였기 때문에, 행정절차를 간소화하거나 정비하는 과정에서 학부모의견서만으로 병결처리가 되는 절차가 자리 잡은 것으로 보인다.</p>
      <div className={ss.annotation_wrap}>
        <p>정상화란 것이, 불합리한 것을 바꾸려는 노력으로는 이루어지지 않고, 어떤 큰 위기에 처해야만 이루어진다는 것은, 마치 소를 잃어야 외양간을 고치는 것과 같은 맥락이다.</p>
        <p>아니 왜 오래된 말 중에서, ‘군사부일체’와 같이 불필요한 말은 신봉하고, ‘유비무환’ 같은 꼭 필요한 말은 등한시하는가?</p>
        <p>나중에 여러 번 이야기하겠지만, 학생 안전에 최선을 다하겠다고 호들갑을 떨지만, 다른 어느 곳보다 ‘안전불감증’이 만연해 있는 곳도 학교이다.</p>
        <p>사고는 괜히 발생하는 것이 아니다.</p>
        <p className={ss.bold}>생각이 없으니 안전도 없다.</p>
        <p>당신이 학교운영위원이라면, 절대 안전을 믿지 말라.</p>
        <p className={ss.bold}>아이들의 안전을 위해서라면, 당신 스스로 발 벗고 나서야 한다.</p>
        <p>필요하다면 학교장과 싸울 각오도 되어 있어야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>한 가지 빠진 말이 있는데, 지금까지 이야기한 학업성적관리규정은 각 학교에서 만들어 시행하는 규정이다.</p>
      <p>다만, 많은 조항이 상위 법령이나 교육청 지침에 의해 구체적으로 제한되므로, 단위 학교에서 자율적으로 규정할 수 있는 부분은 한정적이다.</p>
      <p>하지만 어쨌든 이 규정은 단위 학교의 학업성적관리위원회(학교장과 교사)에서 개정(심의)한다.</p>
      <p>본인들이 만든 규정의 해석은 본인들의 책임이다.</p>
      <p>지침의 내용을 잘 모르겠으면 법제처나 교육청에 문의해서라도 확인을 해야 한다.</p>
      <p><span className={ss.quo}>“나는 모른다.”</span>라고 말하려면, 학생들이 숙제를 제대로 해왔는지 검사하는 것도 하지 말아야 한다.</p>
      <p>자기가 한 일(숙제)의 내용도 제대로 모르는 사람들이, 숙제를 검사할 자격이 있는가?</p>
      <p>매뉴얼을 그대로 베껴서 규정을 만들고, 그렇게 만든 규정의 내용도 제대로 파악하지 못하면서, 무슨 자격으로 학생의 수행평가 내용이 베낀 내용인지 창작인지를 평가한단 말인가?</p>
      <p>&nbsp;</p>
      <p>도대체 이런 이야기를 하는데, 교사가 아닌 독도 씨가 왜 낯부끄러운 것일까?</p>
      <p>참을 수 없이 부끄럽다.</p>
      <p>아마도, <span className={ss.bold}>국가 교육의 수준이 우리 사회의 수준을 결정</span>하기 때문일 것이다.</p>
      <p>우리 사회의 수준이 우리 정치의 수준일 것이고, 우리 정치의 수준에 따라 우리의 미래가 결정되기 때문일 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>생각하지 않는 사례로서 역시나 심각한 ‘예시’의 사례가 있는데, 지면이 너무 길어져서 다음 기회에 설명하겠다.</p>
        <p>또한, 규정의 해석이라는 앞의 논점과는 별개로, 소위 ‘부당한 결석’을 막겠다는 취지에서 처방전을 요구하는 교사도 있는데, ‘부당한 결석’과 관련한 논쟁도 뒤에 ‘생리공결’과 관련하여 따로 상세히 다룰 예정이다.</p>
        <p>그리고 상급기관의 지침과 다르게, 처방전이 꼭 있어야 한다고 규정에 못 박은 학교의 사례(상위 규정 위반)는 ‘학교의 잘못된 규정’을 다룰 때 설명하겠다.</p>
      </div>
      <p>&nbsp;</p>
      <p>다른 이야기가 또 길어졌다.</p>
      <p>이제 다시 독도 씨 이야기로 돌아가자.</p>
      <p>&nbsp;</p>
      <p>독도 씨의 설명을 듣던 밤톨 엄마가, 독도 씨는 잘 모를만한 이야기를 꺼냈다.</p>
      <p className={ss.quo}>“그런데 참고로 사춘기 여자애들은 먹는 거에 신경 많이 써. 안 그런 아이도 있겠지만 신경 쓰는 애들이 더 많을 거야. 다이어트를 하는 아이들도 있고, 집 밖에서는 많이 먹는 걸 부끄럽게 생각하는 아이들도 있어. 특히 남자애들하고 같이 있으면 그래. 그래서 배가 고픈데도 일부러 적게 먹고 내색을 안 하는 경우가 꽤 있다고. 뭐, 결코 바람직한 일은 아니야. 잘 먹어야 건강해지니까. 어쨌든 그래서 여학생의 엄마들 중에서는 급식의 양에 대해 불만이 있는 엄마들이 거의 없을 거야. 집에서는 솔직하게 배가 고프다고 말할지언정, 학교를 상대로 부족하다고 말하는 것은 창피하다고 생각할 수 있으니까.”</p>
      <p>확실히 독도 씨는 여학생의 학교생활에 대해서는 잘 알지 못했다.</p>
      <p>그러나 운영위원이라면 학생들의 학교생활에 보다 깊은 관심을 가져야 하기 때문에, 독도 씨는 밤톨 엄마의 이야기를 주의 깊게 들었다.</p>
      <p className={ss.quo}>“흠, 그럴 수도 있겠구나. 흥미롭네. 남학생들에게 적게 주는 만큼 여학생들에게는 많이 주는 셈이라서 안 그래도 남을 텐데, 다이어트 한다고 자기 정량보다 더 적게 먹으려하면 더욱 남겠네. 잔반이 넘치겠어. 하긴 주는 대로 다 먹으면 과할거야, 남기는 게 나을 수도 있어. 일단 당신 말은 참고할게, 분석할 때 고려해야할 필요가 있는 부분인 것 같아.”</p>
      <p>&nbsp;</p>
      <p>독도 씨는 이어서 밤톨 엄마에게 다른 질문을 던졌다.</p>
      <p className={ss.quo}>“그런데 여보. 그러니까 남자애들 사이에서 급식의 양이 적다는 이야기가 꽤 나왔다는 거잖아?”</p>
      <p className={ss.quo}>“민철 엄마 이야기에 따르면 그렇지.”</p>
      <p className={ss.quo}>“그러면 보통 학부모회에서 문제 제기를 하지 않나?”</p>
      <p className={ss.quo}>“아까 민철 엄마랑 그런 이야기도 했어. 공교롭게도 학부모회 임원이나 대의원 중에서 여론을 주도하는 학부모들이 대부분 여학생들 엄마인데, 이 사람들이 자기 일이 아니다보니 적극적으로 나서지 않는대. 그러다보니 상대적으로 목소리가 작은 쪽에서는 적극적으로 주장을 못하고 있는 형편인데다, 아까도 얘기했지만 일부 사람들은 어쩔 수 없는 일이라며 애초에 문제 삼지도 않는 분위기이고. 사실, 아예 급식을 굶는 것도 아니고 애들이 집에 오면 뭐라도 먹이면 그만이니까…….”</p>
      <p>그러니까 사실 학부모회에서는 이미 급식의 양이 적다는 여론을 인지했다는 것이고, 그럼에도 불구하고 이에 대해 학교에 이의를 제기하지 않고 있다는 이야기였다.</p>
      <div className={ss.annotation_wrap}>
        <p>이와 같은 일은 종종 발생한다.</p>
        <p>일부 그룹에서 큰 논란이 발생해서 이슈가 되어도, 다른 그룹에서는 마치 강 건너 불구경하듯 신경 쓰지 않는다.</p>
        <p>2019년에 밤톨이가 다녔던 고등학교에서, 여학생 화장실에 다른 학교의 남학생 한 명이 숨어 있다가 발견된 사건이 있었는데, 당시 많은 여학생이 극심한 정신적 충격으로 화장실을 가지 못할 정도의 불안과 공포에 시달렸기 때문에, 이 일의 처리와 관련해서 한 동안 학교가 크게 몸살을 앓았다.</p>
        <p>당시 여학생의 학부모들은 강경한 자세로 단호하게 사태의 해결(철저한 조사, 처벌, 재발 방지 등)을 요구하며 발 벗고 나섰으나, 남학생의 학부모들은 대개 대수롭지 않게 생각했으며, 일부의 경우 오히려 여학생들이 너무 심하게 유난스러운 것 아니냐며 괜히 학교만 시끄럽게 한다는 불만 섞인 분위기마저 내비치기도 했다.</p>
        <p>&nbsp;</p>
        <p>어떤 이는 이러한 양쪽의 다른 반응을 재미있다며 가볍게 웃어넘겼는데, 독도 씨는 가볍게 생각하지 않았다.</p>
        <p>매우 참혹하고 참담한 일이라고 생각했다.</p>
        <p>세상을 살아가면서 다른 사람들의 일에 전부 관심을 가질 필요는 없다.</p>
        <p>그럴 수도 없다.</p>
        <p>하지만 자신이 소속된 집단(가족, 학교, 직장, 마을, 국가 등)의 일이라면 관심을 가져야 한다.</p>
        <p>집단에서 발생한 문제는 집단을 위험(위기)에 빠뜨릴 수 있다.</p>
        <p>집단의 역량이 감소하고 집단의 질서가 무너지면 집단은 소멸(해체)의 길로 접어들고, 구성원들은 더 이상 집단(사회)의 보호를 받을 수 없다.(정치 부패와 사회 불안 등으로 국력의 지속적인 약화가 가져온 일제강점기를 떠올려보면 알 수 있다. 그 피해는 고스란히 국민 개개인이 떠안았다.)</p>
        <p>당장에 나하고 관련이 없다고 해서 집단에서 발생한 문제를 외면하면, 자칫 집단이 큰 위기에 빠지고 그로인해 결국 자신에게 커다란 위해가 발생할 수 있다.</p>
        <p>가족이 해체되고, 회사가 폐업하고, 지자체가 도산하고, 국가가 침략 당하는 일이 발생할 수 있다.</p>
        <p>심한 경우 나의 생존(존엄)이 위태해질 수 있다.</p>
        <p>그러므로 학교의 구성원들이, 학교에서 문제가 발생했을 때, 내 일과 남의 일을 구분하여 대응하는 것은 어리석고 잘못된 행동이라고 할 수 있다.</p>
        <p>&nbsp;</p>
        <p><span className={ss.fbody}>“남학생들은 배고프다고 하지만, 우리 딸은 괜찮다고 하니 솔직히 나는 별 관심이 가지 않아.”</span>라고 말하는 것은 주권이나 민주주의의 개념이 희박하기 때문에 생기는 현상인데, 특히 학교운영위원이나 학부모회 임원이 그렇게 행동하는 것은 더더욱 큰 잘못이다.</p>
        <p>이는 독도 씨가 <span className={ss.bold}>“모든 아이를 바라보는 것이 내 아이를 위한 것인데, 내 아이만 바라보느라 그것을 놓치는구나.”</span>라고 한탄한 것과 같은 맥락이다.</p>
        <p>끔직하고 두려운 일이 아닐 수 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>독도 씨는 학부모회의 대처에 깊은 분노를 느꼈다.</p>
      <p className={ss.quo}>“하! 아니, 자기 일이 아니라고? 이건 다 우리 일이야. 어떻게 내 일과 남의 일로 구분해? 그런 사람은 학부모회에서 일할 자격이 없어.”</p>
      <p className={ss.quo}>“그거야 당신 생각이고. 다들 자기 자식들이 중요하지 남의 자식들이 중요한가? 뭐든 학교에 따지는 일은 부담스러우니까 자기 자식일이 아니면 쉬쉬하는 거지. 내가 누누이 말하지만 <span className={ss.bold}>당신 같은 사람은 없어.</span> 세상이 당신 생각대로만 움직인다면 벌써 남북한이 통일되고도 남았을 걸?”</p>
      <p>밤톨 엄마의 가벼운 비꼼은 자주 있는 일이었다.</p>
      <p className={ss.quo}>“아아, 됐어. 여기까지! 그만 하자고.”</p>
      <p>독도 씨는 지금까지의 생각을 정리하기 위해 그만 대화를 끝냈다.</p>
      <p>&nbsp;</p>
      <p>며칠 전, 한비중학교 학교홈페이지의 급식 게시판에서 내려 받은 ‘급식 만족도 조사 결과’를 살펴본 결과, 독도 씨는 개선이 필요한 몇 가지 문제점이 있음을 발견하였다.</p>
      <p>그 중에서도 학생들의 불만이 가장 많았던 ‘급식의 양’이 부족한 문제의 원인을 찾고자 고민하였는데, 마침 같은 급식 게시판에 ‘2015학년도 학교급식운영계획(안)’이 올라와 있는 것을 발견하고 이를 내려 받아 읽어보았다.</p>
      <p>이 문서에는 급식관련 법령인 「학교급식법」이 언급되어 있었고, 또한 「학교급식법 시행규칙」의 별표3에 규정된 ‘학교급식의 영양관리기준’에 따라 학교급식이 운영되고 있음을 알게 되었다.</p>
      <p className={ss.quo_mini}>‘아, 급식도 법령에 따라 운영되고 있구나. 알아야할 것이 정말 많네.’</p>
      <p>&nbsp;</p>
      <p>그리고 곧, ‘학교급식의 영양관리기준’이 문제의 핵심임을 깨닫게 되었다.</p>
      <p className={ss.quo_mini}>‘이게 문제였네. 이렇게 정해주었는데 그대로 안 하는 게 잘못된 거야. 빙고! 법령 위반을 찾았어. 그러니까 배가 고픈 거지.’</p>
      <p>단순히 성장기라서 배가 고픈 것이 아니라, 학교가 밥을 적게 주고 있어서 배가 고픈 것이라는 증거를 발견한 것이다.</p>
      <p>&nbsp;</p>
      <p>밤톨 엄마와의 대화를 마친 뒤, 독도 씨는 증거를 보강하기 위해, 또 다른 근거를 찾는 작업에 착수했다.</p>
      <p>부종 시 내에 있는 중·고등학교 50개교의 모든 학교홈페이지를 샅샅이 뒤져서 각 학교의 급식 만족도 조사 결과를 내려 받아 이를 낱낱이 분석하기 시작하였다.</p>
      <div className={ss.annotation_wrap}>
        <p>약 3분의1에 해당하는 학교의 홈페이지에는 해당 자료가 없거나 비공개로 되어 있어서, 자료를 입수하는 것이 불가했다.</p>
        <p>이런 자료를 비공개로 할 이유가 있을까?</p>
        <p>학교의 홈페이지 운영에도 너무나 많은 심각한 문제가 산적해 있다.</p>
        <p>문제는 세상 어디에나 있을 수 있지만, <span className={ss.bold}>학교는 모든 게 문제라는 치명적인 문제를 가지고 있다.</span></p>
      </div>
      <p>&nbsp;</p>
      <p>이 작업에는 상당한 시간이 소요되었다.</p>
      <p>며칠을 꼬박 할애하여 자료를 내려 받고 정리하고 분석하였다.</p>
      <p>그리하여 충분한 근거를 확보하고 결론을 내릴 수 있었다.</p>
      <p>그리고는 곧이어 개최될 학교운영위원회 회의에서 이 문제를 논의를 할 수 있도록, 분석 결과를 토대로 ‘급식량 문제 제기’라는 문서를 작성하였다.</p>
      <div className={ss.annotation_wrap}>
        <p>훗날 이 문서는 독도 씨가 ‘급식소위원회 역량 연수’ 강의 시에 활용하였는데, 이때에도 남학생 부모들은 많은 관심을 보였으나, 여학생 부모들은 별로 관심을 보이지 않았다.</p>
        <p>사람들은, 적게 주는 것은 문제라고 생각하는데, 많이 주는 것도 문제라는 사실은 잘 인지하지 못한다.</p>
        <p>남으면, 버리면 그만이라고 생각하는 경향이 있다.</p>
        <p>하지만 잔반은 자원의 낭비, 예산의 낭비, 환경의 오염, 때에 따라서는 위생의 문제까지 불러일으키는 학교급식의 핵심 문제점 가운데 하나다.</p>
        <p>따라서 혹여 여러분이 여학생이거나 여학생의 가족이라 할지라도, 부디 이 문제에 관심을 가져주기를 간절히 바라는 바이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>독도 씨는 한비중학교의 학교운영위원으로써 두 차례의 큰 논쟁(!)을 겪었는데, 그 중의 하나가 바로 이 급식 논쟁이다.</p>
      <div className={ss.annotation_wrap}>
        <p>두 번의 논쟁은 매우 치열하였고, 매 논쟁은 독도 씨에게 깊은 상처(분노와 좌절)를 주었으며, 논쟁의 끝은 항상 노골적인 따돌림이었다.</p>
      </div>
      <p>&nbsp;</p>
      <p>10여 년이 흐른 지금도, 독도 씨는 자신의 계산이 틀리지 않았다고 확신한다.</p>
      <p>이제 다음 장에서는 독도 씨가 문제를 추적해갔던 흐름을 따라, 한비중학교의 급식 만족도 조사 결과, 「학교급식법」 등 관련 법령, 타 학교의 급식 만족도 조사 결과를 살펴보도록 하자.</p>
      <p>그리고 급식 논쟁이 벌어진 한비중학교 학교운영위원회의 그 두 번째 회의를 지켜보자.</p>
      <p>여전히 해결되지 않은, 여전히 은폐되어 있는 ‘아이들이 배고픈 이유’, 그리고 왜 생각이 없는 것이 문제가 되는지를 모두 확인해 보자.</p>
    </div>
  )
}

export default Year2015_24