import React from 'react'
import ss from './MainText.module.css'

const Year2015_25 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>25. 급식 논쟁(1).</h1>
      {/* <p className={ss.bold}>PART 1.</p>
      <p>&nbsp;</p> */}
      <p>처음에 학교홈페이지의 급식게시판에서 ‘2015학년도 급식 만족도 조사’라고 게시 되어 있는 글을 발견했을 때, 독도 씨는 급식의 양에 대한 학생들의 의견을 알 수 있을 것이라는 기대를 했다.</p>
      <p className={ss.quo_mini}>‘구체적으로 무엇을 어떻게 조사했는지는 봐야 알겠지만, 급식이 만족스러운지 아닌지 조사하는 것이 목적이니까, 많은 학생들이 급식의 양이 적다고 느꼈다면 어떻게든 조사 결과에 나타났을 거야.’</p>
      <p>물론 그전까지만 해도, 독도 씨는 급식 만족도 조사라는 것이 있다는 것도 몰랐다.</p>
      <p>따라서 그 문서 안에 어떤 내용이 있을지 알지 못했다.</p>
      <p>&nbsp;</p>
      <p>그런데 읽어본 결과, 기대 이상이었다.</p>
      <p>그 안에서, 독도 씨는 짐작했던 것 보다 훨씬 더 많은 것을 확인할 수 있었다.</p>
      <p>&nbsp;</p>
      <p>한비중학교의 ‘2015학년도 급식 만족도 조사’는, 3개 학년(전체 29학급)에서 4개 학급씩 총 12개 학급(399명)을 선정하여, 설문조사를 실시하고 그 결과를 기록한 문서였다.</p>
      <p>&nbsp;</p>
      <p>설문의 항목은 아래와 같았다.</p>
      <p className={ss.circle_num}>① 학교급식에 만족합니까?</p>
      <p className={ss.circle_num}>② 불만족하였다면 그 이유는 무엇입니까?</p>
      <p className={ss.circle_num}>③ 급식에서 제공하는 음식의 양은 적당합니까?</p>
      <p className={ss.circle_num}>④ 급식에서 주로 양이 부족한 음식은 무엇입니까?</p>
      <p className={ss.circle_num}>⑤ 급식에서 제공하는 음식의 간은 적당합니까?</p>
      <p className={ss.circle_num}>⑥ 평상시 여러분의 잔반은 어떻습니까?</p>
      <p className={ss.circle_num}>⑦ 주로 남기는 음식은 무엇입니까?</p>
      <p className={ss.circle_num}>⑧ 음식을 남기는 이유는 무엇입니까?</p>
      <p className={ss.circle_num}>⑨ 아침식사를 하고 등교합니까?</p>
      <p className={ss.circle_num}>⑩ 우리 학교는 부종시의 친환경쌀과 진부도의 우수축산물을 사용한 급식을 하고 있다는 것을 알고 있습니까?</p>
      <p>&nbsp;</p>
      <p>설문의 항목을 보면, 이슈(issue), 즉 급식에 있어서 주요 관심거리가 무엇인지 알 수 있다.</p>
      <p>위 설문조사에서는 ‘만족도, 음식의 양과 간, 잔반’이 핵심 이슈임을 알 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>이러한 설문조사 항목은 학교마다 조금씩 다르다.</p>
        <p>한비중학교 설문조사 항목에는 없지만, 음식의 맛이나 위생(청결), 서비스(친절함), 식단의 구성(기호도) 등도 중요하게 묻는 항목이며, 좀 더 세세하게 음식의 온도가 알맞은지, 기본 반찬인 김치의 맛이 좋은지를 묻는 학교도 있다.</p>
        <p>또한 교육급식의 일환으로 학교급식의 필요성, 영양의 균형과 건강한 식습관(편식 지양)의 필요성, 잔반 줄이기의 필요성 등을 인지하고 있는지, 바람직한 급식문화와 관련하여 학교의 교육(지도)과 학생들의 실천이 잘 이루어지고 있는지 확인하는 항목을 넣는 학교도 있다.</p>
        <p>독도 씨가 부종시 32개교(중·고교 50개 중 자료 입수가 가능했던 학교. 나머지 학교는 학교홈페이지에서 자료를 찾을 수 없거나 자료가 비공개였다)의 3년 치 급식 만족도 조사 자료를 분석한 결과로 알아낸 바에 의하면, 질문이 구체적이고 질문에서 성의가 느껴지는 학교일수록 만족도가 높거나, 불만의 개선이 잘되고 있었다.</p>
        <p>반대로 질문이 형식적이고 몇 개 되지 않는 학교의 경우, 만족도가 낮음에도 불구하고 개선이 이루어지지 않고 있었다.</p>
        <p>이는 <span className={ss.bold}>설문조사의 질문 항목에서부터 급식 개선 활동이 출발되어야 함</span>을 시사한다.</p>
        <p>&nbsp;</p>
        <p>한편, 만족도 조사의 질문 항목은 단위 학교에서 학교 구성원들이 자율적으로 만들 수 있다.</p>
        <p>이상적으로는 학생회, 학부모회, 교사협의회(교사는 자신의 돈을 내고 급식을 사먹는다. 급식의 질 향상에 참여할 권리가 있다)와 영양교사, 학교장 등이 협의하여 항목을 결정하는 것이 바람직하다.(평상시 급식실 건의함이나 학교홈페이지 급식게시판을 상시 운영하면서, 설문에 추가하고 싶은 항목을 적어달라고 하고, 이를 반영하여 설문지를 만드는 것이 가장 쉬운 방법이다.)</p>
        <p>하지만 협의하여 결정하기 번거롭다면, 학교급식에 대체적으로 만족하고 있다는 전제하에, 영양교사의 판단으로 기존의 설문조사 항목이나 교육청 예시나 타 학교의 사례를 그대로 하여 시행해도 무리는 없다.</p>
        <p>물론, 급식이 불만족스러운데 설문조사를 통해서도 개선방법이 도출되지 않는다면, 그때는 공동체의 의견을 물어서 항목을 조정해야할 필요가 있다.</p>
        <p>묻는 방법에 따라 결과는 다르게 나올 수 있으며, 좋은 질문은 이미 개선책을 포함하고 있을 수도 있기 때문이다.</p>
        <p>&nbsp;</p>
        <p>참고로 진부도교육청이 말하는 교육급식이란, ‘학생이 학교급식을 통해 건강한 식생활을 실천하고, 공동체에서의 배려와 책임을 배우며, 공동체의 문제해결력을 키워가는 다양한 교육활동’을 의미한다.</p>
        <p>지속가능하고 건강한 삶을 위해서는 건강한 식생활이 최우선으로 필요하다.</p>
        <p>교육급식은, 급식이 단지 한 끼 영양을 해결하는 과정이 아니라, 급식을 통해 영양과 건강과 바람직한 식습관을 배우고, 자신의 몸(구성요소, 체질, 알레르기 등)에 대해 알게 되고, 공동체 생활에서의 배려와 질서의 중요함을 배우며, 환경의 중요성과 위기와 해결책을 고민하는 등, 과학과 문화 전반에 걸쳐 학습하고 배운 것을 실천해 나아가는 중요한 교육과정이라는 것을 강조하는 단어이다.</p>
        <p>단순히 차려주는 식단에 따라 음식을 소비하는 수동적 학생이 아니라, 식재료 선정, 조리, 배식, 식사, 퇴식 등 급식의 전 과정을 이해하고, 필요하다면 그 과정에 적극적으로 참여하는 능동적이고 책임 있는 주체로 성장시키려는 것이 교육급식의 목적이다.</p>
        <p>&nbsp;</p>
        <p>하지만 진부도교육청이 강조하고 있고, 매스컴을 통해 홍보도 열심히 하며, 실제로도 반드시 필요하다고 할 수 있는 교육급식의 학교 내 정착은 지지부진하다.</p>
        <p>2016년부터 실시한 자율배식 도입 정책은 교육급식의 처음이자 마지막이라고 할 수 있는, 교육급식을 위한 핵심 정책이자 게임 체인저(Game Changer)라고 할 수 있는데, 정책 도입 후 10년 가까이 지났으나 자율배식을 실시하는 학교는 진부도 내 전체 초‧중‧고 학교의 10% 수준인 250여개에 그치고 있다.(나중에 언급하겠지만, 독도 씨가 진부도교육청에 급식의 문제(영양관리기준 등)로 민원을 제기했을 때, 독도 씨의 핵심 주장이 바로 학교급식을 모두 자율배식으로 바꿔야 한다는 것이었다.)</p>
        <p>그렇다고 자율배식 없이도 교육급식이 잘되고 있는 학교가 많이 있는가하면, 그렇지도 않다.(자율배식이 아니라면 영양관리기준을 충족시키기 어렵다. 영양 균형이 무너진 급식은, 다른 것을 아무리 잘해도 교육급식이 될 수 없다.)</p>
        <p>여전히 학교급식에 대한 만족도는 10년 전과 비교할 때 별 차이가 없다.</p>
        <p>교육급식은 급식의 만족도를 개선시키려는 공동체의 노력을 포함하므로, 급식 만족도의 개선이 지지부진하다면, 교육급식이 이루어지지 않고 있다고 판단하면 된다.</p>
      </div>
      <p>&nbsp;</p>
      <p>1. 첫 번째 이슈, 만족도.</p>
      <p>먹는 행위는 생명을 유지하기 위한 가장 기본적인 행위이고, 음식은 우리 몸의 신체를 구성하는 물질이다.</p>
      <p>“밥”(음식)은 생명이고 “나”의 물질적 정체성이다.</p>
      <p>매일 먹는 밥이라서 그 중요성을 잊고 살기 마련이지만, 밥이 곧 건강이고, 건강은 가장 중요한 행복의 요소이다.</p>
      <p>특히 성장기의 아동청소년에게 밥은 성장에 필요한 가장 중요한 요소이자 필수 요소이다.</p>
      <p>통상 하루 3끼를 먹는다고 할 때, 학생은 학교에서 점심 한 끼를 먹기 때문에, 학교급식은 그 중요한 밥 먹는 행위의 3분의 1을 매일 제공하고 있는 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>연간 급식제공 횟수는 190회 안팎이다.</p>
        <p>보편적으로 연간 식사 횟수는 아침까지 잘 챙겨 먹는다고 할 때, 365일 1,095회 이다.</p>
        <p>따라서 ‘아동청소년의 성장을 견인하고 몸을 구성하는 전체 식사’의 약 17.4%를 학교급식이 담당하고 있다.</p>
        <p>그러므로 당신(혹은 당신 자녀)이 ‘초등학교에 입학해서 성인이 될 때’까지 만들어진 당신 신체의 6분의1 이상은 학교에서 제공한 것이다.</p>
        <p>계산해보면 당신이 고등학교를 졸업했을 때 기준으로 대략 5~7kg에 해당하는 당신의 몸(뇌, 신경, 혈관, 뼈, 근육, 장기, 피부 등)의 일부를 학교에서 만들어준 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그러므로 급식은 학부모와 사회의 관심을 끄는 대상일 수밖에 없다.</p>
      <p>눈이 휘둥그레 해지는, 감탄사가 절로 나오는 급식 사진이 화제가 되며, 부실한 급식 사진은 사회의 공분을 일으킨다.</p>
      <p>이렇게 중요한 급식의 평가 방법에는 여러 가지가 있을 수 있지만, 종합적인 판단은 급식의 만족도가 알려 주므로, 결국 만족도가 가장 중요한 이슈라고 할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>우리가 흔히 상품이나 서비스를 구매하고 남기는 후기(만족도)는 해당 상품의 질을 판단하는 바로미터이다.</p>
        <p>충분한 데이터(후기)가 축적된 경우, 만족도는 상품으로서 소비자가 요구하는 조건(품질, 가격, 서비스 등)을 제대로 충족하고 있는지를 매우 정확하게 보여준다.</p>
        <p>얄팍한 눈속임은 통하지 않으며, 상품이 개선되어야할 방향까지도 제시한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그렇다면 한비중학교의 급식 만족도는 얼마나 될까?</p>
      <p>이를 알아보기 전에 우선 급식 만족도는 어떻게 산출하는 것이 적당한지부터 알아보자.</p>
      <p>자칫 이현령비현령(귀에 걸면 귀걸이, 코에 걸면 코걸이)이 될 수도 있기 때문이다.</p>
      <p>&nbsp;</p>
      <p>만족도를 산출하기 위해, ‘만족 여부에 답하는 선택지’는 만족의 정도를 일정 구간으로 나누기 마련인데, 4구간(매우만족, 만족, 불만, 매우불만)이나 3구간(만족, 보통, 불만)으로 나누는 학교도 있으나, 5구간(매우만족, 만족, 보통, 불만, 매우불만)으로 나누는 학교가 가장 많다.</p>
      <p>보편적으로, 인터넷 쇼핑몰이나 SNS에서 상품이나 작품, 영업점 등에 대한 후기를 점수로 표현할 때, 가장 많이 사용하는 방식이 5개의 별(★)을 사용해 점수를 매기는 방식인데, 표시 형식만 다를 뿐 내용은 둘 다 같다.</p>
      <p>&nbsp;</p>
      <p>그런데 모든 사람들의 만족도를 취합하여 하나의 결과(수치)로 표시할 때, 별점의 경우는 5점 만점의 숫자(평점)로 표현을 한다.</p>
      <p>별 1개를 1점으로 계산하여, 4개와 5개를 받으면 평균이 4.5개이므로 4.5점으로 표시한다.</p>
      <p>대개 4점 이상이면 소비자의 평균이 만족했다는 뜻으로 받아들여진다.</p>
      <div className={ss.annotation_wrap}>
        <p>이를 100점 만점으로 환산하려면 20을 곱하면 된다.</p>
        <p>5점 척도를 100점으로 환산하는 방법에는 여러 가지가 있는데, 여기서는 매우만족은 100점, 만족은 80점, 보통은 60점, 불만은 40점, 매우불만은 20점으로 계산하기로 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>반면에 급식 만족도 결과를 이렇게 5점 만점의 숫자로 표현하는 학교는 거의 없다.</p>
      <p>최소한 부종시 내에서는 하나도 없었는데, 아마도 진부도를 포함하여 전국 대부분의 학교에서 모두 그러하리라 짐작된다.</p>
      <p>학교급식 만족도는 대개 ‘보통 이상 몇 %’라고 비율로 표현한다.</p>
      <p>이렇게 표현하는 이유는, 만족 비율만 표현하는 것보다 보통을 포함하는 것이 수치가 높게 나타나기 때문이다.</p>
      <p>예를 들어, 매우만족 0%, 만족 8%, 보통 72%, 불만 2%, 매우불만 18%로 집계된 만족도 조사가 있다고 하자.</p>
      <p>언뜻 봐도 이 학교의 급식은 별로 만족스럽지 못하다.</p>
      <p>그럭저럭 보통 수준인 것이다.</p>
      <p>이때, ‘만족 8%, 보통 72%’라고 표현하는 것보다 ‘보통 이상 80%’라고 표현하는 것이 훨씬 더 긍정적으로 받아들여진다.</p>
      <p>이처럼 ‘만족’과 ‘보통’을 합쳐서 발표하면, ‘보통’은 만족과 비슷하게 인식되어 만족스러운 결과로 포장된다.</p>
      <p>하지만 이 결과를 위의 별점 산출하듯 점수로 계산하여 표시하면 2.7점이 된다.</p>
      <p>당신이 맛집을 찾고 있다면, 아마 2.7점짜리 식당에는 절대 가지 않을 것이라 확신한다.</p>
      <div className={ss.annotation_wrap}>
        <p>100점 만점으로 환산하면, 2.7점은 54점으로 환산된다.</p>
        <p>산출하는 방법에 따라 낙제점(60점미만)이 되기도 하고, 80%가 그럭저럭 만족한다는 결과도 될 수 있으니, 학생의 급식 만족 여부는 산출 방법에 따라 이현령비현령이라 할 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>물론 다소 극단적인 사례를 가정하였지만, 보통을 만족에 포함하여 마치 만족한 비율인 것처럼 발표하는 것은 ‘눈 가리고 아옹’일 수 있다는 이야기이다.</p>
      <p>그렇다면 어떻게 하는 것이 좋을까?</p>
      <p>비율이 아닌 점수로 계산하여 발표하는 것이 현실을 파악하는데 있어서 더 나은 방법이라고 판단된다.</p>
      <div className={ss.annotation_wrap}>
        <p>보통과 만족을 합쳐서 비율로 발표하는 것은, 공공기관이 주로 사람들의 눈과 귀를 속이기 위해 사용하는 대표적인 집계 방식이다.</p>
        <p>더 이상 눈 가리고 아옹 하는 일이 없도록 학교 발표를 그대로 받아들이지 말고, 학부모회 등에서 따로 계산하여 냉정하게 판단하도록 하자.</p>
      </div>
      <p>&nbsp;</p>
      <p>이제, 한비중학교의 만족도를 살펴보자.</p>
      <p>문서에는, 매우만족 11%, 만족 36%, 보통 33%, 불만 13%, 매우불만 7%로 집계되어 있었고, 결과분석 페이지에서 영양교사는, <span className={ss.quo}>“보통 이상의 응답이 80%입니다. 앞으로 만족도를 더 높이기 위해 한층 더 노력하겠습니다.”</span>라며, 마치 만족도가 괜찮은 수준이라는 듯이 표현하고 있었다.</p>
      <p>그런데 불만족(매우불만과 불만)이 20%이면, 5명 중 1명은 급식에 불만이 있다는 이야기이므로, 이는 적지 않은 수이다.</p>
      <p>또한 그럭저럭 보통이라는 수치를 제외하면, 급식에 만족하는 학생은 47%로 절반이 되지 않는다.</p>
      <p>이를 위에서 본 것처럼, 점수로 표시하면 5점 만점에는 3.31점이고, 100점 만점에는 66.2점이다.</p>
      <p>낙제를 간신히 면한 점수이다.</p>
      <p>보통 이상이 80%라고 당당하게 말할 상황은 아니다.</p>
      <p>&nbsp;</p>
      <p>그리고 이 수치는 인근의 학교에 비교해서도 높지 않은 수치다.</p>
      <p>독도 씨가 집계한 관내 17개 중학교의 만족도 평균은 3.58점으로, 이를 100점 만점으로 환산하면 71.6점이다.</p>
      <div className={ss.annotation_wrap}>
        <p>참고로 보통을 만족에 포함하여 각 학교가 발표한 만족도의 평균은 82.9%로 71.6점과 비교하면 차이가 상당하다.</p>
      </div>
      <p>한비중학교의 점수는 평균보다 아래에 있었으며, 17개 중학교 중에서는 15위에 해당하는 점수였다.</p>
      <div className={ss.annotation_wrap}>
        <p>가장 높은 만족도를 보여준 학교는 4.3점이었고, 가장 낮은 만족도의 학교는 3.1점이었다.</p>
        <p>각 학교가 발표한 만족도 비율로 보면 각각 97%와 67%였다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그런데 여기서 잠깐 생각해 보아야할 것이 있다.</p>
      <p>적정 만족도 수치는 과연 얼마일까?</p>
      <div className={ss.annotation_wrap}>
        <p>적정 수치는 달성해야할 최소한의 목표 수치를 의미한다.</p>
      </div>
      <p>기준이 있어야 문제를 가늠하기가 쉬운 법이다.</p>
      <p>&nbsp;</p>
      <p>독도 씨가 가끔 학교급식 만족도에 관해 이야기를 할 때, 많은 사람들이 쉽게 이해하고 동의했던 방식은 평균을 기준으로 하는 방식이었다.</p>
      <p>이 방식은, 비슷한 수준의 학교 평균이나 인근 학교의 평균 수치를 적정 수치라 설정하고, 그 수치를 기준으로 우리 학교의 수준을 확인하여 좋거나 부족함을 판단하는 가장 보편적인 방식이다.(비슷한 수준이란 학생 수, 교육환경, 교육과정 등이 비슷한 것을 말한다.)</p>
      <div className={ss.annotation_wrap}>
        <p>하지만 평균을 기준으로 삼는 것은 상대적인 개념이므로, 만족도의 적정성을 평가하는 데는 적합하지 않을 수 있다.</p>
        <p>예를 들자면, 충분히 만족할만한 급식인데도 평균 이하라는 이유로 비판받을 수 있으며, 반면에 형편없는 급식인데도 평균 이상이라는 이유로 개선 노력을 하지 않을 수 있다.</p>
        <p>모두가 다 같이 좋을 수도 있고, 모두가 다 같이 나쁠 수도 있으므로, 평균은 참고할 수치이지 기준으로 삼을 수치는 아니다.</p>
      </div>
      <p>다른 방식으로는, 대표적인 학교를 기준으로 하는 것이 있다.</p>
      <p>지역 내 가장 유명한 학교, 급식이 괜찮다고 소문난 학교, 반대로 급식이 나쁘다고 소문난 학교 등 지표가 되는 특정 학교를 기준으로 삼아 우리 학교의 수준을 비교하고 달성 목표를 설정하는 방식이다.</p>
      <p>평균을 기준으로 하거나 특정 학교를 기준으로 하는 것은, 둘 다 비교 대상이 기준이 되는 방식이다.</p>
      <p>그러나 이와 같은 상대적인 비교는, ‘비교 대상의 수준이 적정하다고 판단할 수 있는 근거’가 없으므로, 적정한 비교방식이라 보기 어렵다.</p>
      <p>따라서 상대적인 기준이 아닌 절대적인 기준이 필요하다.</p>
      <p>&nbsp;</p>
      <p>그렇다면 비교 대상이 필요 없는 절대적인 기준을 만들 수 있을까?</p>
      <p>학교에서 발표하는 비율(%) 만족도가 아닌, 점수로 계산한 만족도라면 답을 찾기가 쉽다.</p>
      <p>&nbsp;</p>
      <p>매우 간단하다.</p>
      <p>평균 점수가 4점일 때가 소비자의 평균이 만족하는 때이다.</p>
      <p>그러므로 학생들이 급식에 만족하고 있다고 말하려면, 평균 점수가 4점 이상이면 된다.</p>
      <p>따라서 평점 4점을 적정 만족도의 절대적 기준으로 삼으면 된다.</p>
      <div className={ss.annotation_wrap}>
        <p>물론 이는 독도 씨의 개인적인 의견이다.</p>
        <p>학교마다 구성원들의 의견에 따라 적정하다고 판단하는 기준은 달라질 수 있다.</p>
        <p>또한 평점이 4점 이상으로 높더라도, 여전히 존재하는 불만족한 학생을 위한 개선책은 계속해서 고민해야 한다.</p>
        <p>&nbsp;</p>
        <p>참고로, 비율 만족도는 같은 수치에도 질적인 차이가 발생할 수 있어서, 적정 만족도의 기준으로 삼기에는 매우 곤란하다.</p>
        <p>예를 들면, ‘만족 40명, 보통 40명, 매우불만 20명’일 때의 만족도 80%와, ‘매우만족 80명, 불만 20명’일 때의 만족도 80%는 같은 수치지만 질적으로는 확연한 차이가 있다.</p>
        <p>점수로 나타내면 전자는 3.2점(64점), 후자는 4.4점(88점)이다.</p>
        <p>따라서 비율 만족도는 단순히 참고용 수치로서만 사용하는 것이 좋다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학교급식에서 모두를 만족시키는 것은 거의 불가능하겠지만, 점수로 4점을 넘기는 것(평균을 만족시키는 것)은 충분히 가능한 일이다.</p>
      <p>실제로 독도 씨가 수집한 자료에서, 17개 중학교 중 3개 학교가 4점을 넘었고, 15개 고등학교 중에서도 3개 학교가 4점을 넘겼다.</p>
      <p>부종시에서는 약 19%의 학교가 4점을 넘긴 것이다.</p>
      <p>매우 어렵다고 판단하기에는 적지 않게 높은 비율이다.</p>
      <p>&nbsp;</p>
      <p>만일 4점을 넘기기 위해서, 영양교사나 조리사에게 높은 수준의 개인역량을 요구하거나, 업무 시간 초과를 요구하거나, 업무 시간 동안 늘 100%의 노력을 투입하도록 요구해야 한다면 이는 무리한 기준이라 할 수 있겠지만, 열린 급식 운영과 시스템의 지원, 학교자치의 활성화가 뒷받침된다면 어느 학교에서라도 충분히 달성가능한 점수라고 판단된다.</p>
      <div className={ss.annotation_wrap}>
        <p>열린 급식 운영이 어떤 것이고 학교자치의 활성화가 급식 만족도에 어떻게 영향을 미치는지는 앞으로 이야기하는 여러 내용을 읽어 보면 충분히 이해할 수 있을 것이다.</p>
        <p>그 외, 4점을 넘기기 위해 필요한 시스템의 지원이란, 학교급식의 만족도를 향상시킬 수 있는 방법을 연구하는 정책(용역 등)의 시행과 그 연구 결과에 의한 특정 시스템의 구축을 말한다.</p>
        <p>구체적으로 말하자면, 급식 만족도를 올리기 위한, ‘교육‧시설‧식재료‧식단‧조리‧배식‧퇴식’에서의 다양한 개선 방안을 선정하여, 다양한 조건에서 실험해보고, 각각의 경우 만족도가 어떻게 향상되는지를 산출한 후, 급식 만족도가 다양한 조건하에서 보편적으로 향상되는 조건을 찾아 이를 정책으로 만들어(시스템을 구축하여) 보급(지원)하는 것이다.</p>
        <p>한편, 해당 실험은 어떤 방식이라도 보편적인 영양교사‧조리사 등이 충분히 업무에 적용할 수 있는 수준이어야 한다는 전제 조건이 있어야 한다.</p>
        <p>즉, 식단이나 조리방법(recipe) 등을 <span className={ss.bold}>개인역량으로 해결하라고 하지 않고 시스템으로 충분히 지원할 수 있어야 한다.</span></p>
        <p>이를 테면, 법령을 준수하고, 현재 재학생의 만족도(기호)와 식품 트렌드를 반영하고, 상황에 따라 다양한 옵션을 적용할 수 있는, ‘표준 식단 자동 구성 프로그램’의 제공, 이 프로그램과 연동되고 급식실의 규모 등 각종 상황을 옵션으로 하여 조리방법을 생성하는 ‘조리방법 프로그램’의 제공, <span className={ss.bold}>자율배식이 가능하도록 급식실의 구조를 변경</span>하는 공사 지원 등이 그것이다.</p>
        <p>&nbsp;</p>
        <p>지금처럼 학교급식 종사자들의 개인역량에만 맡긴다면, 급식 만족도가 4점을 넘어가는 학교의 비율은 앞으로도 부종시 기준으로 당시 수준인 19%를 넘기기 어려울 것이다.</p>
        <p>보편적인 학교급식 종사자들이 충분히 업무에 적용할 수 있는 보편적인 시스템만으로 4점을 확보할 수 있어야 한다.</p>
        <p>그렇게 되면, 학교급식 종사자들이 추가적인 개인역량을 발휘하거나, 학교자치의 수준 향상을 통해 그 이상의 만족도까지도 기대할 수 있을 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>여기까지 정리하자면, 한비중학교 급식 만족도 조사에서 확인할 수 있는 첫 번째 이슈는 ‘만족도’ 자체이며, 만족도는 대개 ‘매우만족, 만족, 보통’을 합한 비율(%)로 나타내는데, 이보다는 5점 또는 100점 만점의 점수로 나타내는 것이 직관적이고 정확도가 높다.</p>
      <p>한비중학교의 급식 만족도는 학교 발표 만족도 비율로는 80%, 독도 씨가 따로 계산한 점수로는 3.31점(66.2점)이며, 이는 관내 최하위권(17개교 중 15위)이다.</p>
      <p>참고로 관내 30개 중학교 중 조사된 17개 중학교의 평균은 각각 82,9% 및 3.58점(71.6점)이다.</p>
      <p>한편, 독도 씨는 ‘만족할만한 급식 만족도 점수’로 4점(평균 만족)을 제시했으며, 이는 조사된 부종시 내 학교의 19%가 달성하고 있는 점수로서, 뒤에 설명하겠지만 열린 급식 운영 및 학교 구성원의 참여 여하 따라 충분히 가능하며, 시스템에 의한 지원이 있다면 그 이상도 가능하리라는 주장이다.</p>
      <p>&nbsp;</p>
      <p>2. 두 번째 이슈, 급식의 양.</p>
      <p>독도 씨가 급식 만족도 조사를 들여 보게 된 계기가 바로 급식의 양에 관한 문제였다.</p>
      <p>아니나 다를까, 급식의 양이 부족하다는 응답은 생각보다 많았다.</p>
      <p>무려 59%의 학생이 양이 적다고 응답하였다.(31%는 적당하다고 했고, 10%는 많다고 했다.)</p>
      <p>처음 이 수치를 보고 독도 씨는 충격에 빠졌다.</p>
      <p className={ss.quo_mini}>‘장난이야? 아니면 뭐야? 절반 이상의 학생이 양이 부족하다고? 이 정도면 매우 심각한 수준 아닌가? 급식실에 무슨 문제라도 있나?’</p>
      <p>&nbsp;</p>
      <p>그런데 영양교사는 양 부족에 대한 결과분석을 하면서, <span className={ss.quo}>“육류반찬이 부족하다는 응답이 56%로, 육류반찬에 대한 선호도가 높아 배식량이 알맞아도 부족하다고 느끼는 것입니다. 좋아하는 반찬에 대해서는 부족하다고 생각하고, 싫어하는 반찬에 대해서는 많다고 생각하는 것으로 보입니다. 적정 배식량에 대한 교육 및 인식이 필요하다고 생각됩니다.”</span>라며, 배식량은 적정한데 선호도 때문에 부족함을 느끼는 것이라고 말하고 있었다.</p>
      <div className={ss.annotation_wrap}>
        <p>양이 부족하다는 항목에 이어, 어떤 것이 부족한가를 묻는 항목이 있었는데, 육류반찬이 56%, 밥이 25%, 기타 19%로 집계되어 있었다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그러니까, 영양교사의 분석은, 양이 부족하다는 응답은 학생들의 기호가 편중되어서 발생한 ‘느낌’이며, 이는 배식량에 관한 교육으로 해결하여야 할 일이라는 것이다.</p>
      <p className={ss.quo_mini}>‘음, 학생들이 고기반찬을 좋아하기 때문에 그런 결과가 나온 것이고, 급식에는 아무 문제가 없다는 이야기이군. 단순명료하고 단호한 분석이네. 정말 그런가?’</p>
      <p>&nbsp;</p>
      <p>독도 씨는 관련 정보를 검색해 보았다.</p>
      <p>실제로 학생들의 육류선호 경향을, 많은 영양교사들이 현장에서 보고하고 있었다.</p>
      <p>육류를 선호하되 골고루 먹는 학생이 있는가하면, 육류 외의 반찬(나물 등 채소류와 생선 등)을 싫어하는 편식이 심한 학생도 있는데, 편식의 가장 큰 문제점은 영양섭취의 불균형과 잔반의 증가로 이어진다는 것이었다.</p>
      <p>이에 대한 대처로는 식습관 개선을 위한 교육(지도) 활동 실시, 가정에서부터의 개선을 유도하는 안내 활동 실시, 다른 반찬의 조리법을 개선하여 기호도 향상시키기, 잔반 감소에 인센티브를 부여하는 각종 이벤트의 실시 등 다양한 방법이 시도되고 있었다.</p>
      <p>&nbsp;</p>
      <p>확실히 가공식품이나 나트륨의 과다 섭취, 편식으로 인한 영양 불균형 등은 건강을 위해 반드시 바로 잡아야할 식습관이다.</p>
      <p>따라서 ‘육류만(선호하는 음식만) 골라서 섭취하는 식습관(편식)’을 개선하는 것은 주요 급식교육 목표의 하나라고 할 수 있다.</p>
      <p className={ss.quo_mini}>‘영양교사들의 고민도 쉬운 고민은 아니겠구나.’</p>
      <p>독도 씨는 학생들의 편식 문제가 쉽게 개선되어질 문제가 아니라는 것을 잘 안다.</p>
      <p>자신도 어렸을 적에는, 아주 오래도록, 채소를 먹기 싫어서 골라냈던 기억이 있기 때문이다.</p>
      <div className={ss.annotation_wrap}>
        <p>여담이지만, 독도 씨의 편식은 술을 마시기 시작하면서 사라졌다.</p>
        <p>한참 술을 배우던 시기에, 당시 또래들은 안주를 골라 술을 마시지 않았으므로, 갖가지 안주를 접하다보니 온갖 식재료에 대한 거부감도 줄어들었고, 대개는 뭐라도 있으면 감사할 처지였으므로 못 먹는 것이 없을 정도로 취향이 다양해졌다.</p>
        <p>그래서 독도 씨는, <span className={ss.bold}>다양한 식재료(식단)에 대한 경험이 중요</span>하다고 생각한다.</p>
        <p>학생들도 다양한 식단을 접하다보면 편식이 나아지지 않을까?</p>
        <p>물론 다양하게 차려 놓아도, 손을 대지 않으면 경험하지 못할 것이므로, 일단 먹기 시작해야 할 것이다.</p>
        <p>그런데 그것(먹기 시작하는 것)을 강제할 수는 없는 노릇이니, 새로운 조리법 등으로 ‘아는 식재료지만 모르는 맛’으로 둔갑시켜, 경험을 유도하는 것도 좋은 방법이 될 수 있다고 생각한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그런데 가만히 생각해보면, 많은 음식 중에서 육류를 선호하는 것이 반드시 나쁜 일은 아니다.</p>
      <p>영양의 불균형을 초래하여 몸에 악영향을 끼칠 정도로 육류에 편중된 식사를 하는 것이 좋지 않은 것이지, 선호한다는 자체를 잘못이라고 할 수는 없다.</p>
      <p>따라서 고기반찬만 먹고 다른 반찬을 남기는 식습관이라면 개선이 필요하겠지만, 다른 반찬도 잘 먹으면서 고기반찬을 좋아하는 것은 나무랄 것이 없다.</p>
      <p>그래서 독도 씨는 6번 항목인 잔반에 대한 답변을 살펴보았다.</p>
      <p>잔반을 거의 남기지 않는다는 답변이 27%, 가끔 조금 남긴다는 답변이 53%, 거의 매일 남긴다는 답변이 20%였다.</p>
      <p>그리고 7번 항목인, 주로 남기는 음식에 대한 답변은 국이 58%로 압도적으로 많았고, 2위가 채소반찬으로 22%였고 김치 8%, 생선 8%, 밥 4% 순이었다.</p>
      <p>잔반을 남기는 학생들이 주로 남기는 잔반이 국이라는 것을 알 수 있다.</p>
      <p>국물은 어디서나(음식점이나 구내식당 등에서) 가장 흔하게 남기는 음식이다.</p>
      <p>특히 최근의 젊은 성인이나 아동청소년 세대는 국을 선호하지 않는다.</p>
      <div className={ss.annotation_wrap}>
        <p>국은 나트륨 과다 섭취, 부종, 소화 불량의 원인으로 인식되어, 다양한 장점에도 불구하고 점차 섭취를 지양하는 추세이다.</p>
      </div>
      <p>또한 8번 항목에서 음식을 남기는 이유에 대한 답변은, 싫어하는 음식 38%, 맛이 없어서 34%, 배식량이 많아서 11%, 남기지 않음 12%, 기타 5%였다.</p>
      <p className={ss.quo_mini}>‘싫어하는 음식 때문에 남긴다는 학생이 38%지만, 잔반이 주로 국이고, 가끔 조금 남긴다는 학생을 편식하고 있다고 보기는 어렵잖아? 나도 음식을 좋아해서 뭐든 잘 먹는 타입이지만 맛이 없거나, 간이 안 맞거나, 냄새가 나거나, 양이 많으면 가끔 남기는데…….’</p>
      <p>독도 씨도 어쩌다 구내식당 같은 곳에서 배식을 받아서 먹을 경우, 거의 모든 밥과 반찬을 다 가리지 않고 주는 대로 잘 먹지만, 국이나 김치가 남을 경우에는, 짜기 때문에 억지로 먹지 않고 잔반으로 버린다.</p>
      <p className={ss.quo_mini}>‘80%의 학생은 거의 편식하지 않는다고 봐도 될 것 같아. 급식의 양이 많다고 한 10%의 학생들은 편식과 무관하게 자주 잔반을 남길 테니, 실제 편식 때문에 거의 매일 잔반을 남기는 학생은 10% 안팎으로 보면 되겠어.’</p>
      <p>&nbsp;</p>
      <p>위 항목들의 답변을 보면, 잔반을 자주 남기는 학생의 비율이 높지 않고, 잔반의 종류가 주로 국이라는 점에서, 육류반찬을 선호하는 56%의 학생들이 모두 육류에 편중된 식사를 하고 있다고 보이지는 않는다.(육류편식 때문에 교육이 필요한 학생은 10~20% 사이로 보는 것이 적당한 듯하다.)</p>
      <div className={ss.annotation_wrap}>
        <p><span className={ss.fbody}>“나는 육류반찬은 좋아하지만 다른 반찬은 별로 좋아하지 않아서 자주 남긴다.”</span>와 <span className={ss.fbody}>“나는 육류반찬을 좋아하지만 다른 반찬도 잘 먹어서 거의 남기지 않는다.”</span>를 선택하는 질문을 넣으면, 적정 배식량 교육이나 편식 개선 교육의 필요성과 관련하여 보다 확실한 통계를 얻을 수 있을 것이다.</p>
      </div>
      <p>그러니까 절반 이상의 상당수 학생들이 고기를 더 좋아하지만, 그들을 포함한 학생 대부분은 다른 반찬도 잘 먹고 있는 것이다.</p>
      <p>그렇다면 과연 편식이 심하지 않은 이 학생들이, 배식량은 적절한데 육류반찬을 더 먹고 싶어서 급식의 양이 부족하다고 응답했을까?</p>
      <p>혹시 배식량이 적절하지 않았던 것은 아닐까?</p>
      <p>영양교사는 무슨 근거로 ‘적정 배식량’이 제공되고 있다고 확신하는 것일까?</p>
      <div className={ss.annotation_wrap}>
        <p>한비중학교의 사례와 다른(육류반찬 선호로 인해 편식이 심한 학생이 많은) 학교도 있을 것이다.</p>
        <p>하지만 한비중학교의 사례와 비슷한 학교도 있을 것이므로, 양이 부족하다는 학생 모두가 ‘육류반찬 선호로 인해 편식을 하는 것’은 아니라고 봐야 한다.</p>
        <p>편식하는 학생이 다수인 것은 사실로 보이고, 그것은 교육이나 기타 방법을 통해 해결해야할 문제가 분명하지만, <span className={ss.bold}>양이 부족한 원인이나 잔반이 많은 원인을, 육류섭취 선호로 인한 편식 등으로만 판단하는 것은, 문제의 본질(급식 체계의 문제 등)을 파악하지 못하고 그 원인을 학생(잘못된 식습관)의 탓으로만 몰아가는 실책일 수 있다.</span></p>
        <p>&nbsp;</p>
        <p>참고로, 부종시의 중학교 중에서 만족도 4점이 넘은 학교가 3개가 있다고 했는데, 그중 하나인 A학교의 사례를 잠깐 살펴보자.</p>
        <p>A학교는 학교 발표 만족도가 95%이고, 점수로 계산한 만족도가 4.1점이다.</p>
        <p>이 학교에서 급식의 양이 부족하다는 응답은 18.9%로 집계되었는데, 부종시 내에서 비율이 낮은 순위로 3위에 해당된다.(양이 부족하다고 응답한 비율은 부종시 중학교 평균이 32%이다.)</p>
        <p>A학교의 영양교사는, <span className={ss.fbody}>“불고기, 탕수육 등 학생들의 기호도가 높은 주찬일수록 학생들이 추가로 더 먹고 싶다는 의견을 주었기에, 관련 식재료를 추가 발주하되 급식 단가에 맞추어서 양을 늘리고 있으며, 추가셀프배식대에 주찬도 추가배식하고 있습니다.”</span>라고 말했다.</p>
        <p>한비중학교의 경우와는 대응이 사뭇 다르다.</p>
        <p>육류반찬 선호에 대해, 한비중학교는 <span className={ss.fbody}>“적정 배식량에 대한 교육이 필요하다.”</span>며 학생들의 교육부재 탓으로 돌렸는데, A학교는 <span className={ss.fbody}>“부족하다면 더 주마.”</span>라고 말하고, 추가셀프배식대에 추가분을 배치하여 모자란 학생들이 더 먹을 수 있도록 조치한 것이다.</p>
        <p>독도 씨는 단지 급식 만족도 조사 결과만 보았을 뿐이고, 급식 단가, 식재료 구매 단가, 영양관리기준 준수, 추가셀프배식대 운영 요령, 학생들의 질서 유지 여부 등을 확인하지는 못했지만, 아마 규정을 준수하면서도 가능했으리라고 추정하였다.</p>
        <p>학생들로부터 4.1점을 받았다는 것은, 그만큼 <span className={ss.bold}>열린 급식</span>을 운영했다는 이야기다.</p>
        <p>실제로 A학교는 약 50여개 요리에 대해 학생들의 기호를 조사하여 이를 식단에 반영하고 있었으며, 위생(청결)과 친절, 질서 등에 대해서도 만족도 조사를 통해 개선 노력을 하고 있었고, 조리방법의 선호도를 물어 반영하고, 심지어 급식실의 냉난방 온도까지 묻고 조절하는, 세심한 노력을 기울이고 있었다.</p>
        <p>&nbsp;</p>
        <p>어떤가?</p>
        <p>고기가 부족하다는 응답에 대해 대처하는 방식 말이다.</p>
        <p>100% 만족시키기는 어려워도, <span className={ss.bold}>방법을 찾으려고 노력하면 다른 결과가 나온다.</span></p>
        <p>양이 부족하다는 응답이 59%와 18.9%로 차이가 나는 이유가 이런 것이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그렇다면 이제 ‘59%의 학생이 응답한 양 부족’의 진짜 원인을 알아보자.</p>
      <p>&nbsp;</p>
      <p>독도 씨는, 부족한 음식을 묻는 4번 항목에서 밥 부족이 25%라고 답한 부분에 주목했다.</p>
      <p>밥이 부족하다는 것은 육류반찬의 경우처럼 ‘밥을 선호하니까 배식량은 적당한데 부족하다고 느낀다.’라고 하기에는 무리가 있다.</p>
      <p>이는 배식량이 부족하다고 보는 것이 맞을 것이다.</p>
      <p className={ss.quo_mini}>‘영양교사는 왜 밥이 부족하다는 부분에 대해서는 언급하지 않았을까? 무시해도 좋을 수치가 아닌데? 양이 부족하다는 학생이 많으니까, 개선을 위해 여러 각도에서 문제를 살펴보고 정확히 분석해야할 텐데…….’</p>
      <p>독도 씨는 일단 적정량이 공급되었는지를 체크 해보기로 했다.</p>
      <p className={ss.quo_mini}>‘그런데 적정량은 얼마지? 어떻게 정하지?’</p>
      <p>학교홈페이지 급식게시판에는, 월간 게시물로 급식 일정(식단표)과 영양교육안내 등의 게시물이 올라오고, 연간 게시물로 ‘학교급식 만족도 조사’와 ‘학교급식운영계획(안)’이 올라오고 있었다.</p>
      <div className={ss.annotation_wrap}>
        <p>이 게시물들의 조회수는 보통 0~2회이다.</p>
        <p>아무도 읽지 않는다는 뜻이다.</p>
        <p>그런데 이는 학교급식에 무관심해서 나타나는 현상이 아니다.</p>
        <p>급식과 무관한, 학교홈페이지 운영 문제와 관련 있는 현상이다.</p>
        <p>월간 게시물은 가정통신문을 통해 각 가정에 전달되고, 급식실 및 학교 교실에도 게시하기 때문에 학교홈페이지에 접속하지 않아도 확인할 수 있다.</p>
        <p>식단표는 학생 뿐 아니라 학부모도 높은 관심을 가지고 읽는 게시물이며, 학교 구성원들은 매월 학교에서 보내 주는 식단표와 영양교육안내를 보며, 학교가 매우 성실하게 정보를 제공하고 있다고 믿는다.</p>
        <p>하지만, 연간 게시물은 가정통신문으로 전달하지 않는다.</p>
        <p>높지 않은 급식 만족도를 알릴 필요가 없으며, ‘학교급식운영계획(안)’ 같은 심의 안건은 되도록 눈에 띄지 않는 것이 비판을 없애고 우민화하는데 도움이 되기 때문이다.</p>
        <p>둘 다 매우 중요한 정보임에도 불구하고, 아무도 접속하지 않는 학교홈페이지에만 올려놓고 다른 방식으로는 알리지 않는다.</p>
        <p>학교홈페이지가 있으나마나하게 운영되는 것은, 학교 구성원들의 입장에서는 정보의 불균형이고, 그렇기 때문에 학교 입장에서는 큰 이득이다.</p>
        <p>공지해야 한다는 규정을 준수하면서도, 실제로는 공지되지 않는, 실로 신묘한 전술이라고 할 수 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>급식게시판에서 만족도 자료를 내려 받은 독도 씨가, ‘학교급식운영계획(안)’을 놓칠 리 없었다.</p>
      <p className={ss.quo_mini}>‘분명 학교급식운영계획(안)에는 급식 운영에 관한 다양한 내용이 있을 거야.’</p>
      <p>과연 그 안에는 다양한 정보가 수록되어 있었다.</p>
      <p className={ss.circle_num}>① 연간 급식의 운영 목표, 방침.</p>
      <p className={ss.circle_num}>② 급식 시설의 현황 보고 및 시설 운영(유지보수 등) 계획</p>
      <p className={ss.circle_num}>③ 급식 예산(급식 단가와 재료비, 인건비, 경비 비율) 운용 계획</p>
      <p className={ss.circle_num}>④ 급식 일정(횟수)‧시간‧식단관리‧조리방법관리‧식재료검수관리 계획</p>
      <p className={ss.circle_num}>⑤ 연간 모니터링 계획</p>
      <p className={ss.circle_num}>⑥ 안전 예방 대책(식중독, 이물질 혼입 등 각종 사고 시 대응 절차 포함)</p>
      <p className={ss.circle_num}>⑦ 위생관리 절차와 관련 계획</p>
      <p className={ss.circle_num}>⑧ 식재료 구매 기준 및 식재료 조달 업체 선정 기준 설정</p>
      <p className={ss.circle_num}>⑨ 급식지도 및 연수계획</p>
      <p className={ss.circle_num}>⑩ 기타</p>
      <p>실로 다양한 항목, 그러니까 연간 급식 운영의 모든 것이 이 문서에 수록되어 있었다.</p>
      <div className={ss.annotation_wrap}>
        <p>위의 A중학교의 경우에는, 급식 건의함 운영 계획이나 나트륨 및 당류 저감 계획, 잔반 감소 대책, 급식 만족도 개선 대책 등이 포함되어 있었는데, 한비중학교와는 확실히 차이가 있었다.</p>
        <p className={ss.bold}>만족도가 높은 데에는 다 이유가 있기 마련이다.</p>
      </div>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘이 문서 한 권으로 학교급식의 모든 것을 파악할 수 있네. 그런데 심의는 학년말에 하니까, 신규 급식소위원회는 이 내용을 전혀 몰라. 이런 정보를 모르니까 학교급식을 모니터링 할 때도 놓치는 것이 발생하고 문제가 있어도 모르는 거야. 이걸 학교(급식실)에서 알려주면 좋은데, <span className={ss.bold}>그런 기대를 하는 것은 어리석은 일</span>이겠지?’</p>
      <p>만일 독도 씨가 급식 게시판에서 이 문서를 발견하지 못했다면, 독도 씨 역시 다른 학부모들처럼 급식 운영 방식에 대해 구체적으로 알지 못했을 것이다.</p>
      <p className={ss.quo_mini}>‘하여간 죄다 형식적으로 하니까 문제가 개선되는 것이 없지. 그러니 자꾸 <span className={ss.bold}>같은 사건·사고가 반복</span>되는 것이고, 그때마다 개선하겠다고 떠들지만, 근본적으로 <span className={ss.bold}>정보가 감추어져 있는데 개선이 되겠어? 덮어서 감추는 것이 훨씬 쉽지.</span> 알면 알수록 <span className={ss.bold}>학교는 모든 것이 문제</span>야.’</p>
      <p>독도 씨는 새로운 정보를 획득할 때마다, 그것이 자연스럽게(정상적으로) 흘러들어온 것이 아니라 많은 수고를 들이거나 우연한 경로로 발견되었기 때문에, <span className={ss.bold}>학교와 학부모 사이의 심각한 정보 불균형</span>에 대해 생각하지 않을 수 없었고, 이것이 <span className={ss.bold}>학교 운영 참여(학교자치)에 최대의 걸림돌</span>이 되고 있다는 생각을 하였다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘앗, 여기다! 급식 식단관리 부분에 학교급식의 영양관리기준이 있어! 그러니까 이 기준으로 음식을 만들고 배식한다는 거네. 이야! 그냥 임의로 하거나 주먹구구식으로 하는 것이 아니라 정해진 공급기준이 있었네. 역시 <span className={ss.bold}>우리나라 공교육은 시스템은 잘 만들어. 제대로 운영하지 못하는 게 문제지.’</span></p>
      <div className={ss.annotation_wrap}>
        <p>잘 만들어 놓고 운영을 제대로 못하거나, 아예 시스템 자체가 엉망이거나 대개 둘 중 하나이다.</p>
        <p>공교육에 대한 칭찬은, 섣부르거나 경솔하게 될 우려가 많기 때문에, 좀 아낄 필요가 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이 문서에는, 한비중학교 급식의 영양관리기준이, ‘「학교급식법 시행규칙」 제5조 제1항에서 언급한 [별표3]’(이하 [별표3])에 따라 작성되었다고 쓰여 있었다.</p>
      <p>그래서 독도 씨는 곧바로 「학교급식법 시행규칙」을 찾아서 읽어 보았다.</p>
      <p>&nbsp;</p>
      <p>[별표3]에서는 초등저학년, 초등고학년, 중학생, 고등학생의 남녀 학생별 공급 열량(칼로리)과 단백질(7~20%), 지방(15~30%), 탄수화물(55~70%)의 비율, 각종 미네랄 함량 등을 규정하고 있었다.</p>
      <div className={ss.annotation_wrap}>
        <p>2015년 당시의 기준과, 현재의 기준은 약간의 차이가 있다.</p>
        <p>현재의 열량 기준이 약간 더 높은데, 아마 학생들의 평균 체중과 평균 키의 증가가 반영된 것이라 추정된다.</p>
        <p>여기서는 당시의 논쟁을 기준으로 서술하기 위해, 이후 [별표3] 관련한 모든 수치는, 특별히 언급하지 않는 한, 2015년을 기준으로 한다.</p>
        <p>물론 2015년을 기준으로 이야기하지만, 그때나 지금이나 문제의 본질은 완전히 같다.</p>
        <p>이 문제는, 10년이 지났어도 전혀 개선되지 않고 있다.</p>
      </div>
      <p>&nbsp;</p>
      <p>[별표3]에 따르면, 급식 한 끼에 공급해야 하는 열량은, 남자중학생 800㎉, 여자중학생 667㎉, 남자고등학생 900㎉, 여자고등학생 667㎉이며, 이 수치의 ±10%까지 공급할 수 있다.</p>
      <p>그러니까 남자중학생의 경우 720㎉~880㎉ 사이에서 공급하면 적법한 것이다.</p>
      <p>이 경우, 단백질은 공급 열량의 7~20% 사이로 하여야 하므로, 최대 176㎉(=880㎉×0.2)까지 공급이 가능하고, 이를 무게로 환산하면 단백질 1g의 열량은 4㎉이므로, 급식 한 끼에 최대 44g(=176㎉÷4g/㎉)의 단백질을 공급할 수 있다.</p>
      <p>단백질 44g은 어느 정도일까?</p>
      <p>돼지고기 앞다리의 경우 100g당 단백질 함량은 약 20g이다.</p>
      <p>그러므로 돼지고기 앞다리만으로 단백질을 공급한다면 최대 220g을 공급할 수 있다.</p>
      <p>물론 밥이나 다른 반찬에도 단백질이 포함되어 있을 것이므로, 돼지고기 앞다리를 이용한 제육볶음을 공급할 때, 1인당 대략 150g~200g 정도를 공급할 수 있을 것이라 추정된다.</p>
      <div className={ss.annotation_wrap}>
        <p>독도 씨는 영양교사와 사이가 좋지 않았기 때문에, 다른 반찬에 포함되는 단백질의 양 같은 것을 구체적으로 물어 볼 수는 없었다.</p>
        <p>물론 운영위원들과도 사이가 좋지 않았기 때문에, 위원들의 동의를 구해 자료를 요구하는 행위도 할 수 없었다.</p>
        <p>따라서 일부 데이터는 추정할 수밖에 없고, 그 계산결과도 추정일 수밖에 없다.</p>
        <p>하지만 추정과 실제에 유의미한 차이가 있다고 하더라도, 문제의 본질을 논하는 데에는 아무 영향이 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>명쾌했다.</p>
      <p>학생 1인이 공급받아야할 열량이 정해져 있으므로, 그대로 공급하면 된다.</p>
      <p>일단 식단을 구성하면, 식단별 식재료의 종류와 양이 정해질 것이고, 식재료별 영양성분과 열량은 모두 밝혀져 있으므로, 식단(식재료의 양)에 따라 공급되는 열량과 영양소를 모두 정확하게 계산할 수 있다.</p>
      <p>또한 [별표3]의 세부 내용에 따르면, 5일(주중) 동안 제공한 식단의 평균 공급열량이 기준치를 만족하면 되므로, 그날그날의 열량은 기준치를 살짝 벗어나도 문제가 없다.</p>
      <p>그러니까 운영의 묘를 살린다면 A학교처럼 고기반찬을 좀 더 추가해서 학생들을 만족시키는 것도 크게 어려운 일은 아닐 듯싶었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘제육볶음 1인분에 돼지고기 150~200g 정도면, 아주 많이 먹는 학생들을 제외하고 보통은 1인분으로 충분하지 않나? 체구가 큰 학생들에게는 당연히 모자란 양이겠지만, 평균으로 치면 56%의 학생들이 고기반찬이 부족하다고 할 수준은 아닌 것 같은데? 애들이 다 그렇게 많이 먹나? 아님 역시 육류반찬 선호에서 비롯된 수치일까?’</p>
      <p>독도 씨는 법령에서 정한 열량이 분명 적정한 수준일 것이라 믿었다.</p>
      <p>학생들이 배고픔에 허덕일 수준으로 제시하지는 않았을 것이고, 오히려 기준을 과하게 초과하면 비만을 야기할 수 있는, 그런 적정한 수치일 것이다.</p>
      <p className={ss.quo_mini}>‘모두 같은 양을 먹는 것은 아니니까, 체구가 큰 아이들은 더 먹고, 작은 아이들은 덜 먹고……. 그것을 평균내서 산출한 저 기준은 적정한 수준일 텐데. 어째서 59%나 되는 학생들이 급식의 양이 부족하다고 하는 것일까? 고기반찬 선호만으로 원인이라 하기에는 뭔가 석연치가 않아.’</p>
      <p>&nbsp;</p>
      <p>그런데 이런 생각을 하던 독도 씨의 눈에 어떤 숫자가 들어왔다.</p>
      <p>“2015학년도 자체 영양 기준량, 707㎉.”</p>
      <p className={ss.quo_mini}>‘어? 이게 뭐지?’</p>
      <p>&nbsp;</p>
      <p>그러니까 한비중학교에서 2015년에 공급할 예정인 한 끼의 열량이 707㎉이라는 말이었다.</p>
      <p>작년에 공급한 식단의 열량을 계산해 보았더니 평균 707㎉이었는데, 올해도 그에 준해서 공급하겠다는 뜻이었다.</p>
      <p>&nbsp;</p>
      <p>언뜻 보니, 뜬금없다고 생각 되었다.</p>
      <p>밤톨이를 기준으로 생각하면, 학교에서 제공하는 급식의 양은 열량 800㎉ 안팎이어야 하는데, 707㎉면 너무 적다는 생각이 들었다.</p>
      <p className={ss.quo_mini}>‘뭐야? ±10%를 감안해도 너무 낮잖아? +10%를 주지는 못할망정 -10%보다도 낮아?’</p>
      <p>&nbsp;</p>
      <p>이게 어디서 나온 수치일까?</p>
      <p>&nbsp;</p>
      <p>독도 씨는 미간을 찌푸리며 잠시 고민을 하다가, 금방 답을 찾아냈다.</p>
      <p className={ss.quo_mini}>‘아! 남학생과 여학생의 기준 열량이 다르니까 그걸 합쳐서 평균을 낸 것이구나.’</p>
      <p>한비중학교의 남학생은 485명이므로 한 끼에 800㎉×485=388,000㎉를 공급해야 하고, 여학생은 501명이므로 667㎉×501=334,167㎉를 공급해야 한다.</p>
      <p>이를 합하면 한 끼에 공급해야할 전교생 대상 전체 열량은 722,167㎉이다.</p>
      <p>그러니까 한비중학교는 매 끼니마다 722,167㎉만큼의 음식을 조리해야 한다.</p>
      <p>이를 학생 1인당 평균으로 계산하면, 722,167㎉÷986=732.4㎉가 된다.</p>
      <p>그러므로 한비중학교는 급식 한 끼에 732.4㎉의 열량을 공급해야 [별표3]의 조건을 만족시킬 수 있다.</p>
      <p>그런데 707㎉는 732.4㎉의 96.5%에 해당되므로, 「학교급식법 시행규칙」을 준수하고 있다고 말할 수 있다.(±10%의 차이는 적법하다.)</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘좀 더 주지. 애들이 양이 부족하다는데……. 급식비가 많이 딸리나?’</p>
      <p>독도 씨는 학교에서 공급하는 평균 열량이 707㎉로 계산되는 이유를 완전히 이해했다.</p>
      <p className={ss.quo_mini}>‘1인당 평균이 707㎉라는 것이지 800㎉을 주어야할 남학생에게 707㎉을 주는 것은 아니니까 문제가 없는……. 어? 그게 아닌데?’</p>
      <p>&nbsp;</p>
      <p>갑자기 머리가 어질어질 했다.</p>
      <p>독도 씨의 머릿속에, 급식소위원회 위원으로 참가했던 급식모니터링 당시의 기억이 선명하게 떠올랐다.</p>
      <p>조리사와 자원봉사 학생들은, <span className={ss.bold}>남학생과 여학생 모두에게 같은 양을 배식하고 있었다.</span></p>
      <p className={ss.quo_mini}>‘앗! 그럼 안 되잖아! 남학생은 800㎉을 주고, 여학생은 667㎉을 줘야하는데, 둘 다 707㎉을 준다는 이야기잖아!’</p>
      <p>&nbsp;</p>
      <p>그랬다!</p>
      <p>이제 문제의 원인이 선명하게 보였다.</p>
      <p>남학생과 여학생에게 주어야할 배식량은 [별표3]에 따라 각각 다르다.</p>
      <p>그 다른 양에 맞춰 조리를 해 놓고는, 정작 배식은 남학생과 여학생에게 똑같이 주고 있었던 것이다.</p>
      <p>그러니까, 남학생은 제공받아야할 양의 707÷800=88%를 받고 있었고, 여학생은 제공받아야할 양의 707÷667=106%를 받고 있었다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘생각해보니 그러네. 배식을 남녀 똑같이 주면 안 되는 거야. 남자애들이 평균적으로 더 많이 먹잖아. 더 크니까. 법령에도 다르게 주도록 되어 있는데 똑같이 주니까 남자애들은 양이 모자란 거지. 여자애들은 오히려 양이 많고. 이거 심각한데?’</p>
      <p>&nbsp;</p>
      <p><span className={ss.bold}>명백한 법령 위반</span>이었다.</p>
      <p>남학생과 여학생이 제공받아야할 칼로리는 다르다.</p>
      <p>한비중학교의 남학생은 12%의 열량을 덜 받고 있었고, 여학생은 6%의 열량을 더 받고 있었다.</p>
      <div className={ss.annotation_wrap}>
        <p>우리가 흔히 <span className={ss.bold}>다이어트</span>를 결심할 때, 가장 먼저 떠오르는 방법이 열량(칼로리)의 제한이다.</p>
        <p>다이어트를 시도한 경험이 있는 사람은 잘 알 것이다.</p>
        <p><span className={ss.bold}>칼로리를 제한(덜 섭취)한다는 것은 매우 배가 고픈 일</span>이라는 것을.</p>
      </div>
      <p>산술적으로 따지면 약 50%의 학생이 덜 받고 있으며, 50%의 학생이 더 받고 있는 셈이다.</p>
      <p>만족도 조사 결과 양 부족이 59%가 나온 것은, 여러 가지 심리적 요인(육류반찬 선호, 다른 불만의 전이, 솔직하지 않은 응답 등)도 어느 정도 영향을 끼쳤겠지만, 남학생들이 적정량을 받지 못한 것이 가장 큰 요인이라고 판단할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>남녀학생에게 동등한 양을 배식하는 학교는 그렇지 않은 학교보다 훨씬 많다.</p>
        <p>이는 우리 사회가 <span className={ss.bold}>대수롭지 않게 넘기고 있는 심각한 문제의 하나</span>라고 할 수 있는데, 왜냐하면 <span className={ss.bold}>인권(차별)의 문제</span>이기 때문이다.</p>
        <p>따라서 보다 체계적인 조사와 논의가 필요하다고 할 수 있다.</p>
      </div>
      <p>밥도, 국도, 반찬도 모두 12%를 덜 받는다면, 양이 부족할 수밖에 없다.</p>
      <div className={ss.annotation_wrap}>
        <p>그나마 국은 잘 먹지 않으니 더욱 양이 부족할 것이다!</p>
      </div>
      <p>특히 체구가 평균 이상인 학생은 배고픔이 더욱 고통스러웠을 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>[별표3]에 따라 880㎉까지도 공급받을 수 있기 때문에, <span className={ss.bold}>법령이 줄 수 있다고 제시한 최대 양의 고작 80%</span>만 먹는 셈이다.</p>
        <p>3끼를 기준으로 하면 하루 500㎉ 이상을 덜 먹는 것이므로, 그야말로 강제 다이어트를 하고 있는 수준이다.</p>
      </div>
      <p className={ss.quo_mini}>‘이제야 깨달았지만, 남녀학생에게 같은 양의 음식을 배식하는 것은, 분명히 문제가 있는 방식이다. 아니, 이런 위법한 일이 몇 년째 이어지고 있는데 아무도 지적을 안 했다는 말인가?’</p>
      <div className={ss.annotation_wrap}>
        <p>혹시나 양 차별의 문제가 남학생과 여학생 사이의 젠더 논쟁으로 변질될지도 몰라서 노파심으로 미리 말하지만, <span className={ss.bold}>근본적으로는 모든 학생(개개인)의 배식량이 같은 것이 문제다.</span></p>
        <p>남학생과 여학생의 급식량을 차등 배식하더라도, 모든 학생에게 개인차가 있기 때문에 여전히 문제는 해결되지 않는다.</p>
        <p>이와 관련한 부분은 뒤에서 고찰하기로 하자.</p>
      </div>
      <p>&nbsp;</p>
      <p>다른 학교의 경우도 마찬가지였다.</p>
      <p>독도 씨가 집계한, 부종시 관내 17개 중학교의 학교급식 만족도 조사에서 양이 부족하다는 응답은 평균 32%였다.</p>
      <div className={ss.annotation_wrap}>
        <p>다른 학교도 남녀 똑같이 배식하고 있으리라는 것은, 처음에는 추정이었다.</p>
        <p>독도 씨가 직접 확인하지는 못했으나, 데이터를 보면 그럴만한 이유가 확실히 있었다.(데이터 분석은 아래 내용을 참조하자.)</p>
        <p>그런데 나중에 독도 씨가 교육청을 통해 문의하고 확인한 바로는, 정말로 대부분의 학교가 그렇게 배식하고 있었다.</p>
        <p>간혹 자율배식을 하거나 자율배식에 가까운 배식을 하거나, 남녀에게 다르게 주는 학교가 있기는 했지만, 당시 그런 학교의 비율은 매우 소수였다.</p>
      </div>
      <p>&nbsp;</p>
      <p>학교마다 다양한 변수가 있어서, 그 차이는 있었지만 대개 비슷했다.</p>
      <p>한비중학교가 유난히 부족하다는 응답이 더 많은 것은 아마도 다른 요인이 추가되어 있기 때문일 것이고, 여러 학교의 공통적인 요인이자 핵심 요인은 <span className={ss.bold}>배식량을 똑같이 주기 때문</span>인 것으로 판단되었다.</p>
      <p>앞서 등장했던 A학교의 경우, 추가셀프배식대를 활발하게 운영하였기 때문에 양이 부족하다는 비율이 18.9%로 다른 학교 평균보다 낮았지만, 추가셀프배식대만으로는 열량 부족 문제를 완전하게 해결하기가 어려웠을 것이라고 추정할 수 있다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는 <span className={ss.quo_mini}>‘양 부족의 원인은, 육류반찬 선호로 인한 부분도 있겠지만, 근본적으로 배식량이 부족하기 때문임이 확실해.’</span>라며, 이와 같은 사실을 더 확실하게 입증할 근거를 찾기 위해, 앞서 말한 대로 부종시 관내에 위치한 중·고등학교 50개의 홈페이지를 샅샅이 조사하여, 32개의 학교홈페이지에서 3년 치의 ‘급식 만족도 조사 자료’를 확보한 후, 여러 날에 걸쳐 낱낱이 분석하였다.</p>
      <p>&nbsp;</p>
      <p>그 결과 독도 씨의 확신에 힘을 실어주는 세 가지 추정(현상)에 대한 데이터를 확보할 수 있었다.</p>
      <p>&nbsp;</p>
      <p>일단, 가장 기본이 되는 수치부터 집계해보자.</p>
      <p>부종시 학생들이 ‘양이 부족하다’고 응답한 수치로서, 중학교(17개) 평균은 32%이고 고등학교(15개) 평균은 36%였다.</p>
      <div className={ss.annotation_wrap}>
        <p>물론 이 수치가 ‘배식량이 적정하지 않아서 발생한 응답’만을 반영했다고는 할 수 없다.</p>
        <p>현장에서 영양교사들이 이구동성으로 말하는, 육류반찬 선호에 의한 불만 부분도 어느 정도 포함되어 있을 것이다.</p>
      </div>
      <p>또한 여기서 만족도 평점이 4점 이상인 6개(중학교 3개, 고등학교 3개) 학교의 경우는, 양 부족 응답이 모두 20% 미만이었는데, 이 6개 학교를 제외하면, 양 부족 응답은 중학교 14개 평균이 36%, 고등학교 12개 평균이 42%였다.</p>
      <p>그러니까 특별한 개선 노력이 없고 만족도가 높지 않은 26개 학교의 경우, 학생 10명 중 약 4명이 급식의 양이 부족하다고 하는 상황이었다.</p>
      <div className={ss.annotation_wrap}>
        <p>참고로, 평점 4점 이상인 6개교 중 4개 학교는, 만족도 개선을 위해 활발한 소통(학생 의견 반영), 다양한 질문을 통한 만족도(기호도) 조사, 다양한 방식의 개선책 시행, 적극적인 급식교육 등 ‘식재료·식단·조리·배식·퇴식·교육·급식환경’의 모든 부분에서 개선을 위해 노력하는 학교라는 공통점을 가지고 있었다.</p>
        <p>다른 학교들이 보고 배워야할 필요가 있는 <span className={ss.bold}>열린 급식</span>을 하는 학교들이다.</p>
        <p>나머지 2개 학교 중 1개교는 당시 전국적으로 유명한 스타 영양사를 보유했던 고등학교로서, 높은 급식 만족도(98%, 4.36점)가 나왔던 사립학교인데, 재단의 지원이 있고 영양사의 개인역량이 특별한 학교였기 때문에, 부러워하거나 칭찬은 할 수 있지만, 보고 배우기에는 거의 불가하다.</p>
        <p>또 다른 1개교는 자율형공립고인데 학생들과 학부모의 교육열이 대단히 높은 학교라서, 인근 학교보다 10~20% 비싼 급식비를 기꺼이 지불했다.(2015년 당시 고등학교는 무상급식이 아니었다.)</p>
        <p>급식비 단가가 높아 질 높은 급식이 가능했기에 만족도(94%, 4.3점)가 높았으나(한계효용까지는 금액에 비례하여 만족도가 상승), 이 역시 보편적이지 않은 상황이라서(만족도가 떨어질 때마다 급식비를 올린다), 보고 배우기에는 적절하지 않다.</p>
      </div>
      <p>&nbsp;</p>
      <p>첫 번째 추정, 남녀공학이 아닌 여학교(혹은 남학교)의 경우에서, 양 부족 응답 비율이 낮게 나타날 것이다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는, 이전에 밤톨이의 초등학교 친구 B의 이야기를 들었는데, 다른 도시의 남자중학교에 다니는 B는, 급식의 양이 충분해서 부족하다고 느낀 적이 없으며, 그 학교의 다른 학생들도 대부분 부족함을 느끼지 않는다고 했다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는, 그 학교가 남학생만 있으므로 여학생과의 평균으로 공급하지 않고, 800㎉를 온전히 공급하니까 양이 부족한 학생의 수가 적은 것이라 해석했다.</p>
      <p>그렇다면 여학교의 경우도 마찬가지로, 평균을 내지 않고 667㎉를 공급하니까, 양이 많거나 적다는 응답이 적고, 양이 적당하다는 응답이 많을 것이라 추정했다.</p>
      <p>&nbsp;</p>
      <p>이와 같은 해석과 추정에 따라, 독도 씨는 부종시 내의 남중·여중·남고·여고의 자료를 찾아보았는데, 당시 부종시에는 여중과 여고가 각각 하나 있었고, 남중과 남고는 없었다.</p>
      <p>그 중 C여중의 자료는 이미 수집이 되어 있었는데, D여고의 자료는 구할 수 없었다.</p>
      <p>그래서 아쉽지만, 독도 씨는 C여중의 자료만을 확인할 수 있었다.</p>
      <p>&nbsp;</p>
      <p>그렇다면 과연 C여중의 만족도 조사 결과에서, 양이 부족하다는 응답은 얼마나 나왔을까?</p>
      <p>독도 씨의 추정대로, 관내 중학교 평균을 훨씬 밑도는 18%였다.</p>
      <p>그리고 ‘급식 양이 알맞다’는 응답은 무려 74%나 나왔다.</p>
      <p>독도 씨는 이 수치가 매우 자연스럽다고 판단했다.</p>
      <p>C여중은, 여학생만 있기 때문에 1인당 평균 열량 667㎉를 그대로 받는다.</p>
      <p>적정량을 배식했을 때 발생하는 당연한 결과이다.</p>
      <div className={ss.annotation_wrap}>
        <p>물론 여학생들 사이에서도 체구의 차이는 있고, 개인의 기호나 식습관도 어느 정도는 영향을 끼칠 것이므로, [별표3]의 적정량이 많거나 부족하다고 말하는 학생이 없을 수는 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>또 한 가지, C여중의 잔반 설문에서 가장 많이 남기는 잔반은 51%가 국이었고, 이는 다른 학교와 다를 바가 없었는데, 잔반을 남기지 않는다가 23%, 가끔 남긴다가 68%, 거의 매일 남긴다가 9%로 나타났다.</p>
      <p>적정량을 배식함으로써 급식 양이 알맞기 때문에 잔반이 적은 것은 당연한 결과다.</p>
      <p>남녀공학의 여학생들은 평균적으로 많은 양을 받고 있기 때문에 잔반이 많이 생길 것이라 추정할 수 있고, 여학교의 여학생들은 적정량을 배식 받고 있기 때문에 잔반의 양이 적을 것이라 추정할 수 있는데, C여중의 경우는 이러한 추정에 잘 들어맞는다.</p>
      <div className={ss.annotation_wrap}>
        <p>현장의 증언에 의하면, 학교급식에서는 주로 편식 때문에 잔반이 많이 발생한다고 한다.</p>
        <p>하지만 <span className={ss.bold}>적정량을 배식하지 않는 것도 잔반의 주요 원인</span> 중 하나이다.</p>
        <p>많이 가져가면 남길 수밖에 없다.</p>
        <p>따라서 편식을 교정하기 위한 급식교육도 중요하지만, 적정량을 배식하는 것도 매우 중요하다.</p>
      </div>
      <p>&nbsp;</p>
      <p>다만, 이와 같은 현상이 여러 학교에서 발견된다면 좋겠지만, 부종시에서 구할 수 있는 데이터가 하나(C여중) 밖에 없는 것이, 독도 씨는 아쉬웠다.</p>
      <p>독도 씨의 추정에 따라 데이터를 보면 딱 들어맞지만, 그것은 우연일 수도 있기 때문에 하나의 사례만 가지고는 근거로 제시하기가 부족했다.</p>
      <div className={ss.annotation_wrap}>
        <p>최근 다시 여러 학교의 자료를 찾아보려, 부종시 인근의 3개 교육청 10개의 여중·여고 홈페이지를 방문했다가 독도 씨는 좌절을 겪고 더 이상의 수집 노력을 포기했다.</p>
        <p>아직 올해의 급식 만족도 조사를 실시하지 않아 작년 자료를 찾으려 했으나 작년 자료를 모두 삭제한 학교가 2곳, 서버 이상 등으로 자료를 내려 받을 수 없는 학교가 5곳, 자료는 수집했으나 아예 양 부족을 묻는 항목이 없고 전반적으로 만족도 조사 내용이 부실한 학교가 3곳이었다.</p>
        <p>도대체 아직도 왜 이 모양인지 모르겠다.</p>
        <p>할 말은 많은데 지면상 생략하겠다.</p>
      </div>
      <p>&nbsp;</p>
      <p>두 번째 추정, 남녀공학 고등학교의 양 부족이 남녀공학 중학교보다 더 심하게 나타날 것이다.</p>
      <p>&nbsp;</p>
      <p>독도 씨는, 남중생과 남고생의 기준 열량은 차이가 있는데, 여중생과 여고생의 기준 열량은 같다는 점에 주목했다.</p>
      <p>[별표3]에 따라 공급해야할 열량은, 남중생이 800㎉이고 남고생은 900㎉인데, 여중생과 여고생은 똑같이 667㎉이다.</p>
      <p>고등학교에서 남녀 칼로리의 차이가 더 크다.</p>
      <p>그러므로 중학교에서 남녀 성비가 같다면, 남중생은 733.5㎉를 공급받게 되므로, [별표3]의 기준량 대비 91.6%를 먹게 되는데, 고등학교에서 남녀 성비가 같다면, 남고생은 783.5㎉를 공급받게 되므로 [별표3]의 기준량 대비 87.1%를 먹게 된다.</p>
      <p>즉, 남고생이 남중생보다 ‘적정량 대비 더 낮은 비율’로 배식을 받게 됨을 알 수 있다.</p>
      <p>따라서 독도 씨는, 고등학교에서 양이 부족하다는 응답이 더 많을 것이라고 추정했다.</p>
      <p>실제로 위에서 보았듯이, 양 부족 응답은 중학교가 32%였는데, 고등학교가 36%로 더 높게 나타났다.</p>
      <p>&nbsp;</p>
      <p>세 번째 추정, 중학생과 고등학생에게 같은 양을 배식하는 학교는, 중학생만 배식하는 학교에 비해 중학생의 양 부족 응답 비율이 낮을 것이고, 이 학교에서 양 부족 응답 비율은 중학생보다 고등학생이 더 높을 것이다.</p>
      <p>&nbsp;</p>
      <p>부종시에는 중학교와 고등학교를 같이 운영하고 있는 사립학교 재단이 5군데 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>이 중 중학교와 고등학교 모두에서 ‘급식 만족도 조사’ 자료를 공개하고 있었던 재단은 3군데였다.</p>
      </div>
      <p>중·고등학교가 하나의 급식실을 운영하고 있고, ‘학교급식운영계획(안)’의 연간 급식 영양 기준량이 중학교와 고등학교가 같다면, 중학생과 고등학생은 같은 양을 공급받고 있다고 추정할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>중학생과 고등학생의 양을, 달리 주는지 같이 주는지 직접 확인해보지는 못했다.</p>
        <p>하지만 남녀학생에게 같은 양을 주는 것이, 차등 배식의 곤란함(남녀학생에게 납득시키기 어렵고, 정량을 계산해서 나눠주기가 어렵다)에서 비롯된 것이므로, 중학생과 고등학생을 구분해서 차등 배식할 것이라 기대하기는 어렵다고 본다.</p>
        <p>물론 만족도 조사 결과는 이와 같은 추정에 부합되게 나타나고 있다.</p>
      </div>
      <p>이때의 영양 기준량은, 남중생·여중생·남고생·여고생의 값을 모두 모아 평균으로 정한 것이므로, 각각의 학생 수가 같다고 하면, 그 값은 758.5㎉가 된다.</p>
      <p>즉, 여중·여고생은 [별표3]의 기준량 대비 113.7%를 받고, 남중생의 경우는 [별표3]의 기준량 대비 94.8%를 받고, 남고생의 경우는 [별표3]의 기준량 대비 84.3%의 열량만을 공급받게 된다.</p>
      <p>남중생의 경우 남고생 형의 열량이 더 높은 덕에 평균이 상승하여 약간 덕을 보게 된다.</p>
      <p>반대로 남고생의 경우, 남중생의 열량이 상대적으로 낮고, 거기에 여중생까지 수가 더해져서 여고생만 있을 때보다 평균이 더 큰 폭으로 낮아지게 된다.</p>
      <p>중·고등학교가 같은 급식실을 사용하는 학교의 남중생은, 중학교만 사용하는 학교의 남중생에 비해 [별표3]의 기준량 대비 3.2%의 열량을 더 받고, 남고생의 경우 2.8%를 덜 받는다.</p>
      <p>그러므로 두 가지의 추정이 가능한데, 첫째, 중학생과 고등학생이 같은 급식실을 사용함으로써, 고등학생 대비 중학생의 양 부족 응답이 훨씬 낮을 것이라 추정할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>남중생은 기준량 대비 94.8%를, 남고생은 기준량 대비 84.3%를 배식 받으므로, 더 받는 쪽의 응답이 덜 부정적일 것이라고 추정된다.</p>
      </div>
      <p>둘째, 이러한 학교에서 중학생의 양이 부족하다는 응답은, 중학교만 급식하는 학교에 비해 그 비율이 낮을 것이고, 반대로 고등학생의 경우에는 높을 것이라고 추정할 수 있다.</p>
      <div className={ss.annotation_wrap}>
        <p>중·고등학생에게 공통 급식하는 학교의 남중생은 기준량 대비 94.8%를 받고, 중학교만 급식하는 학교의 남중생은 91.6%를 받는다.</p>
        <p>따라서 중학교만 급식하는 학교의 양 부족 응답 비율이 더 높을 것이다.</p>
        <p>반대로 남고생의 경우 84.3% VS 87.1%의 차이가 발생하므로, 고등학교만 급식하는 학교의 양 부족 응답 비율이 더 낮을 것이다.</p>
        <p>이 모든 경우에서 여학생의 ‘평균’은 초과 배식을 받고 있으므로, 양 부족 응답 비율에 영향을 주지 않는다고 가정한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>이러한 추정에 부합되는 결과가 나타났을까?</p>
      <p>&nbsp;</p>
      <p>E재단의 경우, 중학생의 양 부족 응답은 28.6%였는데, 고등학생의 양 부족 응답은 59.1%로 상당히 높았으며, 이 고등학교의 급식 만족도 평점은 2.2점에 불과했다.</p>
      <p>F재단의 경우, 중학생의 양 부족 응답은 11%였는데, 고등학교의 경우 31%였다.</p>
      <p>그러므로 중학생이 고등학생에 비해 양 부족 응답이 훨씬 낮음을 알 수 있다.</p>
      <p>G재단의 경우, 중학생의 양 부족 응답은 19%였는데, 고등학생의 경우 37%였다.</p>
      <p>모두 추정에 부합되는 결과이다.</p>
      <p>&nbsp;</p>
      <p>또한 E재단과 F재단, G재단 모두 중학생의 양 부족 응답은 전체 중학교 평균치(32%)보다 낮았다.</p>
      <p>이 역시 추정에 부합되는 결과이다.</p>
      <p>하지만, F재단의 고등학생 양 부족 응답은 31%로 전체 고등학교 평균치(36%)보다 높지 않았다.</p>
      <p>그러나 E재단, F재단, G재단의 평균(42.4%)으로 비교해보면, 전체 고등학교 평균치 보다 높다는 것을 알 수 있다.</p>
      <p>그러므로 이 역시 추정에 부합된다고 할 수 있다.</p>
      <p>&nbsp;</p>
      <p>이상과 같은 세 가지 추정은 모두, [별표3]의 열량 기준을 근거로, 남녀학생에게 같은 양의 배식을 할 때, 남학생과 여학생, 그리고 중학생과 고등학생에게 공급되는 열량 비율을 계산하여, 양 부족 응답의 상대적인 차이를 추정한 것이며, 부종시 내의 학교들이 보여주는 데이터는 모두 완벽하게 들어맞았다.</p>
      <div className={ss.annotation_wrap}>
        <p>물론 특정 지역(부종시)의 적은 데이터만으로 추정한 것이므로, 이를 인정하지 않으려는 사람들이 있을 것이다.</p>
        <p>따라서 다양한 지역의 더 많은 데이터를 수집하여 분석할 필요가 있다.</p>
        <p>위에서도 말했지만, 교육부에서는 보다 체계적인 조사와 논의를 거쳐 문제를 확인하고, [별표3]의 내용을 개정(보완)할 필요가 있다.</p>
        <p>지금은 ‘학교급식의 영양관리기준은 학생 집단의 성장 및 건강상태, 활동정도, 지역적 상황 등을 고려하여 탄력적으로 적용할 수 있다.’라고만 되어 있는데, 보다 구체적으로 ‘기준 열량이 다른 학생집단을 같이 배식할 때, 그 차이를 구분하여 배식할 것’을 명시해야 한다.</p>
        <p>보다 근본적으로는, <span className={ss.bold}>개인별로 천차만별인 기호와 양</span>에 대해 어떤 식으로 접근해야 최대의 건강과 만족을 확보할 수 있을지에 대해 고민을 해야 한다.</p>
        <p>이를 테면 <span className={ss.bold}>자율배식이 가능하도록 지원하는 시스템에 대한 고민</span> 같은 것 말이다.</p>
        <p>지금처럼 기준 열량만 떨렁 만들어 놓고 방치해 놓을 일이 아니다.</p>
        <p><span className={ss.bold}>급식은 국민의 평생 건강을 좌우하는 성장기 먹거리에 대한 교육과정</span>이다.</p>
        <p>제발 각성좀 하자.</p>
      </div>
      <p>&nbsp;</p>
      <p>정리하자면, ‘학교급식운영계획(안)’과 「학교급식법 시행규칙」의 [별표3]과 부종시 내 학교들의 ‘학교급식 만족도 조사’를 모두 종합하여 분석한 결과, 밤톨이와 여러 학생들이 급식이 적어서 배고프다고 말하는 것은 학생 개인의 육류반찬 선호 때문이 아니라, 법령에서 정한 알맞은 양을 공급하지 않는, 학교급식의 배식 체계가 잘못된 것이라고, 독도 씨는 확신하게 되었다.</p>
      <p>&nbsp;</p>
      <p>3. 다른 이슈들과 학부모 만족도 조사.</p>
      <p>앞서 한비중학교 학교급식 만족도 조사의 핵심 이슈는 ‘만족도, 음식의 양과 간, 잔반’이라고 했는데, 이중 잔반에 관한 이슈는 뒤에서 자율배식 이슈와 함께 따로 다룰 예정이다.</p>
      <p>음식의 간을 포함하여, 한비중학교의 질문 항목에는 없지만, 맛이나 위생, 서비스, 시설, 환경 등 다루어야할 이슈들은 이 외에도 상당히 많다.</p>
      <div className={ss.annotation_wrap}>
        <p>만일 급식에 관심이 있는 분이라면, 여러 학교의 만족도 조사를 읽어보길 권한다.</p>
        <p>생각보다 다양한 질문이 있음을 알 수 있다.</p>
        <p>다만, 학교홈페이지에서 자료를 내려 받는 것이 항상 순조롭지만은 않을 것이다.</p>
        <p>사회 전반의 정보화 수준과 교육계(학교)의 정보화 수준은 하늘과 땅 차이라는 것을 미리 알아두는 것이 정신건강에 이롭다.</p>
        <p>&nbsp;</p>
        <p>다양한 질문은 다양한 관점에서 나오고, 다양한 관점은 다양한 문제와 그 원인을 발견하게 하고, 다양한 해결책을 제시해 준다.</p>
        <p>그러므로 다양한 관점을 위한 <span className={ss.bold}>학교 구성원들의 참여와 소통은, 급식에 있어서도 가장 중요한 운영 방식(학교자치)</span>이라고 할 수 있다.</p>
      </div>
      <p>이 모든 내용을 제대로 짚고 넘어가게 되면, 이번 이야기가 너무 길어지게 된다.</p>
      <p>그러므로 나머지 이슈들은, 그때그때 등장할 때마다 조금씩 다루도록 하겠다.</p>
      <p>&nbsp;</p>
      <p>그리고 이제 마지막으로, 학생들을 대상으로 하는 급식 만족도 조사가 아닌, 학부모를 대상으로 하는 급식 만족도 조사에 대해서 잠시 살펴보고 넘어가자.</p>
      <p>&nbsp;</p>
      <p>한비중학교 ‘학교급식 만족도 조사’ 문서의 뒷부분에는, 학부모를 대상으로 질문하고 그 응답 내용을 기록한 ‘학부모 만족도 조사’ 항목이 있었다.</p>
      <p>학년별로 2개 학급을 선정하여 179명의 학부모에게 가정통신문을 보내 설문을 진행하였고, 회수된 응답은 103명이라고 적혀 있었다.(57.5%)</p>
      <div className={ss.annotation_wrap}>
        <p>가정통신문을 제때 전달하지 않는 학생들의 수가 워낙 많으므로, 응답률이 높지 않지만 크게 실망할 수준은 아니라고 본다.</p>
        <p>최근에는 앱을 이용하여 응답을 받기 때문에, 이보다는 응답률이 높다고 한다.</p>
        <p>하지만 여전히 부족한 부분이 많으므로, 소통을 위한 양자(학부모와 학교)의 꾸준한 노력이 필요함을 잊지 말아야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>여기에는 총 12개 항목의 질문이 있었는데, 학교급식에 대해 어느 정도 관심이 있는지, 어느 정도 아는지, 가정에서의 식생활 지도 내용은 어떠한지, 학교급식에 바라는 점은 무엇인지 등의 질문이 있었다.</p>
      <p>이러한 질문은 ‘급식모니터링 참여 학부모에게 중점적으로 설명해야할 내용’을 파악하거나, ‘학생들을 지도할 때 참고해야할 내용’, ‘급식 안내 가정통신문에 보낼 내용’ 등을 작성하는데 도움이 되는 질문이다.</p>
      <div className={ss.annotation_wrap}>
        <p>학교급식은 단순히 학교에서 밥을 주는 행위가 아니라, 학교와 가정에서의 올바른 식습관을 형성할 수 있도록 가르치는 교육 행위이므로, <span className={ss.bold}>가정과 학교가 함께 노력해야 한다.</span></p>
        <p>따라서 학부모에게는 ‘학교급식이라는 교육과정에 대한 이해’가 필요하고, 학교에게는 ‘학생들이 가정에서 어떤 식습관을 가지고 있는지 파악하는 것’이 필요하다.</p>
        <p>학부모 만족도 조사는 그러한 요구를 충족시키는데 도움이 되는 도구이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>내용을 쭉 읽어 내려가던 독도 씨는 어떤 항목에서 또 한 번 놀라게 되었다.</p>
      <p className={ss.quo_mini}>‘아니 이게 무슨……. 장난인가?’</p>
      <p>혹시 잘못 보았나 싶어 다시 보아도 마찬가지였다.</p>
      <p>허!</p>
      <p>학교급식에 대해 만족하냐는 질문의 응답이, 매우만족 40%, 만족 40%, 보통 19%, 불만 1%로 나타나 있었다.</p>
      <p>이는 학교에서 발표하는 만족도 수치(보통 이상 응답)로는 99%이고, 별점으로 계산하면 4.2점이다.</p>
      <p>이 점수가 만일 학생 만족도 점수라면, 부종시 내에서 비율(%)로는 1위, 별점으로는 2위에 해당하는 최상위권 점수이다.</p>
      <p>&nbsp;</p>
      <p className={ss.quo_mini}>‘보통을 제외한 학생들의 만족 비율은 47%인데, 학부모의 만족 비율은 80%라고? 물론 학생들이 만족하지 않아도 학부모가 만족할 수는 있어. 충분히 그럴 수 있지. 예를 들자면 아이들은 숙제 내주는 선생님이 싫어도, 부모들은 그런 선생님을 좋아할 수 있거든. 그러니까 학생과 학부모의 만족 비율은 충분히 다를 수 있어.’</p>
      <p>학생과 학부모의 만족도 비율이 같아야 하는 것은 아니다.</p>
      <p>경우에 따라서는 큰 차이가 날 수도 있다.</p>
      <p>예를 들자면, 학부모가 보기에는 늘 좋은(건강한) 식단이고 맛도 좋은데, 편식하는 학생이 보기에는 싫은 날이 많고 좋은 날이 적을 수 있다.</p>
      <p>따라서 편식하는 학생이 많은 경우라면, 학생의 만족도보다 학부모의 만족도가 월등히 높을 수도 있다.</p>
      <p className={ss.quo_mini}>‘그래도 그렇지. 배가 고프다고 아우성치는 학생들이 59%인데, 학부모 중에 불만인 사람이 103명 중에 단 한 사람뿐이라고? 도무지 이해가 가지 않는다. 배고픈 것은 문제가 되지 않는다는 건가?’</p>
      <p>&nbsp;</p>
      <p>대개의 학부모들은 학교급식에 우호적이다.</p>
      <p>그들은 세 가지 측면에서 학교급식을 좋아한다.</p>
      <p>첫째, 학교급식 덕분에 도시락을 싸지 않아도 되니 너무 편하다.</p>
      <p>둘째, 돈이 한 푼도 들지 않는 무상급식이다.</p>
      <p>셋째, 학교에서 전문가(영양교사)가 골고루(영양가 있게) 잘 먹일 테니, 건강(균형 잡힌 식단)에 대한 걱정이 줄어든다.</p>
      <p>그야말로 세 가지 문제(수고, 돈, 고민)을 학교에서 몽땅 해결 해주니, 학교급식에 대한 고마움이 기본으로 장착되기 마련이다.</p>
      <p>&nbsp;</p>
      <p>물론 단체 급식이다 보니, 위생(안전)이나 맛과 영양, 양 등 급식의 질에 대해 우려하는 사람도 꽤 있다.</p>
      <p>그래서 학교에서는 그런 걱정을 덜어주기 위해, 직접 보라고 급식모니터링 제도를 운영한다.</p>
      <p>실제 학교에서 실시하는 급식현장을 보고 온 학부모는 청결하며 잘 관리가 되는 식재료 검수, 조리 과정, 배식 과정을 지켜보고, 직접 급식을 먹어보고 나서 만족하게 된다.</p>
      <p>그래서 학부모들 사이에서 급식모니터링에 대한 이야기를 나눌 때면 다음과 같은 이야기를 쉽게 들을 수 있을 것이다.</p>
      <p className={ss.quo}>“애들은 불만이라는데, 아직 어려서 그래. 집에서 입에 맞는 것만 먹다보니 학교급식이 얼마나 훌륭한지 몰라서 그래. 아휴, 나는 매일 먹고 싶더라. 애들이 부러워.”</p>
      <p className={ss.quo}>“그래그래. 나물이나 국도 다 맛있더라. 애들 입맛에는 별로겠지만, 나는 오히려 한식이 더 많이 나왔으면 좋겠어. 애들이야 스파게티, 피자, 치킨, 햄버거, 짜장면 이런 메뉴가 좋다고 하겠지만.”</p>
      <p className={ss.quo}>“그러게. 애들 입맛 맞춰서 먹이는 게 쉬운 일이 아니야. 불만 있는 엄마들도 있겠지만, 이 정도면 나는 만족해. 아, 불만이면 자기들이 와서 해보라고 해. 보니까 급식선생님도 진짜 열심히 하더라고. 정말 힘들겠어, 나라면 못할 거야. 그래도 어찌나 친절하던지, 내가 다 미안하더라고.”</p>
      <p>어쩌면 교육청이나 학교는 이런 이야기들을 바라고 급식모니터링 제도를 운영하는지도 모르겠다.</p>
      <p>영양교사며 조리사가 열심히 일하는 모습을 보고 감동하고, 많은 아이들이 질서 있게 줄서서 밥을 받아가는 모습에 감동하고, 밥도 맛있고, 학부모들에게 친절하고, 전반적으로 깨끗하니 그들 눈에는 나무랄 것이 없어 보인다.</p>
      <p>간혹 공분을 일으킬 만큼 질 나쁜 급식이 나오는 학교도 있지만, 상당수 학교의 경우 어른들 눈에는 좋아 보이거나 그럭저럭 괜찮게 보인다.</p>
      <p>&nbsp;</p>
      <p>그런데 급식모니터링 제도에는 살짝 꼼수가 숨겨져 있다.</p>
      <p>일단 학부모가 보았을 때 불만이 많을만한 학교는 급식모니터링을 잘 실시하지 않는다.</p>
      <p>그리고 그럭저럭 보통인 학교는 급식모니터링을 통해 상황을 잘 포장한다.</p>
      <p>2주에 한 번 정도, 급식모니터링이 있는 날이면, 식단도 갑자기 풍성해지고, 영양교사도 조리실도 모두 각오하고 고되게 움직이고, 청결이며 친절에 온 힘을 쏟는다.</p>
      <p>맛에 자신 있는 메뉴(특히 육류가 포함된 식사)가 나오고 양도 충분하게 준다.</p>
      <p>그러다보니 급식모니터링이 있는 날은 아이들의 표정도 밝다.</p>
      <p>많이 먹고 잔반도 얼마 없다.</p>
      <p>2주에 한 번이라면 이런 상황을 연출하는 것이 어렵지 않다.</p>
      <p>이것을 좋게 생각하면 ‘잘 보이고 싶은 마음’ 정도로 이해할 수 있지만, 문제는 학부모에게 잘 보임으로 인해서 학부모의 급식 개선 요구가 사라지므로, 이는 학생들의 만족도 개선에 저해 요소로 작용한다.</p>
      <p>학부모들에게서 만족스럽다는 칭찬을 받는 것이, 학생들의 불만을 해결하는 것보다 훨씬 쉽다.</p>
      <p>급식모니터링 제도는, 학부모들에게 학교급식을 이해시킴으로써, 학교와 가정이 유기적으로 협력하여 교육급식을 하는데 도움이 되고자하는 목표를 가지고 있으나, 이렇게 학교급식을 포장하는데 이용됨으로써 교육급식에 역행하는 일이 발생하기도 한다.</p>
      <p>조금 심하게 말하자면, 이런 경우에 학부모는 호구가 된다.</p>
      <p>또 하나의 우민화 정책이라고 할 수 있다.</p>
      <p>&nbsp;</p>
      <p>반면에 아무 때나 불시에 모니터링을 해도 급식실을 자신 있게 공개할 수 있는 학교도 있는데, 당신(자녀)의 학교가 모니터링 상황을 연출하는 학교인지 늘 자신 있는 학교인지 알고 싶다면, 그야말로 불시에 가보면 된다.</p>
      <p>물론 학부모가 원한다고 아무 때나 불시에 모니터링을 할 수 있는 것은 아니다.</p>
      <p>급식 업무는 한가한 업무가 아니다.</p>
      <p>모니터링은 영양교사의 업무에 지장을 주기 마련이다.</p>
      <p>따라서 막무가내로 방문하는 것은, 규정상(위생·안전상의 문제) 불가하기도 하거니와, 예의에도 어긋나는 일이다.</p>
      <p>그러면 어떻게 해야 하는가?</p>
      <p>불시 방문을 하고 싶다면, 사전에 학부모회와 학교(학교장과 영양교사) 간에 협의를 진행해서 방식을 논의해 놓아야 한다.</p>
      <div className={ss.annotation_wrap}>
        <p>학교자치가 활성화 되어있지 않고, 열린 급식을 하고 있지 않는 학교라면, 불가능에 가까울 것이다.</p>
      </div>
      <p>방문 당일 오전에 급식실에 통보하고 방문하는 것이라면 무리가 없을 것이다.</p>
      <p>방문 시에는 정규 모니터링 절차대로 하지 말고 배식대에서 밥만 타먹고 오면 된다.</p>
      <p>그러니까 학생처럼 행동하면 된다.</p>
      <p>식품창고나 조리실에 들어가지 않고, 손 잘 씻고 용모단정한 간소한 복장을 하고 학생들과 같이 줄서서 배식을 받고 음식을 먹어보면 된다.</p>
      <p>식재료 검수와 조리 과정을 뺀 나머지 부분의 불시 모니터링은 전부 가능하다.</p>
      <div className={ss.annotation_wrap}>
        <p>식재료 검수와 조리 과정은 정규 모니터링 시에 확인하면 된다.</p>
      </div>
      <p>&nbsp;</p>
      <p>자, 그렇다면 이제, 학부모들의 만족도가 99%가 나온 이유에 대해서 추정해 보자.</p>
      <p className={ss.circle_num}>① 학생들과 어른들의 입맛, 기호도, 급식에 대한 관점 등이 달라서, 학생들보다 높은 만족도가 나왔다.(즉, 99%가 나온 것은 진실 된 답변이다.)</p>
      <p className={ss.circle_num}>② 학교급식에 우호적이라서 긍정적으로 편향된 심리가 작용했다.</p>
      <p className={ss.circle_num}>③ 싫은 소리 하는 것을 꺼려하고, 정에 이끌리는 문화가 작용했다.</p>
      <p className={ss.circle_num}>④ 급식모니터링 연출과 이로 인한 입소문에 이끌렸다.</p>
      <p className={ss.circle_num}>⑤ 별 생각 없이, ‘좋은 게 좋은 거다’라며 그냥 만족을 찍었다.</p>
      <p className={ss.circle_num}>⑥ 학교가 하는 일은 옳은 일이기 때문에, 잘 모르지만 만족을 선택했다.</p>
      <p className={ss.circle_num}>⑦ 응답지를 제출한 학부모 중에는 여학생의 학부모들이 압도적으로 많았다.(남학생 학부모들은 불만을 차마 적어내지 못해서 안 냈다. 아이가 가정통신문을 들고 가면 누가 불만을 적어 냈는지 선생님이 알게 되므로.)</p>
      <p>&nbsp;</p>
      <p>또 무슨 이유가 있을 수 있을까?</p>
      <p>대충 이정도면 경우의 수가 다 나오지 않았을까?</p>
      <p>그렇다면 한비중학교 학부모들의 학교급식 만족도가 99%인 이유는 무엇일까?</p>
      <p>&nbsp;</p>
      <p>결론은……, 알 수 없다.</p>
      <p>당시의 독도 씨도, 지금의 독도 씨도, 아무리 생각해봐도 마땅한 답을 찾기 어려워서 포기한 문제다.</p>
      <p>솔직히 말하면, 한비중학교의 학교자치 수준으로 판단했을 때, ②번~⑥번이 모두 해당될 것 같고, 밤톨 엄마로부터 들은 이야기(여학생 학부모들은 양 부족에 대해 관심이 없고, 남학생 학부모들은 목소리를 내지 못하는 상황)로는 ⑦번도 가능성 있어 보인다.</p>
      <p>사실 ①번이 가장 그럴듯해 보이지만, 그러기에는 학생 만족도 조사와의 차이가 너무 크고, 무엇보다도 단지 1%의 불만이라는 숫자가 너무 비현실적이라서 합당한 이유로 보기에는 너무 어렵다.</p>
      <div className={ss.annotation_wrap}>
        <p>앞서 언급했던, 전국적으로 가장 유명한 영양사를 보유하여 완벽에 가까운 급식을 선보였던 사립학교(만족도 98%)의 경우와 인근 학교보다 급식비를 높게 책정하여 급식의 질을 올렸던 자율형공립고(만족도 94%)의 경우에도, 99%의 만족도는 나오지 않았다.</p>
        <p>&nbsp;</p>
        <p>참고로, 독도 씨가 입수한 32개 학교 중에서, ‘학부모 만족도 조사’가 학생 만족도 조사 문서에 함께 병기된 학교는 한비중학교 외에 3군데가 더 있었다.(나머지 학교는 별도의 문서로 작성되었을 것으로 추정된다.)</p>
        <p>H중학교는 학부모 불만족이 12%, I중학교는 10%, J중학교는 9%로 나왔다.</p>
        <p>그리고 이들 학교의 학생 평점은 각각 3.34점, 3.44점, 3.26점으로 한비중학교의 3.31점과 비슷했다.(부종시 관내 17개 중학교 중 평점 기준으로 각각 14위, 13위, 16위로 한비중학교(15위)와 함께 최하위에 자리 잡은 학교들이었다.)</p>
        <p>이들 학교의 학부모 만족도를 보면, 하나같이 학생 불만족에 비해 월등하게 낮은 불만족 수치를 나타내고 있었다.</p>
        <p>하지만 역시 1%의 불만은 너무 비현실적이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>하지만, 이유를 알 수는 없지만, 이것이 어떤 문제를 야기하는지는 쉽게 알 수 있다.</p>
      <p>바로 <span className={ss.bold}>학생들을 위한 급식 만족도 개선에 저해 요소로 작용</span>한다는 것이다.</p>
      <p>한비중학교는 99%의 학부모가 만족하는 학교급식을 개선하기 위해 노력을 할까?</p>
      <p>계속 하던 대로 하면 되는데 굳이 다른 일을 벌일까?</p>
      <p>보통의 학교는 그런 짓(!)을 절대 하지 않는다.</p>
      <p><span className={ss.bold}>혁신은 열린 학교에서만 가능한 일</span>이다.</p>
      <p>&nbsp;</p>
      <p>그러니까 결국, 어떤 이유에서였건, 한비중학교의 학부모들은 결코 현명한 선택을 했다고 할 수 없다.</p>
      <p>학생들의 20%가 불만이 있다고 말하고, 59%가 양이 부족하다고 말하는데, 부모들은 귀를 기울여 듣지 않았다.</p>
      <p>학교의 친절함에 호구가 되지 말고 학생들의 불만을 파악하기 위한 모니터링 활동을 해야 했고, 객관적인 시각으로 끊임없이 의심해야 했고, 싫은 소리라도 냉정하게 지적해야 했고, 관심의 눈을 가지고 고민해야 했다.</p>
      <p>남자아이의 일이든, 여자아이의 일이든, <span className={ss.bold}>모두 내 아이의 일이라 생각하고 해결하기 위해 노력해야 했다.</span></p>
      <p>바로 이 급식의 문제에서도, 또 한 번 오늘날 학교의 모습과 학부모의 모습을 확인할 수 있다.</p>
      <p>이래서는 답이 없다.</p>
      <p>어른(부모)들의 어리석음으로 인해 학생(자녀)들이 고통 받는 현실은, 독도 씨가 어렸을 적이나 지금이나 마찬가지이고, 앞으로도 변함이 없을 것 같다.</p>
      <p>&nbsp;</p>
      <p>지금까지 우리는, 독도 씨가 ‘아이들이 왜 배가 고프다고 하는지’ 그 이유를 찾는 과정을 살펴보았다.</p>
      <p>독도 씨는 이상과 같은 조사 내용을 문서로 정리하였다.</p>
      <p>다가오는 운영위원회 회의에서 이 문제를 논의하고자 하는 목적이었다.</p>
      <p>&nbsp;</p>
      <p>그리고 운영위원회가 열리기 이틀 전.</p>
      <p>독도 씨는 임춘옥 교장에게 전화를 걸었다.</p>
      <p>논쟁이 시작되었다.</p>
    </div>
  )
}

export default Year2015_25