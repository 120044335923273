import React from 'react'
import ss from './MainText.module.css'

const Year2015_28 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>28. 칼럼(2) - 조선시대의 학부모에게.</h1>
      <p className={ss.now_on_anvil}></p>
    </div>
  )
}

export default Year2015_28