import React from 'react'
import styles from './MainText.module.css'

const Year2015_04 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>4. 무언가 이상한 것.</h1>
      <p className={styles.quo}>“오늘이 첫 회의입니다. 다들 바쁘신 와중에 이렇게 우리 학교와 학생들을 위해 시간을 내어 참석해주신 위원님들께 깊은 감사의 말씀을 드립니다.”<span className={styles.black}>라고 시작된 임춘옥 교장의 인사말이 이어지고, 곧이어 첫 만남에 따른 각 운영위원의 자기소개가 시작되었다.</span></p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“안녕하세요? 1학년 나밤톨의 아빠인 나독도라고 합니다. 저는 이번에 운영위원을 처음 해봅니다. 아직 모르는 것이 많지만 여러분들에게 누가 되지 않도록 잘 배우고 열심히 하겠습니다.”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“안녕하세요? 저는 3학년 전찬성의 엄마인 윤미옥입니다. 저는 우리 한비중학교에서 올해로 5년째 운영위원을 하고 있네요. 둘째가 졸업반이라서 이번이 마지막인데요, 교장선생님을 잘 보필하여 유종의 미를 거두도록 하겠습니다.”</p>
      <div className={styles.annotation_wrap}>
        <p className={styles.annotation}>진부도교육청의 「학교운영위원회 설치·운영 조례」는 2013년부터 운영위원의 연임을 2차까지로 제한하고 있다.</p>
        <p className={styles.annotation}>그러므로 한 학교에서 연속 3년까지만 운영위원이 될 수 있다.</p>
        <p className={styles.annotation}>그러나 2013년 이전의 연임기간은 이러한 제한에 포함되지 않으므로(불소급의 원칙), 윤미옥 위원은 한비중학교에서만 5년 연속 운영위원이 가능했다.</p>
      </div>
      <p>&nbsp;</p>
      <p>운영위원 경력 3년차인 안미자 위원과 추한심 위원, 경력 2년차인 주영숙 위원과 왕보람 위원 등 나머지 학부모위원 4명도 각자 자기소개를 했고, 이어서 지역위원 2명이 자기소개를 시작했다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“여러분, 반갑습니다. 저는 작년 9월 자유은행 한비동지점에 지점장으로 부임한 최형식이라고 합니다. 교장선생님이 지역위원을 맡아달라고 하셔서 수락은 했는데, 저도 처음이라 잘 모르긴 하지만, 은행 업무 경험을 살려서 학교에 도움이 될 만한 일이 있다면 최선을 다해 임하겠습니다. 감사합니다.”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“안녕하세요. 저는 올 2월까지 인근 큰산중학교에서 교장으로 근무하다가, 퇴임한 지 이제 한 달 조금 넘은 차근덕이라고 합니다. 교장선생님 추천으로 이 자리에 왔고요. 아무래도 최근까지 교직에 있었으니 여러분들보다야 아는 것이 많겠지만, 사실 운영위원 일이란 게 어렵지 않아서 제가 도움이 될 일은 별로 없을 겁니다. 그냥 선생님들이 설명하는 내용 잘 듣고 모르면 질문하고 그러면 다 됩니다. 여기 한비중학교 학생들을 위해서 우리 교장선생님 많이 도와주십시오.”</p>
      <p>&nbsp;</p>
      <p>그리고 교사위원들과 간사인 행정실장에 이어서, 운영위원은 아니지만 함께 참석한 교감까지 모두 자기소개가 끝나고, 다음 순서로 운영위원회 위원장과 부위원장을 뽑는 절차가 시작되었다.</p>
      <p>한비중학교 규정에는, 학교운영위원은 총 12명(학부모위원 6명, 교원위원 4명(학교장 포함), 지역위원 2명)으로 구성하고, 위원장과 부위원장은 교원위원을 제외한 8명 중에서 투표로 선출하라고 되어 있었다.</p>
      <p>선출 결과, 한비중학교에서만 5년 연속 운영위원을 하고 있고 지난해 운영위원장이었던 윤미옥 위원이 위원장으로 연임 되었고, 다른 사람들이 모두 고사하여 나독도 씨가 부위원장이 되었다.</p>
      <p>&nbsp;</p>
      <p>이렇게 위원장과 부위원장의 선출이 끝나고, 본 회의를 시작하기 위해서 운영위원장이 위원장 자리로 이동하여 회의 준비를 하고 있을 때, 독도 씨는 임춘옥 교장에게 질문을 던졌다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“그런데 교장선생님, 「초·중등교육법 시행령」에 ‘지역위원은 학부모위원 또는 교원위원의 추천을 받아, 학부모위원 및 교원위원이 무기명투표로 선출한다.’고 되어 있잖아요? 그래서 어떤 방식으로 추천을 받는 건지 제가 진부도교육청에 전화로 문의 해봤거든요.”</p>
      <p>독도 씨는 빠르게 이야기를 이어갔다.</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“그랬더니 보통은 학교 행정실에서 학부모위원과 교원위원 모두에게 지역위원 추천서를 나눠주면서, 언제까지 추천하라고 안내를 해준다고 하더라고요. 음……, 안내 받은 위원들은 추천할 사람이 있으면 추천하고, 없으면 추천하지 않아도 되고요. 그래서 그렇게 추천된 사람의 수가 정수 이내이면 무투표 당선이고, 정수를 초과하면 무기명투표로 선출한다고 하더라고요. 그러니까 우리 학교는 2명을 뽑는데 지금 지역위원 두 분이 이 자리에 계시니까, 2명만 추천된 것이고 그래서 무투표 당선이 된 것이겠죠?”</p>
      <p>&nbsp;</p>
      <p>한번 숨을 들이쉬고는, 독도 씨는 정말 하고 싶은 말을 꺼냈다.</p>
      <p className={styles.quo}>“그런데 저는 지역위원 추천 관련해서 안내를 받지 못했거든요. 혹시 저만 안내를 못 받았나요?”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“아, 그건……” <span className={styles.black}>순간 임춘옥 교장의 얼굴에 몹시 당황한 표정이 나타났다.</span></p>
      <p className={styles.quo}>“그러니까……, 전부 안내를 안했습니다. 그건 위원님들께 안내를 드려도 보통은 추천을 하지 않기 때문에……, 그냥 제가 혼자 2명을 추천했습니다. 작년에도 그랬고요. 일종의 관례지요. 사실 3월말까지 지역위원을 선출해야 하는데 시간이 많이 부족하기도 하고요.”</p>
      <p>&nbsp;</p>
      <p>임춘옥 교장의 말을 듣던 독도 씨는 잠시 고개를 갸웃했다.</p>
      <p className={styles.quo}>“음, 그런 관례가 있다면, 만일 제가 추천하고픈 사람이 있어도 추천할 수가 없네요? 좀 이상한데요?”</p>
      <p>&nbsp;</p>
      <p className={styles.quo}>“아, 혹시 추천하고 싶은 분이 계셨나요? 제가 부임한지 3년차인데 다른 분이 지역위원을 추천한 경우가 없어서…….”</p>
      <p>독도 씨는 단지 석연찮은 내용에 대해 질문을 했을 뿐인데, 교장과 그 옆자리의 교감까지도 눈에 뜨이게 당황한 표정을 짓자, 교장의 추천으로 선출된 두 지역위원을 포함하여 다른 위원들의 분위기도 갑자기 싸늘해졌다.</p>
      <p>&nbsp;</p>
      <p>그때 윤미옥 위원장이 나섰다.</p>
      <p className={styles.quo}>“자, 시간이 많이 지체되었는데, 이제 회의를 시작하겠습니다.”</p>
      <p>마치 독도 씨의 질문은 대수롭지 않은 것이라는 듯, 화제를 돌려버리고 가볍게 의사봉을 들어 올렸다.</p>
      <p>&nbsp;</p>
      <p>독도 씨가 첫 회의가 시작되기도 전에 발견한 ‘무언가 이상한 것’은 바로 한비중학교의 지역위원 선출 절차에 관한 것이었는데, 어차피 이미 지난 일이기도 하거니와, 당시 독도 씨는 ‘학교장이 안내 없이 단독으로 지역위원을 추천한 것’이 법령 위반이라는 것을 깨닫지 못했고, ‘왜 이런 일이 벌어지는지’ 그 이유도 몰랐기 때문에 일단은 회의에 집중했다.</p>
    </div>
  )
}

export default Year2015_04