import React from 'react'
import ss from './MainText.module.css'

const Year2015_20 = () => {
  return (
    <div className={ss.wrap}>
      <h1 className={ss.title}>20. 스승의 그림자도 밟지 마라.</h1>
      <p>일곱 번째, 권위주의적 사고방식.</p>
      <p>독도 씨가 한비중학교 학교운영위원회에서 느낀 문제점은 이후로도 여러 가지가 발생하지만, 첫 회의가 끝나고 정리해 본 불편함은 크게 여덟 가지였다.(그 중 학교 홈페이지에 관한 이야기는 조금 더 뒤에 이야기하겠다.)</p>
      <p>이제 독도 씨가 불편하게 느낀 권위주의적 사고방식에 대해 알아보자.</p>
      <p>&nbsp;</p>
      <p>독도 씨가 어렸을 적에 많이 들었던 이야기가 있다.</p>
      <p>아마 누구나 들어봤을 이야기다.</p>
      <p>바로 ‘군사부일체’, 그리고 ‘스승의 그림자도 밟지 않는다.’는 말이다.</p>
      <p>당시 어른들은 학생들에게 이런 이야기를 하면서, 선생님의 말씀을 무조건 잘 들어야 한다고, 절대 복종해야한다고 신신당부했다.</p>
      <div className={ss.annotation_wrap}>
        <p>박물관에나 있을 법한 말 같은데, 교권의 추락을 걱정하는 사람들 사이에서는 최근에도 많이 언급되는 말이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>일곱 번째 불편함을 이야기하기 위해, 이 두 말에 대해 먼저 이야기해보자.</p>
      <p>&nbsp;</p>
      <p>하나, 군사부일체는, ‘임금과 스승과 아버지의 은혜는 같으므로 세 분 섬기기를 같이하라.’는 뜻인데, 이는 소학(유학 교육 입문서의 하나인 도덕 책)에서 유래한 내용이다.</p>
      <p>나를 있게 한 존재에 대한 은혜를 잊지 말고 보답해야한다는 것인데, 현재의 우리 사회와는 잘 맞지 않는 내용이다.</p>
      <p>&nbsp;</p>
      <p>일단, 현재의 우리 사회에는 임금(君)이 없다.</p>
      <p>임금을 섬기는 것이 충(忠)인데, 임금이 없으니 충이라는 개념에 익숙하지 않다.</p>
      <div className={ss.annotation_wrap}>
        <p>“대신 국가에 충성해야 하는 것이 아닌가?”라는 사람이 있다.</p>
        <p>우리는 주권을 가진 국민이고 국가의 주인이다.</p>
        <p>국가의 존속과 안녕과 발전은 주인인 국민의 공동의 목표이다.</p>
        <p>주인이 이를 달성하기 위해 협의하고 노력하는 것을 충성이라고 하지 않는다.</p>
        <p>충성이란 헌신과 복종이다.</p>
        <p>군인은 국가와 국민에게 충성해야 하지만, 국민은 국가를 운영하는 것이지 국가에 충성하는 것이 아니다.</p>
        <p>비슷한 예를 들자면, 회사(주식회사)는 직원에게 충성을 요구할 수 있지만, 회사의 주인인 주주에게는 충성을 요구할 수 없다.</p>
        <p>만일 국민에게 국가를 위해 충성하라는 사람이 있다면, 그는 아마도 독재자일 것이다.</p>
        <p>국민은 국가를 위해 희생당해도 괜찮은 존재가 아니다.</p>
        <p>만일 내가 국가를 위해 헌신한다면, 그것은 주인으로서의 국가에 대한 사랑이지, 복종이 아니다.</p>
        <p>그러므로 국가에 충성하겠다는 맹세는 잘못된(주인의식이 결여된) 것이다.</p>
        <p>국가를 사랑하겠노라고 말해야 한다.</p>
      </div>
      <p>충성은 마음에서 우러나오는 정성이라는 뜻이며, 몸과 마음을 다하여 헌신하고 복종하는 것을 의미한다.</p>
      <p>임금과 스승과 아버지를 똑같이 대해야 한다는 것은, 충과 효(孝)가 본질적으로 같다는 것이며, 스승에게도 똑같이 충·효를 다해야 한다는 뜻이다.</p>
      <p>사실 조선시대가 아니더라도 학생이 교사에게 충성해야 했던 시절이 있었다.</p>
      <p>개인적인 심부름도 거리낌 없이 시켰고, 말을 듣지 않으면 폭력으로 다스리던 때였는데, 지금은 그런 시절이 아니다.</p>
      <p>아무도 학생에게 충성하라고 말하지 않는다.</p>
      <p>그러므로 군사부일체라는 말은 현재의 우리 사회와는 맞지 않는 말이다.</p>
      <p>&nbsp;</p>
      <p>이제, 스승에 대한 이야기를 해보자.</p>
      <p>조선시대에는 성리학(유학의 학파)을 통치이념이자 생활규범으로 채택하였는데, 위에서 말한 소학은 조선시대의 교육기관에서 필수과정으로 가르쳤던 책이라고 한다.</p>
      <p>소학이 완성된 시기(1187년)나 그 이후 조선시대(1392년 개국)나 마찬가지로, 그 시대는 지금처럼 다양한 형태의 각종 교재가 홍수를 이루는 시대가 아니라, 책이 귀하고 종류가 한정된 시대였기 때문에, 스승이 곧 살아있는 교과서이자 대체 불가능한 귀중한 참고서였을 것이다.</p>
      <p>스승은 그만큼 귀한 존재였으며, 제자가 스승의 가르침을 받으려면 찾아가 허락을 구해야 했고, 스승이 거절하면 관계가 맺어지지 않았다고 한다.</p>
      <p>따라서 사제지간이 시작되는 것부터가 스승의 크나큰 은혜일 수밖에 없었다.</p>
      <p>그리고 일단 관계가 맺어지면, 스승은 부모 못지않은 책임감으로 제자를 가르쳤으며, 심지어 제자가 잘못을 저지르면 스승이 자신의 잘못으로 여기고 반성하는 것이 사회의 분위기였다고 한다.(대신 벌을 받는 경우도 있었다고 한다.)</p>
      <p>성리학이 지배했던 시대적 배경, 그리고 이런 특별한 관계였다면, 스승에게 존경을 표하고 복종하는 것이 제자의 가장 기본적인 자세가 되는 것도 당연한 일이었을 것이다.</p>
      <p>&nbsp;</p>
      <p>하지만 현재의 학교에서 일반적인 교사와 학생이 그러한 관계를 맺는 것은 불가능하다.</p>
      <p>우선 교사와 학생은 서로 선택할 수가 없다.</p>
      <p>국가와 교육청과 학교가 정해주는 대로 따라야 한다.</p>
      <p>그리고 스승(교사)에게 가르쳐 달라는 허락을 구할 필요가 없다.</p>
      <p>또한 학생은, 다양한 교재를 구할 수 있고, 교재 외에도 학습에 대한 다양한 정보(가르침)를 얻기가 쉽고, 특정 교사가 자리를 비워도 다른 교사가 얼마든지 대체할 수 있기 때문에, 특정 교사에게 의존할 이유가 없다.</p>
      <p>그리고 이전의 교육은, 스승이 일방적으로 교육과정을 결정하고 제자가 따르는 방식이었다면, 지금의 교육은 학교 구성원이 상호 협의를 통해 교육과정을 결정하고 운영하는 시스템으로 변모하였다.</p>
      <p>이렇게 환경이 달라졌으며, 체계화되고 복잡해지고 행정까지 결합된 거대한 교육 시스템 하에서, 학생에게 교사를 부모님처럼 섬기라고 하거나, 교사에게 부모 못지않은 책임감으로 제자를 가르치고, 제자의 잘못을 대신하여 반성하라고 요구하는 것은 매우 불합리한 일이 될 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>부모 못지않은 책임감을 가지고 제자를 가르치는, 사명감이 투철하고 교육에 헌신하는 교사가 있다면 응당 박수를 보내야 한다.</p>
        <p>또한 학생들에게 선생님을 존중하고 예의를 갖추라고 가르치는 것도 당연히 해야 할 일이다.</p>
        <p>사실 주위를 둘러보면, 박수를 받을만한 교사와 예의를 잘 배워서 갖춘 학생이, 그렇지 않은 교사와 학생보다 더 많다는 것을 알 수 있다.</p>
        <p>학생이 교사에게 폭력을 휘둘렀다는 등 우리의 눈살을 찌푸리게 만드는 불미스러운 사건·사고는 ‘군사부일체를 잊었기 때문에 교권이 추락해서 발생’한 것이 아니다.</p>
        <p>아마 군사부일체를 당연시했던 조선시대에도 그런 사고는 있었을 것이다.</p>
        <p>문제의 원인을 제대로 찾지 못하고, 엉터리로 분석했기 때문에, 시대에 맞지 않는 군사부일체와 같은 말을 향수처럼 되뇌고, 인권을 후퇴시키는 무지한 행동을 하는 것이다.</p>
        <p>우리는, 우리의 아이들을 이용해서 사익을 탐하는 자들을 경계해야 한다.</p>
      </div>
      <p>&nbsp;</p>
      <p>한편, 성리학이 지배적 가치관이었던 시대에는, 나를 낳아준 부모에 대한 무조건 적인 효(孝)를 당연한 의무라 생각하였지만, 현재는 아이를 낳기만 하고 방치하거나 학대하는 부모에게서 친권을 제한하거나 박탈할 정도로 양육의 개념을 중요하게 생각하므로, 자격이 있는지 없는지 따지지 않고 부모라면 무조건 감사와 존경을 표하라는 말은, 이 시대에는 어울리지 않는 말이라 할 수 있다.</p>
      <p>대가족이었고 가부장적이었던 시대의 가족관과 현재의 가족관은 많이 다르다.</p>
      <p>이제는 부모들이 자녀에게 맹목적인 효를 요구하지 않는다.</p>
      <p>대개 가족 간의 사랑과 상호 교감을 더 중요하게 생각한다.</p>
      <div className={ss.annotation_wrap}>
        <p>독도 씨가 판단한 성리학의 효는, 부모와 자식 간 사랑과는 다른 개념이다.</p>
        <p>효는 자식이 부모에게 지켜야할 도리를 규정한 규범이다.</p>
        <p>즉, 자연 발생한 사랑이 아니라 규범으로 정한 매뉴얼이다.(유학자가 임의로 정의한 것을 지배계층에서 ‘진리’로 채택한 것이기 때문에 불편함과 부작용이 많았을 것이라 짐작할 수 있다.)</p>
        <p>또한 독도 씨는, ‘효는 인간으로서 지켜야할 도리, 사랑이 충만한 아름다운 이야기로 포장되어 있지만, 실체는 수직적 관계에서의 복종을 근본으로 하는 충(忠)과 같은 개념’이라고 판단한다.</p>
        <p>효를 설파한 공자의 의도가 그랬는지, 아니면 후대에 변질된 것인지는 알 수 없지만, 군사부일체를 강조한 조선시대의 효는, 지배계층의 사회 지배 수단(백성들에게 충성을 강제하기 위한 수단)으로 이용된 개념이라는 것이 독도 씨의 생각이다.</p>
        <p>부모가 물려준 머리카락(상투)을 자르느니 죽는 게 낫다는 것이 효이고, 부모가 죽으라고 하면 이유 불문하고 목숨을 내 놓아야 한다는 것이 효이며, 부모가 아무리 개차반이라고 해도 자기 자식의 살을 베고 피를 먹여서라도 부모를 봉양해야한다는 것이 효인데, 아무리 생각해도 이는 사랑이 아닌 맹신인 것이다.</p>
        <p>누구나 알겠지만, 사랑은 매뉴얼대로 행할 수 없고, 강제할 수도 없다.</p>
      </div>
      <p>따라서 만일, 스승과 부모를 같은 크기로 바라보아야 한다고 하여도, (이 시대의 부모를 대하는 관점은 효가 아닌 사랑이므로) 스승에게 충성할 필요는 없다.</p>
      <p>선생님이 좋아서, 스승의 날에 편지를 쓰고 작은 이벤트를 준비하는 밝고 건강한 아이들의 맑은 웃음소리라면, 그것으로 충분할 것이다.</p>
      <p>&nbsp;</p>
      <p>독도 씨가 군사부일체라는 말을 싫어하는 이유는, 임금이 지배하던 전제국가가 일제 강점기로 소멸되고 해방 후 시민 사회로 넘어오는 과정에서, 그 과도기에 존재한 독재정권의 지배 이데올로기 중 하나로 즐겨 사용된 말이기도 하며, 최근까지도(혹은 지금도) 수직적 위계의 정점에 있는 조직의 장(長)에 대한 충성을 강조하는 사람들이 즐겨 쓰는 말이기도 하고, 어른·상급자에 대한 말대꾸는 불효·불충이며 어른·상급자가 시키면 시키는 대로 따라야한다는 사람들이 좋아하는 말이기 때문이다.</p>
      <p>앞서 말했듯이, 이 말은 현재의 우리 사회와는 맞지 않는 말이고, 수직적 위계질서를 강조하는 사회에서 규범으로 사용한 말이기 때문에, 수평적 학교자치를 운영 시스템으로 하는 지금의 공교육과는 상극인 말이다.</p>
      <p>&nbsp;</p>
      <p>둘, ‘스승의 그림자도 밟지 않는다.’는 말은 불교의 “교계신학비구행호율의”라는 책에서 나온 말이다.</p>
      <p>스승을 따라 걸어갈 때는 그림자를 밟지 않도록 떨어져서 걸어가라는 것으로, 스승은 존엄한 존재이므로 그에 맞는 예를 갖추어야 한다는 의미이다.</p>
      <p>실체가 아닌 그림자일지라도, 스승의 그림자라면 밟아서는 안 된다는 극한의 존경심이 담겨있는 말이다.</p>
      <p>위에서 알아 본 소학은 12세기의 서적인데, 이 책은 7세기의 서적이다.</p>
      <p>더 오래된 시대였고, 더군다나 불교의 스승과 제자 사이는 일반인보다 더 엄격하고 더 가르침이 귀한 사이일 수밖에 없으니, 이러한 극한의 존경심을 이해 못할 바는 아니다.</p>
      <p>&nbsp;</p>
      <p>하지만 한 번 상상을 해보자. 스승이 앞에 걸어가고 제자가 뒤따라가는 모습을.</p>
      <p>군대에서 부대장이 걸을 때 참모들이 그 뒤를 따르는 것처럼, 어떤 조직의 수장이 걸어갈 때 부하 직원들이 각자의 직급에 맞춰 그 뒤를 따라 걷는 것처럼, 스승의 뒤를 제자가 거리를 두어 쫒아가는 것은 계급사회의 위계질서를 나타낸다.</p>
      <div className={ss.annotation_wrap}>
        <p>독도 씨가 사회에 첫발을 내딛었을 무렵은, 지금으로부터 30년쯤 전이었는데, 당시만 해도 ‘상급자와 걸을 때는 1보 뒤, 1보 왼쪽에 위치해서 걸어야 한다.’는 예절을 가르쳤다.</p>
        <p>그러나 요즘은 수평적 소통을 방해하는 시대착오적 예절이라 하여 이런 가르침을 권장하지 않는다.(하지만 중년 이상의 세대는, 이미 몸에 배어서 이런 예절이 더 편할지도 모르겠다.)</p>
      </div>
      <p>&nbsp;</p>
      <p>그렇다.</p>
      <p>스승의 그림자도 밟지 않는다는 말은, 스승과 제자 사이에 계급이 존재하고 있다는 것을 드러내고 있다.</p>
      <p><p>이 말은 두 사람간의 수직적인 관계를 표현하고 있으며, 스승의 권위에 제자가 복종해야함을 내포하고 있다.</p>
        물론 계급사회에서의 존경은 이렇게 표현되어도 어색하지 않다.</p>
      <p>그러니까 이 말을 처음 사용했을 당시의 사회에서는, 나무랄 데 없는 표현이었을 것이다.</p>
      <p>&nbsp;</p>
      <p>하지만 지금의 교사와 학생의 관계는 계급적이지 않다.</p>
      <p>그러므로 이러한 표현을 사용하는 것은 옳지 않다.</p>
      <p>선생님에 대한 존중과 존경을 표현하고 싶다면, 이제 다른 말을 찾아보자.</p>
      <p className={ss.quo}>“선생님 사랑해요!”<span className={ss.black}>정도면 충분하지 않을까?</span></p>
      <p>교사를 존경과 존엄의 대상으로 볼 것인지, 친근함과 사랑의 대상으로 볼 것인지는 당사자인 학생에게 맡기는 것이 좋겠다.</p>
      <p>&nbsp;</p>
      <p>지금까지, 독도 씨가 어렸을 적 많이 들었던 ‘군사부일체’와 ‘스승의 그림자도 밟지 않는다.’는 말에 대해 이야기하였다.</p>
      <p>이제 다시 본론으로 돌아가서 일곱 번째 불편함에 대해 이야기하자.</p>
      <p>&nbsp;</p>
      <p>스승에 관한 유교와 불교의 두 말은 지금 이 시대에는 어울리지 않는 말이다.</p>
      <p>그러나 독도 씨가 학교에 다녔던 시기는, 규정이 아닌 계급에 복종하는 시기였다.</p>
      <p>권위주의의 시대였고, 몽둥이와 따귀가 일상이었던, 폭력으로 권위를 세우고 학생들을 통제하고 복종시켰던 야만의 시절이었다.</p>
      <p>그 시절에는 두 말이 유효했다.</p>
      <p>그래서 독도 씨는 두 말을 많이 들어 보았고, 잘 알고 있었다.</p>
      <p>하지만 독도 씨가 학부모가 되었을 때, 야만적인 교실의 풍경은 사라졌다.</p>
      <p>독도 씨의 아들은 폭력이 없는 학교생활을 하게 되었고, 독도 씨는 바뀐 환경에 안도했고 감사했다.</p>
      <p>그렇게 독도 씨가 어렸을 적 들었던 ‘군사부일체’와 ‘스승의 그림자도 밟지 않는다.’는 말은, 시대가 변하고 환경이 변함에 따라 독도 씨의 기억 속에서도 사라졌다.</p>
      <p>&nbsp;</p>
      <p>그런데 2015년 4월의 첫 회의에서, 독도 씨는 그 두 말을 다시 떠올리게 되었다.</p>
      <p>이날 독도 씨는 묘하게 이질적이고 불편한 느낌을 받았는데, 그 느낌은 잊었던 두 말을 기억 저편에서 건져 올리는 불쾌한 느낌이었다.</p>
      <p>&nbsp;</p>
      <p>그리고 그 느낌은, 다른 운영위원들의 태도에서부터 비롯되었다.</p>
      <p>&nbsp;</p>
      <p>아무 말도 없이 석상처럼 앉아 있던 교원위원의 모습은 교장의 권위에 도전하지 않겠다는 복종의 자세로 보였고, 교장을 많이 도와 달라던 차근덕 지역위원의 말이나, 교장선생님을 잘 보필하겠다던 윤미옥 운영위원장의 말은, 학교장을 상급자로 인식하는 말이었다.</p>
      <div className={ss.annotation_wrap}>
        <p>운영위원회는 학교장과 수평적 위치에 놓여 있는 독립 기구이다.</p>
        <p>학교장도 한 사람의 운영위원이며, 운영위원회는 학교의 운영과 관련한 안건을 심의하는 기구이지, 교장을 돕는 기구가 아니다.</p>
        <p>따라서 교장을 도우라는 이야기는, 교장의 하부 기구가 되어 교장의 뜻에 따르라는 것을 의미한다.</p>
        <p>또한 알다시피 ‘보필’은 아랫사람이 윗사람을 돕는 행동이다.</p>
      </div>
      <p>&nbsp;</p>
      <p>그리고 학부모위원들의 태도는, 독도 씨가 어렸을 적 야만의 시절(권위주의의 시절)에 스승을 대했던 학생의 자세와 같았다.</p>
      <p>그들은, 심의를 제대로 하기 위해 꼼꼼하게 질문하던 독도 씨를, 안건 제출자인 교장의 권위에 도전하는 불충한 자로 간주하였고, 그렇기 때문에 곱지 않는 시선으로 바라보았다.</p>
      <p>또한 회식자리에서는 교원위원들을 ‘그림자도 밟으면 안 되는 스승’처럼 공손하게 올려다보았다.</p>
      <p>그들이 학교장의 거수기를 자처한 것은, 자녀의 이익을 바라는 마음 때문인데, 그와는 별개로 그들의 행동거지(어투나 몸가짐 등)는 교사를 대하는 것에 대한 어려움(공손함)이 잔뜩 배어 있었다.</p>
      <p>그것은 존중이나 예의와는 구별되는, 지나치게 순종적인 자세였다.</p>
      <p>그것은 아마도 어려서부터 ‘스승을 상급자로 인식하고 복종하라.’는 말에 세뇌되어왔기 때문일 것이다.</p>
      <div className={ss.annotation_wrap}>
        <p>한비중학교의 학부모들 뿐 아니라, 다른 학교의 학부모들도 대부분 이와 비슷한 태도를 보인다.</p>
        <p>자녀를 가르쳐주는 고마운 선생님에 대한 존중과 예의를 지키려는 태도는 나무랄 일이 아니다.</p>
        <p>하지만 스승의 그림자도 밟지 않는다는 자세로 교사를 대하는 것은 학교자치를 어렵게 만드는 것이다.</p>
        <p>계속해서 말하지만, 학교자치는 수평적 관계를 요하는 것이고, 군사부일체는 수직적 관계를 규정하는 규범이므로 둘은 상극이다.</p>
        <p>그런 사고방식을 가지고 운영위원을 하면 제대로 심의를 할 수 없다.</p>
        <p>사고를 전환해야 한다.</p>
        <p>운영위원이 아닌 다른 학부모들도 마찬가지다.</p>
        <p>모든 학부모는 학교의 구성원으로서 학교의 운영에 직·간접으로 참여해야 한다.</p>
        <p>그런 사고방식으로는 올바른 민주적 절차에 참여할 수 없다.</p>
        <p className={ss.bold}>군사부일체와 스승의 그림자도 밟지 않는다는 말을 버리지 않으면 교육의 미래는 없다.</p>
      </div>
      <p>&nbsp;</p>
      <p>독도 씨는, 학교장을 자신들의 상급자로 인식하거나, ‘군사부일체’와 같은 시대착오적인 사고방식으로 교사를 바라보는 학부모위원들의 모습이 너무 불편했다.</p>
      <p>학교장은 운영위원회 위에 군림하는 사람이 아니다.</p>
      <p>운영위원은 경우에 따라서는 학교장의 의견에 반대할 수도 있어야 한다.</p>
      <p>또한 학부모는 ‘스승의 그림자도 밟지 않는다.’는 구시대의 권위주의적 사고방식을 버려야 한다.</p>
      <p>&nbsp;</p>
      <p>이상과 같은 내용이, 독도 씨가 느꼈던 일곱 번째 불편함이었다.</p>
      <p>&nbsp;</p>
      <p>참고로, 회의실 내의 자리 배치에 대한 이야기를 덧붙이고자 한다.</p>
      <p>한비중학교 운영위원회실에 들어서면, 입구를 기준으로 세로로 긴 직사각형의 테이블이 놓여 있다.</p>
      <p>가장 안쪽 중앙(상석이라 일컫는 자리)에 임춘옥 교장이 앉고, 교장의 오른쪽으로 학부모위원들이 일렬로 앉고, 맞은편인 교장의 왼쪽에 지역위원과 교원위원이 일렬로 앉도록 좌석이 정해져 있다.(이름표가 놓여 있다.)</p>
      <p>그리고 입구 가까운 쪽 중앙에 운영위원장이 앉아서 교장을 마주보고 회의를 진행하는 배치이다.</p>
      <p>안건을 설명하는 담당자는 회의실 밖에서 서 있다가, 차례가 되면 들어와 문 옆에 있는 교탁에 서서 안건을 설명한다. 교장은 앉은 자세 그대로 담당자를 바라볼 수 있고, 운영위원장과 다른 위원들은 고개를 돌려서 담당자를 보아야 한다.</p>
      <p>이러한 배치를 아무도 이상하게 생각하지 않았는데, 독도 씨는 자리의 배치부터 마음에 들지 않았다.</p>
      <p>상석을 정해 놓고 그곳에 교장이 앉는 것부터 권위주의의 시작이라 생각했다.</p>
      <p>도대체 하나부터 열까지, 모든 환경이, 동등한 위치에서 토론(심의)을 할 수 있게 하는 것이 아니었다.</p>
      <p>독도 씨가 학부모로서 처음 마주한 학교라는 조직(기관)은 민주주의와는 거리가 아주 먼 조직이었다.</p>
    </div>
  )
}

export default Year2015_20