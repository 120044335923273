import React from 'react'
import styles from './MainText.module.css'

const Year2015_07 = () => {
  return (
    <div className={styles.wrap}>
      <h1 className={styles.title}>7. 회식이 필요했던 이유.</h1>
      <p>그날 저녁 독도 씨는 다른 위원들과 함께 식사를 하면서 여러 가지 이야기를 나누었다.</p>
      <p>회의 중에는 다른 위원들의 표정이 전반적으로 좋지 않았지만, 식사 자리에서는 다들 밝고 활기찼다.</p>
      <p>독도 씨에게도 전반적으로는 친절하게 대화를 건넸고, 특별히 반감을 표하지는 않았다.</p>
      <p>오히려 독도 씨가 다른 위원들을 조금 불편해하였다.</p>
      <p>아무래도 생각했던 것과는 많이 달랐던 운영위원회의 모습 때문이었다.</p>
      <p className={styles.quo}>“운영위원이 처음이시라면서요. 그런데 아주 많이 해보신 것 같으세요. 오늘 여러 가지로 많이 배웠습니다.”</p>
      <p>임춘옥 교장이 독도 씨에게 말을 하자, 윤미옥 위원장이 옆에서 거들었다.</p>
      <p className={styles.quo}>“그러게요. 잘 모른다고 하시더니 상당히 날카로우시던데요? 부위원장님 덕분에 엄청 오래했어요. 어휴 이렇게 길게 해 본건 처음이에요. 호호호.”</p>
      <p>웃으면서 하는 말이라 크게 기분이 나쁘진 않았지만, 칭찬이 아니라는 것쯤은 독도 씨도 잘 알았다.</p>
      <p className={styles.quo_mini}>‘뭘 배웠다는 거고, 뭐가 날카롭다는 거지?’</p>
      <p>하지만 독도 씨는 생각과 다르게 말했다.</p>
      <p className={styles.quo}>“죄송합니다. 처음이라 모르는 게 많아서 이것저것 묻다보니 그렇게 되었네요. 다음에는 좀 나아지겠지요. 하하하.”</p>
      <p>서로 주거니 받거니 대화를 나누고 있는 동안, 독도 씨의 반대편에 앉아 있는 김봉식 교감은 독도 씨를 바라보며 잠시 생각에 잠겼다.</p>
      <p className={styles.quo_mini}>‘이 친구 특이해. 잘 구슬려도 말을 듣지 않을 타입이야. 골치 아프겠어.’</p>
      <p>투박하고 인자한 느낌의 뿔테 안경을 썼지만, 교감의 눈은 인자한 느낌은커녕 상대를 내려다보고 가볍게 여기는, 다소 거만한 느낌을 주고 있었다.</p>
      <p>어쨌든 몇 번이 될지는 모르겠지만, 1년 동안 학교운영위원회에서 여러 번 만나야할 사람들이라서, 독도 씨는 마음속의 불편함을 감추고 다른 위원들과 친해지려 노력했고, 이런저런 사담을 나누며 귀를 기울였다.</p>
      <p>&nbsp;</p>
      <p>시간이 지나면서 이야기의 주제는 주로 자녀들에 관한 것으로 이어졌다.</p>
      <p>학부모위원들은 각기 자녀들의 학교생활에 대해서 물었는데, 교장을 비롯한 교원위원들은 학부모위원들의 자녀들을 잘 알고 있었고, 따라서 자세하게 대답해주었다.</p>
      <p>아이들에 대해 성적이 좋다거나 교우관계가 좋다거나 기타 좋은 특기사항이 있다고 칭찬을 했고, 학부모위원들은 대개 좋아하거나 좀 더 구체적인 내용을 묻거나 하면서 대화가 이어졌다.</p>
      <p>부종시는, 중학생이 고등학교에 진학할 때, 지역(학군) 내의 고등학교를 추첨으로 배정받지 않고, 원하는 학교를 지원하면 내신 성적순으로 합격 여부가 결정되는 비평준화 지역이었다.</p>
      <p>따라서 원하는 고등학교에 진학하기 위해서는 내신 성적을 잘 관리해야 하는데, 학부모위원들은 이에 관한 관심이 매우 높았다.</p>
      <p>여러 교과에 관한 이야기, 방과 후 학교나 자유학기제, 동아리, 스포츠클럽, 교과 성적을 잘 받기 위해 필요한 수행 평가 이야기, 봉사 점수나 수상 실적 등 내신 성적에 도움이 될 만한 학교 내의 여러 정보에서부터, 학부모라면 특히 궁금할 만한 자기 자녀와 관련한 개별적이고 심층적인 이야기를 들을 수 있는 특별한 상담의 기회가 주어진 셈이었다.</p>
      <p>이렇게 식사 자리에서 맥주라도 한 잔 하면서 친근하게 이야기를 나누다보면, 특히 교원위원이 자녀의 교과 담당교사거나 담임교사일 경우, 학교생활이나 성적평가 등에서 조금 더 신경을 써 줄 것이라는 은근한 기대가 학부모위원들에게는 있는 것 같았다.</p>
      <p>회의 도중에는 한 마디도 않고 앉아만 있었던 교원위원들이 대화의 주도권을 잡는 시간이었고, <span className={styles.quo_mini}>'우리는 그저 선생님들만 믿으면 된다.'</span>고 말하는 듯한 학부모위원들이 다소곳한 자세로 질문을 하고 답변을 경청하는 시간이었다.</p>
      <p>&nbsp;</p>
      <p>그런데 학부모위원들과 교원위원들의 대화를 듣던 독도 씨는, 어느 순간 대화에 임하는 그들의 태도를 보며 아주 중요한 사실 하나를 깨닫게 되었다.</p>
      <p>마치 교사위원들에게 자녀들의 미래가 달린 것처럼, 학부모위원들은 교사위원들의 말 한마디 한마디마다 긴장과 집중과 공손함을 보이고 있지 않은가!</p>
      <p className={styles.quo_mini}>‘아하. 그랬구나! 이 사람들은 ‘을(乙)’이구나! 그래서 그런 것이구나!’</p>
      <p>학부모와 교사의 관계는 자녀(학생)를 매개로 하는 관계이기 때문에, 학생들에게 크고 작은 영향을 끼칠 수 있는 교사 앞에서 학부모가 다소곳해지는 것이리라.</p>
      <p className={styles.bold}>내 아이가 볼모인데 어찌 감히 학교운영위원회에서 안건에 대해 묻고 따지고 반대하랴.</p>
      <p>독도 씨는 단 한 번의 운영위원회 참석으로 아주 중요하고 핵심적인 문제를 알게 된 것인데, 이날의 경험과 똑같은 느낌의 경험을 오래도록 반복하여 겪으면서, 훗날 이와 같은 현상(스스로 을이 되는 현상)에 대해 이렇게 한탄하게 된다.</p>
      <p className={styles.bold}>“모든 아이를 바라보는 것이 내 아이를 위한 것인데, 내 아이만 바라보느라 그것을 놓치는구나.”</p>
      <p>&nbsp;</p>
      <p>교육자도 아니고, 교육을 학문으로 전공한 전문가도 아닌, 일반인 독도 씨가, <span className={styles.quo_mini}>‘공교육의 모든 문제에는 아주 근본적이고 가장 핵심적인 두 가지의 원인이 존재하는데, 공급자와 수요자 양자에게서 각각 하나씩 비롯된다.’</span>고 확신하게 된 수많은 경험 중의 첫 번째 경험을, 바로 이날 이 자리에서 하게 된 것이다.</p>
      <div className={styles.annotation_wrap}>
        <p>두 가지의 원인에 대해서는 차차 설명하도록 하겠다.</p>
      </div>
      <p>&nbsp;</p>
      <p>시간이 지날수록 점점 불편해져가던 긴 대화가 끝나자, 독도 씨는 짧은 인사를 마치고 서둘러 집으로 귀가했다.</p>
      <p>몹시 피곤했던 첫 회의가, 그렇게 특별했던 하루가 지나갔다.</p>
    </div>
  )
}

export default Year2015_07